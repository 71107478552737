import { gettext } from 'django'
let React = require('react')

// Register Translations here
// ----------------------------------------------------------------------
const transDeleteTooltip = gettext('Delete user')
// ----------------------------------------------------------------------

export default function TeamMemberActions ({currentUser, deleteMember}) {
  if (currentUser) {
    return <div />
  }
  return (
    <a href='#' onClick={deleteMember} title={transDeleteTooltip} data-placement='left' data-toggle='tooltip'>
      <svg className='icon icon--delete'>
        <use xlinkHref='#icon-delete' />
      </svg>
    </a>
  )
}
