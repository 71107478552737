import React from 'react'
import { gettext } from 'django'

// Register Translations here
// ----------------------------------------------------------------------
const transGeneral = gettext('general form error')

// ----------------------------------------------------------------------

export default function Alert ({message, onClick, fieldMapper = {}, category = 'success'}) {
  if (message) {
    let content
    if (typeof message === 'string' || message instanceof String) {
      content = message
    } else if (Array.isArray(message)) {
      content = (
        <ul>
          {message.map(m => <li key={m}>{m}</li>)}
        </ul>
      )
    } else {
      content = (
        <ul>
          {Object.keys(message).map(k => <li key={k}>{fieldMapper[k] ? fieldMapper[k].toString() : transGeneral}: {message[k].toString()}</li>)}
        </ul>
      )
    }

    if (onClick) {
      return (
        <div className={`alert alert--${category} mb-small has-dismiss-button col-md-12`}>
          {content}
          <button className={`dismiss-button dismiss-button--top-medium`} aria-label={`Dismiss alert`} type='button' onClick={onClick}>
            <svg className='icon icon--dark'>
              <use xlinkHref='#icon-delete' />
            </svg>
          </button>
        </div>
      )
    } else {
      return (
        <div className={`alert alert--${category} mb-small`}>
          {content}
        </div>
      )
    }
  }
  return (null)
}
