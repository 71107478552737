import http from './http'

function declineEntry (entry) {
  return http.fetch(`/api/agenda/invitation/${entry.slug}/`, 'delete')
}

function loadEntries () {
  return http.fetch(`/api/agenda/`, 'get')
}

function updateEntry (entry) {
  return http.fetch(`/api/agenda/${entry.slug}/`, 'put', entry)
}

function updateEntryParticipants (slug, participants, reinvites) {
  return http.fetch(`/api/agenda/${slug}/`, 'PATCH', {participants, reinvites})
}

function deleteEntry (entry) {
  return http.fetch(`/api/agenda/${entry.slug}/`, 'delete')
}

export default {
  declineEntry,
  updateEntry,
  updateEntryParticipants,
  deleteEntry,
  loadEntries
}
