import React from 'react'
import PropTypes from 'prop-types'
import { form } from '../clproxy'
import TOC from '../toc'
import Changeset from '../changeset'
import Chat from '../chat'
import { gettext } from 'django'

// Register Translations here
// ----------------------------------------------------------------------
const transTOCTitle = gettext('TOC Sidebar')
const transCHATTitle = gettext('CHAT Sidebar')
const transCHSTitle = gettext('Approve sidebar')
// ----------------------------------------------------------------------

const SIDEBAR = {TOC: 'toc', QA: 'chat', CHS: 'approve'}

class SideBar extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      rooms: []
    }
  }
  componentDidMount () {
    if (this.props.value) {
      form.getChangesets(this.props.workspace, this.props.value.slug)
        .then(changesets => this.setState({changesets: changesets}))
    }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if ((this.props.value || prevProps.value) !== prevProps.value) {
      form.getChangesets(this.props.workspace, this.props.value.slug)
        .then(changesets => this.setState({changesets: changesets}))
    }
  }

  updateRooms (rooms) {
    this.setState({rooms: rooms})
  }

  render () {
    let hasChagesetSidebar = this.props.value && this.props.value.type_in_workspace === 'domestic'
    let hasTOCSidebar = !!this.props.value

    return (
      <div className={this.props.activeSidebar}>
        <nav className='tabs-icon'>
          <ul>
            {!hasTOCSidebar ? null
            : <li className={this.props.activeSidebar === SIDEBAR.TOC ? 'is-active' : null} >
              <a href='#' onClick={() => this.props.navigateToForm(this.props.value?.slug)}
                title={transTOCTitle} data-toggle='tooltip' data-placement='top'>
                <svg className='icon icon--light'>
                  <use xlinkHref={'#icon-list'} />
                </svg>
                <span className='badge'>{this.props.value && this.props.value.groups.length}</span>
              </a>
            </li>}
            <li className={this.props.activeSidebar === SIDEBAR.QA ? 'is-active' : null}>
              <a href='#' onClick={this.props.navigateToRoomList}
                title={transCHATTitle} data-toggle='tooltip' data-placement='top'>
                <svg className={this.state.rooms.reduce((res, r) => res.concat(r.categories), []).some(c => c.new_messages > 0)
                                ? 'icon icon--red' : 'icon icon--light'}>
                  <use xlinkHref={'#icon-chat'} />
                </svg>
              </a>
            </li>
            {hasChagesetSidebar
              ? <li className={this.props.activeSidebar === SIDEBAR.CHS ? 'is-active' : null}>
                <a href='#' onClick={this.props.navigateToChangesetList}
                  title={transCHSTitle} data-toggle='tooltip' data-placement='top'>
                  <svg className={this.state.changesets && this.state.changesets.length === 0 ? 'icon icon--green' : 'icon icon--red'}>
                    <use xlinkHref={'#icon-approve'} />
                  </svg>
                  <span className={this.state.changesets && this.state.changesets.length === 0 ? 'badge badge--green' : 'badge badge--red'}>
                    {this.state.changesets && this.state.changesets.length}
                  </span>
                </a>
              </li>
              : null}
          </ul>
        </nav>
        {!hasChagesetSidebar ? null
        : <Changeset key={SIDEBAR.CHS} form={this.props.value}
          visible={this.props.activeSidebar === SIDEBAR.CHS} forms={this.props.forms}
          workspace={this.props.workspace}
          changeset={this.props.match.params.changeset}
          onDetailViewRequested={this.props.navigateToChangeset}
          onCloseDetailRequested={this.props.navigateToChangesetList} />}
        <Chat key={SIDEBAR.QA}
          visible={this.props.activeSidebar === SIDEBAR.QA}
          rooms={this.state.rooms} updateRooms={this.updateRooms.bind(this)}
          workspace={this.props.workspace} currentuser={this.props.currentuser} currentuser_slug={this.props.currentuser_slug}
          categories={this.props.categories}
          room={this.props.match.params.room} category={this.props.match.params.category}
          navigateToRoom={this.props.navigateToRoom}
          navigateToRoomList={this.props.navigateToRoomList} />
        {!hasTOCSidebar ? null
        : <TOC key={SIDEBAR.TOC} visible={this.props.activeSidebar === SIDEBAR.TOC}
          workspace={this.props.workspace}
          allowed_forms={this.props.allowed_forms}
          forms={this.props.forms}
          formValue={this.props.value}
          onNewFormAdded={this.props.newFormAdded}
          onFormDeleted={this.props.formDeleted}
          navigateToGroup={this.props.navigateToGroup}
          showActionButtons={this.props.showActionButtons} />}
      </div >
    )
  }
}

SideBar.propTypes = {
  value: PropTypes.object,
  workspace: PropTypes.string.isRequired,
  activeSidebar: PropTypes.string.isRequired,
  currentuser: PropTypes.string.isRequired,
  currentuser_slug: PropTypes.string.isRequired,
  categories: PropTypes.string.isRequired,
  match: PropTypes.object.isRequired,
  allowed_forms: PropTypes.array.isRequired,
  forms: PropTypes.array.isRequired,
  showActionButtons: PropTypes.bool,
  navigateToForm: PropTypes.func.isRequired,
  navigateToRoom: PropTypes.func.isRequired,
  navigateToRoomList: PropTypes.func.isRequired,
  navigateToChangeset: PropTypes.func.isRequired,
  navigateToChangesetList: PropTypes.func.isRequired,
  newFormAdded: PropTypes.func.isRequired,
  formDeleted: PropTypes.func.isRequired,
  navigateToGroup: PropTypes.func.isRequired
}

export { SideBar, SIDEBAR }
