import PropTypes from 'prop-types'
import cookie from 'cookie'
import { gettext } from 'django'
import FileUploadProgress from 'react-fileupload-progress'
import { linkifier } from 'react-linkifier'

var React = require('react')

// Register Translations here
// ----------------------------------------------------------------------
var transDeleteButton = gettext('Delete')
const transError = gettext('File upload error...')
const transErrorTitle = gettext('Error:')
const transPdfWarning = gettext('PDF documents are rendered including CreditLinks watermarking. In rare cases PDF cannot be watermarked. Feel free to click the button "ZIP file via mail" to download all documents in one form template and check the watermark manually. Contact us via info@creditlinks.de if you experience PDFs which are not watermarked.')
// ---------------------------------------------------------

class DocumentField extends React.Component {
  constructor (props) {
    super(props)
    this.state = {id: Math.random().toString(36).substr(2, 10)}
  }

  deleteFile () {
    this.props.onUpdateHandler(null)
  }

  customProgressRenderer (progress, hasError, cancelHandler) {
    let cancelButton = {
      marginTop: '5px',
      WebkitAppearance: 'none',
      padding: 0,
      cursor: 'pointer',
      background: '0 0',
      border: 0,
      fontSize: '21px',
      fontWeight: 700,
      lineHeight: 1,
      color: '#000',
      textShadow: '0 1px 0 #fff',
      filter: 'alpha(opacity=20)',
      opacity: '.2'
    }

    if (hasError || progress > -1) {
      let message = (<span id='uploading'>Uploading ...</span>)
      let progress = (
        <span>
          <progress style={{width: '300px'}} />
          <button style={cancelButton} onClick={cancelHandler}>
            <span>&times;</span>
          </button>
        </span>
                )

      if (hasError) {
        message = (<span style={{ color: '#a94442' }} />)
        progress = (<span />)
      }
      if (this.state.inputValue) {
        message = (<span >Successfully uploaded</span>)
        progress = (<span />)
      }

      return (
        <div className='_react_fileupload_progress_content'>
          {progress}
          <div style={{ clear: 'left' }}>
            {message}
          </div>
        </div>
      )
    }
    return ''
  }

  customFormRenderer (onSubmit) {
    return (
      <div>
        <input style={{display: 'block'}} type='file' name='file' id={`control_${this.state.id}_file`} onChange={onSubmit} />
      </div>
    )
  }

  customFormGetter () {
    var data = new window.FormData()
    let file = document.getElementById(`control_${this.state.id}_file`)
    if (file.files.length === 1) {
      data.append('document', file.files[0])
    }

    return data
  }

  fileUploaded (e, request) {
    var json = JSON.parse(request.responseText)
    this.props.onError(null)
    this.props.onUpdateHandler(json)
  }

  fileUploadError (e, request) {
    var message = transError
    if (request.response) {
      let err = JSON.parse(request.response)
      message = [transErrorTitle].concat(Object.keys(err).map(k => err[k])).join(' ')
    }

    this.props.onError([message])
    document.getElementById(`control_${this.state.id}_file`).value = ''
    this.props.onUpdateHandler(null)
  }

  render () {
    return (
      <div className='input'>
        <label htmlFor='file'>{this.props.template.label}</label>
        {this.props.value != null && this.props.value.document != null ? (
          <div>
            <a href={this.props.value.document} target='_blank'>{this.props.value.name}</a>
            <button onClick={this.deleteFile.bind(this)}>{transDeleteButton}</button>
          </div>
            ) : (
              <div>
                <FileUploadProgress key={`control_${this.props.id}_doc`}
                  url={'/api/form/upload/?workspace_slug=' + this.props.workspace} method='POST'
                  beforeSend={(request) => {
                    request.setRequestHeader('X-CSRFToken', cookie.parse(document.cookie).csrftoken)
                    request.setRequestHeader('Accept', 'application/json, text/plain, */*')
                    return request
                  }}
                  progressRenderer={this.customProgressRenderer.bind(this)}
                  formRenderer={this.customFormRenderer.bind(this)}
                  formGetter={this.customFormGetter.bind(this)}
                  onLoad={this.fileUploaded.bind(this)}
                  onError={this.fileUploadError.bind(this)} />
              </div>
            )}
        <div className='input__description'>{this.props.template.help_text}</div>
        <br />
        <div className='alert alert--secondary has-dismiss-button'>
          {linkifier(transPdfWarning)}
        </div>
      </div>
    )
  }
}

DocumentField.propTypes = {
  id: PropTypes.number.isRequired,
  value: PropTypes.object,
  template: PropTypes.object.isRequired
}

export default DocumentField
