import React from 'react'
import { gettext } from 'django'

// Register Translations here
// ----------------------------------------------------------------------
const transMasterCategoryNameLabel = gettext('Category')
const transMasterCategoryNameHelp = gettext('Select an existing category')
const transCustomCategoryNameLabel = gettext('Category')
const transCustomCategoryNameHelp = gettext('Custom category name')
const transBack = gettext('< Back')
const transConfirm = gettext('Create group')
const transCustomCategory = gettext('Custom category')
// ----------------------------------------------------------------------

const CUSTOM_CATEGORY = 'custom_category'

class NewCategory extends React.Component {
  constructor (props) {
    super(props)

    let categories = props.categories
                          .filter(c => !c.added_by_default)
                          .filter(c => !this.props.room.categories.some(rc => rc === c.name))
    this.state = {
      customCategoryMode: categories.length === 0,
      categories: categories,
      category: categories.length > 0 && categories[0].name
    }
  }

  handleMasterCategorySelection (e) {
    let customCategory = e.target.value === CUSTOM_CATEGORY

    this.setState({
      customCategoryMode: customCategory,
      category: customCategory ? '' : e.target.value
    })
  }

  hanldeCustomCategory (e) {
    this.setState({category: e.target.value})
  }

  render () {
    return (
      <span className='container'>
        <div className='row'>
          <div className='col-md-12'>
            <h5 className='align-left'><a href='#' onClick={this.props.onCancel}>{transBack}</a></h5>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12'>
            <div className='input'>
              <label>{transMasterCategoryNameLabel}</label>
              <select name='category'
                onChange={this.handleMasterCategorySelection.bind(this)}>
                {this.state.categories
                     .map(c => <option key={c.name} value={c.name}>{c.name}</option>)}
                <option value={CUSTOM_CATEGORY}>
                  {transCustomCategory}
                </option>
              </select>
              <div className='input__description'>{transMasterCategoryNameHelp}</div>
            </div>
            {this.state.customCategoryMode ? <div className='input'>
              <label>{transCustomCategoryNameLabel}</label>
              <input name='title' type='text' maxLength={25} onChange={this.hanldeCustomCategory.bind(this)} />
              <div className='input__description'>{transCustomCategoryNameHelp}</div>
            </div> : null}
            <button className='btn mr-micro'
              onClick={() => this.props.onCreate(this.props.room, this.state.category)}>{transConfirm}</button>
          </div>
        </div>
      </span>)
  }
}

export default NewCategory
