import * as Sentry from '@sentry/browser'
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import '@creditlinks/style-guide/public/js/app'
import TeamMemberList from './team/TeamMembersList'
import WorkspacePermissionsList from './workspace_permission_list'
import WorkspaceLog from './workspace_log'
import OrderBook from './order_book'
import AddressBook from './address_book'
import Agenda from './agenda'
import ProjectsBoard from './projects_board'
import takeTour from './userguide'
import CompanyStructure from './company_structure'
import app from './app'
import React from 'react'
import ReactDOM from 'react-dom'
import ReconnectingWebSocket from './ReconnectingWebsocket'
import { SIDEBAR } from './sidebar'
import { gettext } from 'django'

// Register Translations here
// ----------------------------------------------------------------------
const transPermissionRemoved = gettext('Your permissions on this workspace has been revoked. If it was an error, please get in contact with the workspace\'s owner')
// ----------------------------------------------------------------------

moment.defaultFormatUtc = 'DD.MM.YYYY'
moment.defaultFormat = 'DD.MM.YYYY'
moment.locale(window.django_locale)
moment.tz.setDefault(window.django_timezone)

window.takeTour = takeTour

window.notifyMe = (title, body) => {
  var dts = Math.floor(Date.now())

  var options = {
    icon: '/static/images/favicon.png',
    body: body,
    timestamp: dts,
    vibrate: [200, 100, 200]
  }

  // Let's check if the browser supports notifications
  if (!('Notification' in window)) {
    console.debug('This browser does not support desktop notification')
  }

  // Let's check whether notification permissions have already been granted
  else if (Notification.permission === 'granted') {
    // If it's okay let's create a notification
    var notification = new Notification(title, options)
  }

  // Otherwise, we need to ask the user for permission
  else if (Notification.permission !== 'denied') {
    Notification.requestPermission(function (permission) {
      // If the user accepts, let's create a notification
      if (permission === 'granted') {
        var notification = new Notification(title, options)
      }
    })
  }

  // At last, if the user has denied notifications, and you
  // want to be respectful there is no need to bother them any more.
}

let loc = window.location
let wsStart = 'ws://'
if (loc.protocol === 'https:') {
  wsStart = 'wss://'
}
let endpoint = wsStart + loc.host + '/ws/'
window.socket = new ReconnectingWebSocket(endpoint)

window.socket.onmessage = e => {
  console.debug('message', e)
  let data = JSON.parse(e.data)
  window.notifyMe(data.title, data.body)
}

window.socket.onclose = e => {
  console.debug('message: onclose', e)
}

window.socket.onopen = e => {
  console.debug('message: onopen', e)
}

window.socket.onerror = e => {
  console.debug('message: onerror', e)
}

if (document.getElementById('client-app')) {
  let root = document.getElementById('client-app')

  if (window.app.debug !== 'True') {
    Sentry.init({
      dsn: 'https://0b007b7f3e6a4fe4893379309a695c73@sentry.io/220739',
      environment: window.app.environment,
      release: window.app.release
    })
  }

  ReactDOM.render(
    <BrowserRouter>
      <Switch>
        <Route path='/entity/team/' render={() => <TeamMemberList {...(root.dataset)} />} />
        <Route path='/account/address_book' render={() => <AddressBook {...(root.dataset)} />} />
        <Route path='/dashboard/projects/' render={() => <ProjectsBoard {...(root.dataset)} />} />
        <Route path='/dashboard/structure/' render={() => <CompanyStructure {...(root.dataset)} />} />
        <Route path='/agenda/' render={({match, history}) => <Agenda {...root.dataset} />} />
        <Route path='/workspace/:slug/' render={({match}) => {
          window.socket.addEventListener('message', e => {
            const data = JSON.parse(e.data)
            if (data.type === 'workspace.permission_removed' && data.slug === match.params.slug) {
              window.alert(transPermissionRemoved)
              window.location = '/dashboard/projects/'
            }
          })

          return <Switch>
            <Route path='/workspace/:slug/orders/' render={({match, history}) =>
              <OrderBook {...root.dataset} />} />
            <Route path='/workspace/:slug/deadlines/' render={({match, history}) =>
              <Agenda {...root.dataset} />} />
            <Route path='/workspace/:slug/lenders/' render={({match, history}) =>
              <WorkspacePermissionsList {...root.dataset} />} />
            <Route path='/workspace/:slug/members/' render={({match, history}) =>
              <WorkspacePermissionsList {...root.dataset} />} />
            <Route path='/workspace/:slug/log/' render={({match, history}) =>
              <WorkspaceLog {...root.dataset} />} />
            <Route path='/workspace/:slug/export/:form?/:group?/changeset/:changeset?/' render={({match, history}) =>
              <app.workspace.FormsExport {...root.dataset} match={match} history={history} preView activeSidebar={SIDEBAR.CHS} />} />
            <Route path='/workspace/:slug/export/:form?/:group?/chat/:room?/:category?/' render={({match, history}) =>
              <app.workspace.FormsExport {...root.dataset} match={match} history={history} preView activeSidebar={SIDEBAR.QA} />
            } />
            <Route path='/workspace/:slug/export/:form?/:group?/' render={({match, history}) =>
              <app.workspace.FormsExport {...root.dataset} match={match} history={history} preView activeSidebar={SIDEBAR.TOC} />} />
            <Route path='/workspace/:slug/forms/:form?/:group?/chat/:room?/:category?/' render={({match, history}) =>
              <app.workspace.FormsContent {...root.dataset} match={match} history={history} activeSidebar={SIDEBAR.QA} />
            } />
            <Route path='/workspace/:slug/forms/:form?/:group?/changeset/:changeset?/' render={({match, history}) =>
              <app.workspace.FormsContent {...root.dataset} match={match} history={history} activeSidebar={SIDEBAR.CHS} />} />
            <Route path='/workspace/:slug/forms/:form?/:group?/' render={({match, history}) =>
              <app.workspace.FormsContent {...root.dataset} match={match} history={history} activeSidebar={SIDEBAR.TOC} />} />
            <Route component={null} />
          </Switch>
        }} />
        <Route component={null} />
      </Switch>
    </BrowserRouter>
  , root)
}
