import React from 'react'
import { gettext } from 'django'
import { Loader } from 'react-overlay-loader'
import { form, workspace, entity } from '../../clproxy'
import { SideBar } from '../../sidebar'
import Export from '../../export'
import Form from '../../form'
import CorporateStructureTab from '../../company_structure/CorporateStructureTab'
import CorporateStructureExportTab from '../../company_structure/CorporateStructureExportTab'
import FormTab from './form_tab'
import { generatePath } from 'react-router'

import 'react-overlay-loader/styles.css'

// Register Translations here
// ----------------------------------------------------------------------
/* global gettext */
const transAvailableError = gettext('Data category was deleted')
const transCorporateStructure = gettext('Corporate Structure')
// ----------------------------------------------------------------------

const sidebarUrlRegex = /\/workspace\/[\w\d]*\/(forms|export)\/((?!chat|changeset)[\w\d]*\/)?/g

function withForms (showActionButtons) {
  return function (WrappedComponent, StructureComponent) {
    return class extends React.Component {
      constructor (props) {
        super(props)
        this.state = {
          baseUrl: props.match.url.match(/(^.*(?:forms|export)\/).*$/)[1],
          value: null,
          loading: false,
          canManipulateWks: true,
          forms: this._sortForms(JSON.parse(this.props.forms)),
          allowed_forms: [],
          visibleForms: JSON.parse(this.props.forms).map(f => f.slug),
          corporateStructureExported: true,
          targetWorkspace: null
        }
      }

      componentDidMount () {
        if (this.props.match.params.form || this.state.forms) {
          this._loadFormValue()
          workspace.getAvailableForms(this.props.workspace)
            .then(forms => this.setState({allowed_forms: forms}))
            .catch(ex => this.setState({canManipulateWks: false}))
        }

        if (this.props.first_access === 'True') {
          window.takeTour(`/workspace/${this.props.workspace}/forms/`)
        }
      }

      componentDidUpdate (prevProps, prevState, snapshot) {
        if ((prevProps.match.params.form !== this.props.match.params.form) &&
          (this.props.match.params.form !== (prevState.value && prevState.value.slug))) {
          this._loadFormValue()
        }
      }

      _getTabColor (type) {
        const tabColors = {
          'mdm form': '',
          'financial_form': 'secondary',
          'custom form': 'tertiary'
        }

        return tabColors[type]
      }

      render () {
        let masterFormsTabs = this.state.forms.filter(f => f.template_type === this.props.master_form_type)
                                              .map(f => <FormTab key={f.slug} f={f} value={this.state.value} activeSidebar={this.props.activeSidebar}
                                                          visibleForms={this.state.visibleForms} getTabColor={this._getTabColor}
                                                          navigateToForm={this._navigateToForm.bind(this)}
                                                          refreshFormData={this._loadFormValue.bind(this)} />)
        let restFormTabs = this.state.forms.filter(f => f.template_type !== this.props.master_form_type)
                                           .map(f => <FormTab key={f.slug} f={f} value={this.state.value} activeSidebar={this.props.activeSidebar}
                                                       visibleForms={this.state.visibleForms} getTabColor={this._getTabColor}
                                                       navigateToForm={this._navigateToForm.bind(this)}
                                                       refreshFormData={this._loadFormValue.bind(this)} />)
        return (
          <div>
            <Loader fullPage loading={this.state.loading} />
            <div className='col-lg-8 col-xl-10'>
              <div className='col-xs-2'>
                <ul className='nav nav-tabs tabs-left' style={{height: '100vh', paddingTop: '20px'}}>
                  {restFormTabs}
                  {this.props.show_corporate_structure === 'True'
                   ? <li key='corporate_structure'
                       className={this.props.match.params.form === 'structure' || !this.state.value ? 'active' : null}>
                     <a href='#' data-toggle='tooltip' title={transCorporateStructure}
                      className='secondary'
                      onClick={() => this._navigateToCorporateStructure(this.props.activeSidebar)}>
                      {this.state.corporateStructureExported
                        ? transCorporateStructure : <del>{transCorporateStructure}</del>}
                     </a>
                   </li>
                  : null}
                  {masterFormsTabs}
                </ul>
              </div>
              <div className='col-xs-10'>
                <div className='tab-content'>
                  <div className='tab-pane active'>
                    {this.props.match.params.form === 'structure' || (this.props.show_corporate_structure === 'True' && !this.state.value)
                      ? <StructureComponent
                      workspace={this.props.workspace}
                      form={this.props.match.params.form}
                      group={this.props.match.params.group}
                      onChanged={this._loadFormValue.bind(this)}
                      workspace_name_long={this.props.workspace_name_long}
                      workspace_name={this.props.workspace_name}
                      canlink={this.props.canlink}
                      nda_url={this.props.nda_url}
                      nda_name={this.props.nda_name}
                      pdfUrl={this._getUrlFormPDF(this.state.value?.slug)}
                      setVisibleForms={this._setVisibleForms.bind(this)}
                      setCorporateTabVisibility={this._setCorporateTabVisibility.bind(this)}
                      entity={this.state.entity}
                      onEntitySaved={(structure) => this.setState({entity: structure.find(s => s.slug === this.state.entity.slug)})}
                      formName={this.state.forms.find(f => f.slug === this.state.value?.slug)?.name}
                      targetWorkspace={this.state.targetWorkspace}
                      setTargetWorkspace={this._setTargetWorkspace.bind(this)}
                      corporateStructurePdf={this.props.corporate_structure_pdf}
                      formValue={this.state.value} />
                      : <WrappedComponent
                      workspace={this.props.workspace}
                      form={this.props.match.params.form}
                      group={this.props.match.params.group}
                      onChanged={this._loadFormValue.bind(this)}
                      workspace_name_long={this.props.workspace_name_long}
                      workspace_name={this.props.workspace_name}
                      canlink={this.props.canlink}
                      nda_url={this.props.nda_url}
                      nda_name={this.props.nda_name}
                      pdfUrl={this._getUrlFormPDF(this.state.value?.slug)}
                      setVisibleForms={this._setVisibleForms.bind(this)}
                      setCorporateTabVisibility={this._setCorporateTabVisibility.bind(this)}
                      entity={this.state.entity}
                      onEntitySaved={(structure) => this.setState({entity: structure.find(s => s.slug === this.state.entity.slug)})}
                      formName={this.state.forms.find(f => f.slug === this.state.value?.slug)?.name}
                      targetWorkspace={this.state.targetWorkspace}
                      setTargetWorkspace={this._setTargetWorkspace.bind(this)}
                      formValue={this.state.value} />}
                  </div>
                </div>
              </div>
            </div>
            <aside className='col-lg-4 col-xl-2'>
              <SideBar
                value={this.state.value}
                workspace={this.props.workspace}
                activeSidebar={this.props.activeSidebar}
                currentuser={this.props.currentuser}
                currentuser_slug={this.props.currentuser_slug}
                categories={this.props.categories}
                match={this.props.match}
                allowed_forms={this.state.allowed_forms}
                forms={this.state.forms}
                navigateToForm={() => this.props.history.push(
                  this.props.match.url.match(sidebarUrlRegex)[0])}
                navigateToRoom={this._navigateToRoom.bind(this)}
                navigateToRoomList={this._navigateToRoomList.bind(this)}
                navigateToChangeset={this._navigateToChangeset.bind(this)}
                navigateToChangesetList={this._navigateToChangesetList.bind(this)}
                newFormAdded={this._newFormAdded.bind(this)}
                formDeleted={this._formDeleted.bind(this)}
                navigateToGroup={this._navigateToGroup.bind(this)}
                showActionButtons={showActionButtons && this.state.canManipulateWks}
                />
            </aside >
          </div>)
      }

      _loadFormValue () {
        let formValue = this._getSelectedOrAvailableForm()

        if (formValue) {
          this.setState({loading: true})
          form.loadFormValue2(this.props.workspace, formValue, this.props.preView ? '1' : '-1')
            .then(result => {
              this.setState({value: result, loading: false})
            }).catch((ex) => {
              this.setState({loading: false})
              throw ex
            })

          workspace.loadFormsStructure(this.props.workspace, formValue.slug)
                .then(structure => this.setState({entity: structure.length && structure[0]}))
        } else {
          this.setState({value: null, entity: null})
        }
      }

      _getSelectedOrAvailableForm () {
        if (this.props.match.params.form) {
          if (this.state.forms.some(f => f.slug === this.props.match.params.form)) {
            return this.state.forms.find(f => f.slug === this.props.match.params.form)
          } else {
            return
          }
        } else {
          return this.state.forms[0]
        }
      }

      _navigateToForm (formSlug) {
        this.props.history.push(generatePath(this.props.match.path,
                                             {...this.props.match.params, form: formSlug}))
      }

      _getUrlFormPDF (formSlug) {
        return generatePath(this.props.match.path, {...this.props.match.params, form: formSlug}) + '?type=pdf'
      }

      _navigateToCorporateStructure () {
        this.props.history.push(generatePath(this.props.match.path,
                                             {...this.props.match.params, form: 'structure'}).match(sidebarUrlRegex)[0] + 'chat/')
      }

      _navigateToGroup (formSlug, groupId) {
        this.props.history.push(generatePath(this.props.match.path, {...this.props.match.params, group: groupId}))
      }

      _navigateToRoom (room, category) {
        this.props.history.push(generatePath(this.props.match.path, {...this.props.match.params, room, category}))
      }

      _navigateToRoomList () {
        this.props.history.push(
          this.props.match.url.match(sidebarUrlRegex)[0] + 'chat/')
      }

      _navigateToChangeset (changesetId) {
        this.props.history.push(generatePath(this.props.match.path, {...this.props.match.params, changeset: changesetId}))
      }

      _navigateToChangesetList () {
        this._loadFormValue()
        this.props.history.push(
          this.props.match.url.match(sidebarUrlRegex)[0] + 'changeset/')
      }

      _newFormAdded (form) {
        this.setState({
          forms: this._sortForms(
            this.state.forms.concat([{...form, type: 'domestic', template_type: this.props.custom_form_type}])),
          allowed_forms: this.state.allowed_forms.filter(f => f.name !== form.name),
          visibleForms: this.state.visibleForms.concat(form.slug)
        })
        this.props.history.push(`/workspace/${this.props.workspace}/forms/${form.slug}/`)
      }

      _formDeleted (form) {
        workspace.getAvailableForms(this.props.workspace).then(forms => {
          this.setState({
            forms: this.state.forms.filter(f => f.slug !== form.slug),
            allowed_forms: forms
          })
          if (!this.props.match.params.form) {
            this._loadFormValue()
          }
          this.props.history.push(`/workspace/${this.props.workspace}/forms/`)
        })
      }

      _sortForms (forms) {
        let customForms = forms.filter(f => f.template_type === this.props.custom_form_type).sort((f1, f2) => f1.name.localeCompare(f2.name))
        let financialForms = forms.filter(f => f.template_type === this.props.financial_form_type).sort((f1, f2) => f1.name.localeCompare(f2.name))
        let masterForms = forms.filter(f => f.template_type === this.props.master_form_type).sort((f1, f2) => f1.name.localeCompare(f2.name))

        return [].concat(customForms, financialForms, masterForms)
      }

      _setVisibleForms (forms) {
        this.setState({visibleForms: forms.map(f => f.slug)})
      }

      _setCorporateTabVisibility (isExported) {
        this.setState({corporateStructureExported: isExported})
      }

      _setTargetWorkspace (targetWorkspace) {
        if (targetWorkspace !== this.state.targetWorkspace) {
          this.setState({targetWorkspace: targetWorkspace})
        }
      }
    }
  }
}

export default {
  withForms: withForms,
  FormsContent: withForms(true)(Form, CorporateStructureTab),
  FormsExport: withForms(false)(Export, CorporateStructureExportTab)
}
