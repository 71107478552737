import { gettext } from 'django'
import Hopscotch from 'hopscotch/dist/js/hopscotch'

// Define the tour!

var tourFallback = {
  id: "dashboard",
  i18n: {
    nextBtn: gettext('Next Step'),
    prevBtn: gettext('Back Step'),
    doneBtn: gettext('Done Step'),
    skipBtn: gettext('Skip Step'),
    closeTooltip: gettext('Close it')
  },
  steps: [
    {
      title: gettext('No Guide available on this page'),
      content: gettext('Please create a ticket via mail <a href="mailto:support@creditlinks.de">support@creditlinks.de</a>. CreditLinks support will contact you.'),
      target: 'nav-help',
      placement: "top"
    }
  ],
  showPrevButton: false,
  showNextButton: false
}

export default tourFallback