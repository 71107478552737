import PropTypes from 'prop-types'
import { gettext } from 'django'
import React from 'react'
import cookie from 'cookie'
import Alert from '../alert'
import Modal from '../modal'
import DeadlineView from './DeadlineView'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import AgendaView from './AgendaView'
import ActionButton from '../action_button'
import { agenda, workspace } from '../clproxy'

const localizer = momentLocalizer(moment)

// Register Translations here
// ----------------------------------------------------------------------
const transCreateBtn = gettext('Add a Deadline')
const transAgenda = gettext('Deadlines')
const transErrorDeleteDeadline = gettext('Deadline could not be deleted.')

const transShowAgenda = gettext('Show Agenda')
const transShowCalendar = gettext('Show Calendar')

const transUpdateCreateEntrySuccess = gettext('Deadline successfully saved')
const transDeleteEntrySuccess = gettext('Deadline successfully deleted')

const transDeleteBtn = gettext('Delete')
const transUndoneMsg = gettext('This step cannot be undone.')
const transDeleteMsg = gettext('Do you really want to delete this data entry?')
const transShowFuture = gettext('Switch to Upcoming')
const transHidePrevious = gettext('Switch to Pasts')

const transAllDay = gettext('Day')
const transPrevious = gettext('Previous')
const transNext = gettext('Next')
const transToday = gettext('Today')
const transMonth = gettext('Month')
const transWeek = gettext('Week')
const transDay = gettext('Day')
const transBigCalendarAgenda = gettext('Agenda')
const transDate = gettext('Date')
const transEvent = gettext('Event')
const transDeadline = gettext('Deadline')
const transExportCalendar = gettext('Export')

// ----------------------------------------------------------------------

class Agenda extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      deadlines: [],
      show_delete: false,
      alertMessage: null,
      deleteMessage: null,
      showCreateForm: false,
      showStatistic: false,
      selected: null,
      mode: 'agenda',
      members: (props.members && JSON.parse(props.members)) || [],
      privacyChoicesVerbose: (props.privacyChoicesVerbose && JSON.parse(props.privacyChoicesVerbose)) || {},
      privacies: (props.privacies && JSON.parse(props.privacies)) || [],
      errors: null,
      editMode: null,
      showFuture: true,
      entryTypes: JSON.parse(this.props.entry_types)
    }
  }

  componentDidMount () {
    this.loadDeadlines()
    window.$('[data-toggle="tooltip"]').tooltip()
  }

  componentDidUpdate () {
    window.$('[data-toggle="tooltip"]').tooltip()
  }

  _deleteDeadline (deadline) {
    let deleteFunct = () => { this.deleteDeadline(deadline) }
    this.setState({show_delete: true, delete_funct: deleteFunct})
  }

  removeEmpty (obj) {
    if (obj.datetime_end === '') {
      obj.datetime_end = null
    }
  }

  switchMode () {
    if (this.state.mode === 'agenda') {
      this.setState({mode: 'month'})
    } else if (this.state.mode === 'month') {
      this.setState({mode: 'agenda'})
    }
  }

  deleteDeadline (deadline) {
    agenda.deleteEntry(deadline)
      .then(() => {
        this.loadDeadlines()
        this.setState({show_delete: false, showCreateForm: false, alertMessage: transDeleteEntrySuccess})
      }).catch(ex => {
        this.setState({
          deleteMessage: transErrorDeleteDeadline
        })
      })
  }

  onEdit (deadline) {
    this.setState({
      showCreateForm: true,
      editMode: true,
      selected: deadline
    })
  }

  onView (deadline) {
    this.setState({
      showCreateForm: true,
      editMode: false,
      selected: deadline
    })
  }

  loadDeadlines () {
    if (this.props.workspace) {
      workspace.loadAgendaEntries(this.props.workspace).then(data => {
        this.setState({
          deadlines: data
        })
      })
    } else {
      agenda.loadEntries().then(data => {
        this.setState({
          deadlines: data
        })
      })
    }
  }

  saveDeadline (deadline) {
    this.removeEmpty(deadline)
    new Promise((resolve) =>
      resolve(deadline.slug
        ? (deadline.workspace.can_edit
           ? agenda.updateEntry(deadline)
           : agenda.updateEntryParticipants(deadline.slug, deadline.participants, deadline.reinvites))
        : workspace.addAgendaEntry(this.props.workspace, deadline))
    ).then(response => {
      this.setState({errors: null, showCreateForm: false, alertMessage: transUpdateCreateEntrySuccess})
      this.loadDeadlines()
    }).catch(ex => this.setState({errors: ex.message}))
  }

  declineEntry (deadline) {
    agenda.declineEntry(deadline).then(response => {
      this.setState({errors: null, showCreateForm: false})
      this.loadDeadlines()
    }).catch(ex => {
      this.setState({errors: ex.message})
    })
  }

  startAccessor (deadline) {
    if (deadline.datetime_start) {
      return moment(deadline.datetime_start).toDate()
    }
  }

  endAccessor (deadline) {
    if (deadline.datetime_end) {
      return moment(deadline.datetime_end).toDate()
    } else {
      return moment(deadline.datetime_start).toDate()
    }
  }

  eventStyleGetter (event, start, end, isSelected) {
    var style = {
      fontSize: '14px',
      backgroundColor: '#' + this.state.entryTypes.find(e => e.value === event.entry_type)?.color
    }
    return {
      style: style
    }
  }

  tooltipAccessor (obj) {
    return `${obj.title}\r\n${this.state.entryTypes.find(e => e.value === obj.entry_type)?.description}`
  }

  render () {
    const messages = {
      allDay: transAllDay,
      previous: transPrevious,
      next: transNext,
      today: transToday,
      month: transMonth,
      week: transWeek,
      day: transDay,
      agenda: transBigCalendarAgenda,
      date: transDate,
      time: transEvent,
      event: transDeadline,
    }
    let modalTitle = this.state.selected
      ? `${transAgenda} ${this.state.selected.workspace.entity} - ${this.state.selected.workspace.name}`
      : `${transAgenda} ${this.props.entity} - ${this.props.workspaceName}`
    return (
      <div className='col-lg-12'>
        <div className='page-title row'>
          <div className=' col-lg-5'>
            <h2>
              {this.props.workspace
                ? (this.props.workspaceName ? (this.props.workspaceName + ' ' + transAgenda) : 'Workspace ' + transAgenda)
                : transAgenda
              }
            </h2>
            <h5>
              {this.props.entity}
            </h5>
          </div>
          <div className='col-lg-3'>
            <div className='row'>
              <span className='col-lg-2 col-lg-offset-2' style={{paddingTop: '47px'}}>
                {this.props.export_url ? <a href={`webcal://${window.location.host}${this.props.export_url}`}
                  data-original-title={transExportCalendar} data-toggle="tooltip" id='exportAgenda'>
                  <svg className="icon icon--medium icon--light">
                    <use xlinkHref="#icon-share"></use>
                  </svg>
                </a> : null}
              </span>
              <div className='col-lg-8'>
                {this.state.mode === 'agenda' ? (
                  <button
                    className='btn btn--flex btn--green btn--align-center'
                    id={'switchshowFuture'}
                    onClick={() => this.setState({'showFuture': !this.state.showFuture})}>
                    {this.state.showFuture ? transHidePrevious : transShowFuture}
                  </button>) : (null)
                }
              </div>
            </div>
          </div>
          <div className='col-lg-2'>
            <button
              className='btn btn--flex btn--green btn--align-center'
              id={'showCalendar'}
              onClick={() => this.switchMode()}>
              {this.state.mode === 'agenda'
                ? transShowCalendar : transShowAgenda}
            </button>
          </div>
          <div className='col-lg-2'>
            {this.props.workspace ?
            <button
              className='btn btn--flex btn--align-center'
              id={'addDeadline'}
              onClick={() => this.setState({'showCreateForm': true, 'editMode': true, 'selected': null})}>
              {transCreateBtn}
            </button> : null}
          </div>
        </div>
        <div className='row'>
          <div className={'col-lg-12'}>
            <div className={'row | mt-micro'}>
              <Modal id='createForm'
                show={this.state.showCreateForm}
                onClose={() => this.setState({showCreateForm: false, errors: null})}>
                <DeadlineView
                  title={modalTitle}
                  entryTypes={JSON.parse(this.props.entry_types)}
                  errors={this.state.errors}
                  availableParticipants={this.state.members}
                  statuses={this.state.statuses}
                  types={this.state.types}
                  editMode={this.state.editMode}
                  currentUser={this.props.currentuser}
                  workspace={this.props.workspace}
                  onSave={this.saveDeadline.bind(this)}
                  onDelete={(deadline) => this._deleteDeadline(deadline)}
                  onDecline={this.declineEntry.bind(this)}
                  selected={this.state.selected}
                />
              </Modal>
              <Alert
                key='alert'
                message={this.state.alertMessage}
                onClick={() => this.setState({'alertMessage': null})}
                category={'success'} />

              <Modal id='deleteForm' show={this.state.show_delete} onClose={() => this.setState({show_delete: false})}>
                <div title={transDeleteMsg}>
                  <p>{transUndoneMsg}</p>
                  <div className='input | align-right | mt-small'>
                    <ActionButton
                      onClick={() => this.state.delete_funct()}
                      className='btn btn--red'>{transDeleteBtn}
                    </ActionButton>
                  </div>
                </div>
              </Modal>

            </div>
          </div>
        </div>

        <div className='row'>
          <div className={'col-lg-12'}>
            <div className={'row | mt-micro'}>

              {this.state.mode === 'agenda'
                ? (
                  <AgendaView
                    workspace={this.props.workspace}
                    entryTypes={this.state.entryTypes}
                    deadlines={this.state.deadlines}
                    coreteammembers={[]} //this.state.members}
                    showFuture={this.state.showFuture}
                    onEdit={(deadline) => this.onEdit(deadline)}
                    onView={(deadline) => this.onView(deadline)}
                    onDelete={(deadline) => this._deleteDeadline(deadline)}
                  />
                )
                : (
                  <Calendar
                    tooltipAccessor={this.tooltipAccessor.bind(this)}
                    localizer={localizer}
                    style={{height: '800px', fontSize: '14px'}} // explicit height needs to be set for month view to work
                    events={this.state.deadlines}
                    startAccessor={this.startAccessor}
                    endAccessor={this.endAccessor}
                    culture={window.django_locale}
                    onSelectEvent={(deadline) => this.onView(deadline)}
                    messages={messages}
                    views={['month', 'week', 'day']}
                    scrollToTime={moment().hour(7).toDate()}
                    eventPropGetter={this.eventStyleGetter.bind(this)}
                  />
                )
              }

            </div>
          </div>
        </div>
      </div>
    )
  }
}

Agenda.propTypes = {
  workspace: PropTypes.string,
  workspaceName: PropTypes.string,
  currentuser: PropTypes.string.isRequired,
  members: PropTypes.string,
  entry_types: PropTypes.string.isRequired,
  entity: PropTypes.string
}

export default Agenda
