import PropTypes from 'prop-types'
import React from 'react'
import { gettext } from 'django'
import Alert from '../alert'
import FileUpload from '../file_upload'
import ActionButton from '../action_button'
import moment from 'moment'

// Register Translations here
// ----------------------------------------------------------------------
const transCreateBtn = gettext('Add an Order')
const transSave = gettext('Save')
const transShowStats = gettext('Show Statistics')
const transDateCreated = gettext('Entered')
const transDateUpdated = gettext('Updated')
const transCreatedBy = gettext('Created By')
const transLastChangedBy = gettext('Last updated By')
const transLender = gettext('Lender')
const transStatus = gettext('Status')
const transNotional = gettext('Notional')
const transNotionalDescription = gettext('Notional')
const transProbability = gettext('Probability')
const transNotionalProbability = gettext('Notional x Probability')
const transOrderLevelType = gettext('Order Level Type')
const transOrderLevelValue = gettext('Order Level')
const transOrderLevelValueDescription = gettext('Order Level')
const transComments = gettext('Comments')
const transCommentsDescription = gettext('Comments')
const transNextSteps = gettext('Next Steps')
const transNextStepsDescription = gettext('Next Steps')
const transAssignedTo = gettext('Assigned To')
const transAssignedToDescription = gettext('Assigned To')
const transUploadField = gettext('Commitment Document')

const transLimited = gettext('Limited Order?')
const transYesOption = gettext('Yes')
const transNoOption = gettext('No')
// ----------------------------------------------------------------------

export default class OrderView extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      errors: null,
      order: props.selected ? props.selected
        : {}
    }

    if (this.state.order.limited === null) {
      this.setState({
        order: {...this.state.order, limited: false}
      })
    }
  }

  handleInputChange (event) {
    this.setState({
      order: {...this.state.order, [event.target.name]: event.target.value}
    })
  }

  onSave () {
    this.props.onSave(this.state.order)
  }

  onFileUploaded (file) {
    this.setState({
      order: {...this.state.order, upload_field: file}
    })
  }

  onDeleteFile () {
    this.setState({
      order: {...this.state.order, upload_field: null}
    })
  }

  render () {
    let dropDownLenderTeams = this.props.lenders.length > 0 ? (<div className='input'>
      <label htmlFor='lender'>{transLender}*</label>
      <select
        name={'lender'}
        value={this.state.order.lender}
        onChange={this.handleInputChange.bind(this)}
        disabled={!this.props.editMode}
        required>
        <option key={'blank'} value={'invalid_slug'}>{'Please select...'}</option>
        {this.props.lenders.map(team => (<option key={team[0]} value={team[0]}>{team[1]}</option>))}
      </select>
    </div>) : null

    let dropDownStatus = this.props.statuses.length > 0 ? (<div className='input'>
      <label htmlFor='status'>{transStatus}*</label>
      <select
        name={'status'}
        value={this.state.order.status}
        onChange={this.handleInputChange.bind(this)}
        disabled={!this.props.editMode}
      >
        <option key={'blank'} value={''}>{'Please select...'}</option>
        {this.props.statuses.map(status => (<option key={status[0]} value={status[0]}>{status[1]}</option>))}
      </select>
    </div>) : null

    let dropDownAssignedTo = this.props.members.length > 0 ? (<div className='input'>
      <label htmlFor='assigned_to'>{transAssignedTo}</label>
      <select
        name={'assigned_to'}
        value={this.state.order.assigned_to}
        onChange={this.handleInputChange.bind(this)}
        disabled={!this.props.editMode}
      >
        <option key={'blank'} value={''}>{'Please select...'}</option>
        {this.props.members.map(user => (<option key={user} value={user}>{user}</option>))}
      </select>
    </div>) : null

    let dropDownLimited = (<div className='input'>
      <label htmlFor='limited'>{transLimited}</label>
      <select
        name={'limited'}
        value={this.state.order.limited}
        onChange={this.handleInputChange.bind(this)}
        disabled={!this.props.editMode}
      >
        <option key={'false'} value={false}>{transNoOption}</option>
        <option key={'true'} value={true}>{transYesOption}</option>
      </select>
    </div>)

    let currency = this.state.order.currency && this.props.currencies.find((currency) => currency[0] === this.state.order.currency)[1]
    let inputNotional = <div className='input'>
      <label htmlFor='notional'>{transNotional} in {currency}</label>
      <input
        id={'notional'}
        type={'number'}
        value={this.state.order.notional}
        name={'notional'}
        onChange={this.handleInputChange.bind(this)}
        disabled={!this.props.editMode}
      />
      <div className='input__description'>
        {transNotionalDescription}
      </div>
    </div>

    let inputOrderLevelValue = <div className='input'>
      <label htmlFor=''>{transOrderLevelValue} in {this.props.typeFormatter[this.state.order.order_level_type]} </label>
      <input
        id={'order_level_value'}
        type={'number'}
        value={this.state.order.order_level_value || 0}
        name={'order_level_value'}
        onChange={this.handleInputChange.bind(this)}
        disabled={!this.props.editMode} />
      <div className='input__description'>
        {transOrderLevelValueDescription}
      </div>
    </div>

    let inputComments = <div className='input'>
      <label htmlFor='comments'>{transComments}</label>
      <textarea
        id={'comments'}
        type={'text'}
        value={this.state.order.comments}
        name={'comments'}
        onChange={this.handleInputChange.bind(this)}
        disabled={!this.props.editMode}
      />
      <div className='input__description'>
        {transCommentsDescription}
      </div>
    </div>

    let inputNextSteps = <div className='input'>
      <label htmlFor='next_steps'>{transNextSteps}</label>
      <textarea
        id={'next_steps'}
        type={'text'}
        value={this.state.order.next_steps}
        name={'next_steps'}
        onChange={this.handleInputChange.bind(this)}
        disabled={!this.props.editMode}
      />
      <div className='input__description'>
        {transNextStepsDescription}
      </div>
    </div>

    let inputUploadFields = this.props.editMode ? <div className='input'>
      <label htmlFor=''>{transUploadField}</label>
      <FileUpload
        workspace={this.props.workspace}
        value={this.state.order.upload_field}
        onDeleteFile={this.onDeleteFile.bind(this)}
        onFileUploaded={this.onFileUploaded.bind(this)} />
    </div> : this.state.order.upload_field
      ? <div className='input'><label htmlFor=''>{transUploadField}</label><a
        href={this.state.order.upload_field.document} target='_blank'> {this.state.order.upload_field.name}</a>
      </div> : null

    let dateCreated = moment(this.state.order.created_at).format('D. MMM. YYYY, HH:mm')
    let dateUpdated = moment(this.state.order.last_changed_at).format('D. MMM. YYYY, HH:mm')

    let created = this.props.selected ? <div className='input'><label
      htmlFor=''>{transDateCreated}</label> <p>{dateCreated}</p>
    </div> : null
    let updated = this.props.selected ? <div className='input'><label
      htmlFor=''>{transDateUpdated}</label> <p>{dateUpdated}</p>
    </div> : null
    let createdBy = this.props.selected ? <div className='input'><label
      htmlFor=''>{transCreatedBy}</label> <p>{this.state.order.created_by}</p>
    </div> : null
    let lastChangedBy = this.props.selected ? <div className='input'><label
      htmlFor=''>{transLastChangedBy}</label><p> {this.state.order.last_changed_by}</p>
    </div> : null

    return (
      <form action='#' className='mt-micro'>
        <Alert message={this.props.errors} category={'error'} />
        {dropDownLenderTeams}
        {dropDownStatus}
        {inputNotional}

        {dropDownLimited}
        {this.state.order.limited && this.state.order.limited.toString() === 'true' ? inputOrderLevelValue : null}

        {inputComments}
        {inputNextSteps}
        {dropDownAssignedTo}

        {inputUploadFields}

        {updated}
        {lastChangedBy}
        {created}
        {createdBy}

        {this.props.editMode
          ? <div className='input'>
            <ActionButton className='btn' type='button' onClick={this.onSave.bind(this)}>{transSave}</ActionButton>
          </div> : null
        }

      </form>
    )
  }
}

OrderView.propTypes = {
  lenders: PropTypes.array.isRequired,
  members: PropTypes.array.isRequired,
  statuses: PropTypes.array.isRequired,
  types: PropTypes.array.isRequired,
  currencies: PropTypes.array.isRequired,
  typeFormatter: PropTypes.array.isRequired,
  editMode: PropTypes.bool.isRequired
}
