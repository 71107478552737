import React from 'react'
import PropTypes from 'prop-types'
import AccessProfile from './AccessProfile'
import Relationship from './Relationship'
import Alert from '../alert'
import EmployeeAlert from './EmployeeAlert'
import { gettext } from 'django'
import ActionButton from '../action_button'
import Autosuggest from 'react-autosuggest'
import Fuse from 'fuse.js'
import Highlight from 'react-highlighter'
import FileUpload from '../file_upload'
import { entity } from '../clproxy'

// Register Translations here
// ----------------------------------------------------------------------
/* global gettext */
const transSave = gettext('Send Invite')
const transResend = gettext('Resend Invite')
const transEmailDescription = gettext('E-Mail-Address Description')
const transCurrentNDA = gettext('Current NDA')
// ----------------------------------------------------------------------
const mailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

class GrantUserForm extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      relationships: [],
      saving: false,
      email: '',
      accessProfile: '',
      relationship: '',
      suggestions: [],
      nda: null,
      isEmployee: false
    }
    this.fuse_options = {
      shouldSort: true,
      tokenize: true,
      threshold: 0.0,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys: ['company', 'email', 'first_name', 'last_name']
    }
    this.fuse = new Fuse(this.props.contacts, this.fuse_options)
  }

  componentDidMount () {
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.errors || !this.props.relationships || !this.props.relationships.length) { return }

    if (!nextProps.selectedUser) {
      this.setState({
        email: '',
        relationship: this.props.relationships[0],
        accessProfile: this.props.relationships[0].accessProfiles.length && this.props.relationships[0].accessProfiles[0].id,
        nda: null,
        isEmployee: false
      })
    } else {
      this.setState({
        email: nextProps.selectedUser.user.email,
        relationship: this.props.relationships.find(r => r.value === nextProps.selectedUser.relationship),
        accessProfile: nextProps.selectedUser.access_profile && nextProps.selectedUser.access_profile.id,
        nda: null
      })
    }

    if (nextProps.contacts && (nextProps.contacts.length !== this.props.contacts.length)) {
      this.fuse = new Fuse(nextProps.contacts, this.fuse_options)
    }
  }

  fileUploaded (nda) {
    this.setState({nda})
  }

  _handleInputChange (event, extraArgs) {
    const target = event.target
    if (target.name === 'relationship') {
      this.setState({relationship: this.props.relationships.find(r => r.value === target.value)})
    } else if (extraArgs && extraArgs.newValue) {
      if (mailRegex.test(extraArgs.newValue)) {
        entity.validateUser(extraArgs.newValue, this.state.relationship)
          .then(validation => this.setState({isEmployee: validation.isConsideredAnEmployee, 'email': extraArgs.newValue}))
      } else {
        this.setState({'email': extraArgs.newValue})
      }
    } else {
      this.setState({[target.name]: target.value})
    }
  }

  _buildPermissionGranted (e) {
    e.preventDefault()
    const user = {
      email: this.state.email,
      slug: this.props.selectedUser && this.props.selectedUser.slug,
      relationship: this.state.relationship.value
    }
    if (this.state.accessProfile) {
      user.access_profile = this.state.accessProfile
    }
    if (this.state.nda) {
      user.nda = this.state.nda
    }

    return new Promise((resolve) => {
      this.setState({saving: true})
      resolve()
    })
    .then(() => this.props.onSave(user))
    .then((res) => this.setState({saving: false}))
  }

  render () {
    return [
      <Alert key='alert' message={this.props.errors} category={'error'} />,
      <div key='email_input' className='input'>
        <label htmlFor=''>Email</label>
        <Autosuggest
          suggestions={this.state.suggestions}
          onSuggestionsFetchRequested={({value, reason}) => this.setState({suggestions: this.fuse.search(value)})}
          onSuggestionsClearRequested={() => this.setState({suggestions: []})}
          getSuggestionValue={(suggestion) => suggestion.email}
          renderSuggestion={(suggestion, {query}) =>
            <span>
              <Highlight search={query}>{suggestion.first_name}</Highlight>
              &nbsp;<Highlight search={query}>{suggestion.last_name}</Highlight>
              &nbsp;(<Highlight search={query}>{suggestion.email}</Highlight>)
              &nbsp;-&nbsp;<Highlight search={query}>{suggestion.company}</Highlight>
            </span>}
          inputProps={{
            onChange: this._handleInputChange.bind(this),
            value: this.state.email,
            id: 'email',
            type: 'email',
            name: 'email',
            disabled: this.props.selectedUser
          }}
        />
        <div className='input__description'>
          {transEmailDescription}
        </div>
      </div>,
      <span key='isEmployeeSpan'>{this.state.isEmployee ? <EmployeeAlert key='employee_alert' /> : null}</span>,
      <Relationship
        key='relationships'
        disabled={this.props.selectedUser}
        onChange={this._handleInputChange.bind(this)}
        relationship={this.state.relationship.value}
        relationships={this.props.relationships}
      />,
      <AccessProfile
        key='accessprofiles'
        onChange={this._handleInputChange.bind(this)}
        accessProfiles={this.state.relationship && this.state.relationship.accessProfiles}
        accessProfile={this.state.accessProfile}
      />,
      <span key='resendInviteSpan'>
        {this.props.selectedUser && this.props.selectedUser.resend_invite && this.props.selectedUser.nda
          ? <a href={this.props.selectedUser.nda.url} target='_blank'>{transCurrentNDA}</a>
          : null
        }
        <br />
        {this.props.selectedUser && this.props.selectedUser.resend_invite
          ? <FileUpload value={this.state.nda} workspace={this.props.slug} onFileUploaded={this.fileUploaded.bind(this)} />
          : null }
      </span>,
      <div className='input | align-right | mt-small' key='buttons'>
        <button onClick={(e) => this.props.onClose(e)} className='btn btn--red mr-micro'>Cancel</button>
        <ActionButton
          type='submit'
          className='btn'
          disabled={!!this.state.saving}
          onClick={(e) => this._buildPermissionGranted(e)}>
          {this.props.selectedUser && this.props.selectedUser.resend_invite ? transResend : transSave}
        </ActionButton>
      </div>
    ]
  }
}

GrantUserForm.propTypes = {
  relationships: PropTypes.array.isRequired,
  errors: PropTypes.string,
  slug: PropTypes.string.isRequired,
  currentUser: PropTypes.string.isRequired,
  selectedUser: PropTypes.object,
  onSave: PropTypes.func.isRequired
}

GrantUserForm.defaultProps = {}

export default GrantUserForm
