import React from 'react'
import moment from 'moment'

function colorHash (inputString) {
  var sum = 0

  for (var i in inputString) {
    sum += inputString.charCodeAt(i)
  }

  let r = ~~(('0.' + Math.sin(sum + 1).toString().substr(6)) * 256)
  let g = ~~(('0.' + Math.sin(sum + 2).toString().substr(6)) * 256)
  let b = ~~(('0.' + Math.sin(sum + 3).toString().substr(6)) * 256)

  var hex = '#'

  hex += ('00' + r.toString(16)).substr(-2, 2).toUpperCase()
  hex += ('00' + g.toString(16)).substr(-2, 2).toUpperCase()
  hex += ('00' + b.toString(16)).substr(-2, 2).toUpperCase()

  return hex
}

export default function ({m}) {
  return (
    <div key={m.slug} className={'chat-message'}>
      <div className='author-meta__name | text-small | color-secondary'>
        <span className='profile-image profile-small profile-float'
          data-toggle='tooltip' title={m.participant.username}
          style={{background: colorHash(m.participant.username)}}>
          {m.participant.acronym}
        </span>
      </div>{m.participant.first_name} {m.participant.last_name}
      <div className='author-meta__company | text-small | color-secondary'>
        {moment(m.created_at).format(moment.defaultFormat + ', HH:mm')}
      </div>
      <div style={{whiteSpace: 'pre-wrap', wordWrap: 'break-word'}}>
        {m.content}
        {m.attachment ? <p><a href={m.attachment.url} target='_blank'>{m.attachment.name}</a></p> : null}
      </div>
    </div>
  )
}
