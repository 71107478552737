import { gettext } from 'django'
import Hopscotch from 'hopscotch/dist/js/hopscotch'

// Define the tour!

export default function tourWorkspaceForms () {
  return {
    id: "transactionworkspacetour",
    i18n: {
      nextBtn: gettext('Next Step'),
      prevBtn: gettext('Back Step'),
      doneBtn: gettext('Done Step'),
      skipBtn: gettext('Skip Step'),
      closeTooltip: gettext('Close it')
    },
    steps: [
      {
        title: gettext('TG Forms step 1 title'),
        content: gettext('TG Forms step 1 content'),
        target: '.nav-tabs',
        placement: "right"
      },
      {
        title: gettext('TG Forms step 2 title'),
        content: gettext('TG Forms step 2 content'),
        target: 'form__content',
        placement: "top"
      },
      {
        title: gettext('TG Forms step 3 title'),
        content: gettext('TG Forms step 3 content'),
        target: 'addNewFormButton',
        placement: "top"
      },
      {
        title: gettext('TG Forms step 4 title'),
        content: gettext('TG Forms step 4 content'),
        target: document.getElementsByClassName('tabs-icon')[0].getElementsByTagName('a')[0],
        placement: "left"
      },
      {
        title: gettext('TG Forms step 5 title'),
        content: gettext('TG Forms step 5 content'),
        target: document.getElementsByClassName('tabs-icon')[0].getElementsByTagName('a')[1],
        placement: "left"
      },
      {
        title: gettext('TG Forms step 6 title'),
        content: gettext('TG Forms step 6 content'),
        target: document.getElementsByClassName('tabs-icon')[0].getElementsByTagName('a').length > 2
          ? document.getElementsByClassName('tabs-icon')[0].getElementsByTagName('a')[2] : null,
        placement: "left"
      }
    ],
    showPrevButton: true
  }
}
