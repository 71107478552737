import React from 'react'
import AccordionElement from './AccordionElement'
import OwnersTable from './OwnersTable'
import Modal from '../modal'
import EntityForm from './EntityForm'
import { gettext } from 'django'

// Register Translations here
// ----------------------------------------------------------------------
const transForm = gettext('Owners structure')
const transEditEntityTitle = gettext('Edit entity')
// ----------------------------------------------------------------------

class Group extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
     showEditForm: false,
     entity: this.props.entity
    }
  }

  _onEdit(structure) {
    this.props.onEntitySaved(structure)
    this.setState({showEditForm: false})
  }

  render () {
    return (
      <div className='col-md-12'>
        <Modal id='entity_form'
          show={this.state.showEditForm}
          onClose={() => this.setState({showEditForm: false, entity: null})}>
          <EntityForm readOnly={!this.props.allowEdit} title={transEditEntityTitle}
            entity={this.state.entity} onEntitySaved={this._onEdit.bind(this)} />
        </Modal>
        <ul className='accordion'>
          <AccordionElement title={transForm}>
            <OwnersTable entity={this.props.entity} allowEdit={this.props.allowEdit}
              onEdit={()=>this.setState({showEditForm: true, entity: this.props.entity})} />
          </AccordionElement>
        </ul>
      </div>
    )
  }
}

export default Group
