import PropTypes from 'prop-types'
import { gettext } from 'django'

var React = require('react')
// Register Translations here
// ----------------------------------------------------------------------
// ---------------------------------------------------------

let DropDownField = function ({value, template, onUpdateHandler}) {
  var choiceHtml = template.options.map(function (options) {
    return (<option value={options.name} key={options.name}>{options.name}</option>)
  })

  return (
    <div className='input'>
      <label htmlFor=''>{template.label}</label>
      <select value={value} onChange={(evt) => onUpdateHandler(evt.target.value)}>
        {choiceHtml}
      </select>
      <div className='input__description'>
        {template.help_text}
      </div>
    </div>
  )
}

DropDownField.propTypes = {
  id: PropTypes.number.isRequired,
  value: PropTypes.string,
  template: PropTypes.object.isRequired
}

export default DropDownField
