import React from 'react'
import { gettext } from 'django'

// Register Translations here
// ----------------------------------------------------------------------
const transAddNewCategory = gettext('+ Add')
const transExpandGroup = gettext('Show list of chats')
// ----------------------------------------------------------------------

export default function RoomList ({room, categories, onClickCategory, onAddNewCategory, onEdit}) {
  let container = React.createRef()
  function roomCategories (room) {
    return categories.filter(c => c.added_by_default && !room.categories.some(rc => rc === c.name))
                     .map(c => c.name)
                     .concat(room.categories)
  }

  return (
    <li key={room.slug} ref={container}>
      <header>
        <a href='#'
          onClick={() => { container.current.className = (container.current.className === '' ? 'is-open has-data' : '') }}>
          <span title={transExpandGroup}
            data-toggle='tooltip' data-placement='right'>
            <svg className='icon'>
              <use xlinkHref='#icon-arrow' />
            </svg>
          </span>
        </a>
        <div style={{textOverflow:'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}} data-placement='top'
          data-toggle='tooltip' title={room.name}
          className={room.categories.some(c => c.new_messages > 0) ? 'errorlist detail-list__title' : 'detail-list__title'}>
          {room.name}
        </div>
        <a href='#' className='detail-list__add' id='edit-group-btn' onClick={onEdit}>
          <span title={room.participants.map(p => p.username).join(' ')}
            data-toggle='tooltip' data-placement='top'>
            <svg className='icon icon--person' ref={node => node && node.style.setProperty('transform', 'unset', 'important')}>
              <use xlinkHref='#icon-person' />
            </svg>
          </span>
        </a>
      </header>
      <div className='detail-list__content'>
        <div>
          <a href='#' id={`new_category_${room.slug}`} onClick={() => onAddNewCategory(room)}>{transAddNewCategory}</a>
        </div>
        {roomCategories(room).map((c, i) => <div key={i}>
          <a key={c.name} className={c.new_messages ? 'link--delete' : null}
            href='#' onClick={() => onClickCategory(room.slug, c.name)}>
            # {c.name}
          </a>
        </div>)}
      </div>
    </li>)
}
