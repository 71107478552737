import { gettext } from 'django'
import Hopscotch from 'hopscotch/dist/js/hopscotch'

// Define the tour!

var tourAccount = {
  id: "account",
  i18n: {
    nextBtn: gettext('Next Step'),
    prevBtn: gettext('Back Step'),
    doneBtn: gettext('Done Step'),
    skipBtn: gettext('Skip Step'),
    closeTooltip: gettext('Close it')
  },
  steps: [
    {
      title: gettext('TG Account step 1 title'),
      content: gettext('TG Account step 1 content'),
      target: 'nav-account-profile',
      placement: "bottom"
    },
    {
      title: gettext('TG Account step 2 title'),
      content: gettext('TG Account step 2 content'),
      target: 'nav-account-notifications',
      placement: "bottom"
    },
    {
      title: gettext('TG Account step 3 title'),
      content: gettext('TG Account step 3 content'),
      target: 'nav-account-languages',
      placement: "bottom"
    }],
  showPrevButton: true
}

export default tourAccount
