import React from 'react'
import PropTypes from 'prop-types'
import { gettext } from 'django'

import {chat} from '../clproxy'
import RoomList from './room_list'
import RoomEditor from './room_editor'
import RoomMessages from './room_messages'
import NewCategory from './new_category'
import Alert from '../alert'

// Register Translations here
// ----------------------------------------------------------------------
const transCategoryError = gettext('Category already exists')
// ----------------------------------------------------------------------

const EDIT_ROOM = 'new_group'
const NEW_CATEGORY = 'new_category'
const ROOM_LIST = 'group_list'
const ROOM_MESSAGES = 'chat_view'

class Chat extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      categories: JSON.parse(props.categories),
      mode: ROOM_LIST,
      messages: [],
      selected: null
    }
  }

  _getRooms () {
    return chat.getRooms(this.props.workspace)
               .then(this.props.updateRooms)
               .catch((error) => window.alert(error.message || error)) // this.setState({error: error.message || error}))
  }

  showRoomList () {
    this.setState({mode: ROOM_LIST})
    this._getRooms()
  }

  showRoomEditor (room) {
    this.setState({mode: EDIT_ROOM, room: room})
  }

  createNewRoom (room) {
    return chat.createOrUpdateRoom(this.props.workspace, room, this.props.currentuser_slug)
      .then(this._getRooms.bind(this))
      .then(this.showRoomList.bind(this))
      .catch((error) => window.alert(error.message || error)) // this.setState({error: error.message || error}))
  }

  showNewCategoryForm (room) {
    this.setState({mode: NEW_CATEGORY, selected: {room}})
  }

  createNewCategory (room, category) {
    if (room.categories.indexOf(category) === -1) {
      this._addMessage(room.slug, category, {message: `# ${category}`})
          .then(() => this.props.navigateToRoom(room.slug, category))
          .catch((error) => window.alert(error.message || error)) // this.setState({error: error.message || error}))
    } else {
      window.alert(transCategoryError)
    }
  }

  addMessage (content, selectedRoom = null, selectedCategory = null) {
    let room = selectedRoom || this.state.selected.room.slug
    let category = selectedCategory || this.state.selected.category
    this._addMessage(room, category, content)
        .catch((error) => window.alert(error.message || error)) // this.setState({error: error.message || error}))
  }

  _addMessage (room, category, content) {
    return chat.addMessage(this.props.workspace, room, category, content)
      .then(() => chat.getRoomMessages(this.props.workspace, room, category))
      .then(messages => this.setState({messages: messages}))
  }

  markEverythingAsRead (room, category) {
    return chat.markEverythingAsRead(this.props.workspace, room, category)
      .then(() => chat.getRoomMessages(this.props.workspace, room, category))
      .then(messages => this.setState({messages: messages}))
      .then(this._getRooms.bind(this))
  }

  componentDidMount () {
    this._getRooms().then(() => this._checkMessageView(this.props.room, this.props.category))
    window.socket.addEventListener('message', e => {
      const data = JSON.parse(e.data)
      if (data.type === 'qa.reload') {
        this._getRooms()
        if (this.state.mode === ROOM_MESSAGES) {
          chat.getRoomMessages(this.props.workspace, this.state.selected.room.slug, this.state.selected.category)
              .then(messages => this.setState({messages: messages}))
              .catch((error) => window.alert(error.message || error)) // this.setState({error: error.message || error}))
        }
      }
    })
    window.$('[data-toggle="tooltip"]').tooltip()
  }

  componentDidUpdate () {
    window.$('[data-toggle="tooltip"]').tooltip()
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.category !== this.props.category || nextProps.room !== this.props.room) {
      if (nextProps.room && nextProps.category) {
        this._checkMessageView(nextProps.room, nextProps.category)
      } else {
        this.setState({mode: ROOM_LIST})
        this._getRooms()
      }
    }
  }

  _checkMessageView (room, category) {
    if (room && category) {
      return chat.getRoomMessages(this.props.workspace, room, category)
        .then(messages => this.setState({
          mode: ROOM_MESSAGES,
          messages: messages,
          selected: {room: this.props.rooms.find(r => r.slug === room), category: category}
        }))
    }
  }

  render () {
    if (!this.props.visible) {
      return null
    }

    var currentView = null

    if (this.state.mode === ROOM_LIST) {
      currentView = <RoomList key='content' rooms={this.props.rooms} onNewGroup={this.showRoomEditor.bind(this, null)}
        categories={this.state.categories} onAddNewCategory={this.showNewCategoryForm.bind(this)}
        onEdit={this.showRoomEditor.bind(this)}
        onClickCategory={this.props.navigateToRoom} />
    } else if (this.state.mode === EDIT_ROOM) {
      currentView = <RoomEditor key='content' participants={() => this.state.room
                                                    ? chat.getAvailableRoomParticipants(this.state.room)
                                                    : chat.getAvailableParticipants(this.props.workspace)}
        room={this.state.room} currentUser={this.props.currentuser}
        onCreate={this.createNewRoom.bind(this)}
        onCancel={this.showRoomList.bind(this)} />
    } else if (this.state.mode === ROOM_MESSAGES) {
      currentView = <RoomMessages key='content' room={this.state.selected.room} category={this.state.selected.category}
        workspace={this.props.workspace} messages={this.state.messages}
        onReadEverything={this.markEverythingAsRead.bind(this)}
        onSendMessage={this.addMessage.bind(this)} onBack={this.props.navigateToRoomList} />
    } else if (this.state.mode === NEW_CATEGORY) {
      currentView = <NewCategory key='content' categories={this.state.categories} room={this.state.selected.room}
        onCancel={this.showRoomList.bind(this)} onCreate={this.createNewCategory.bind(this)} />
    }

    return (
      <span>
        <Alert key='error-msg' message={this.state.error} category='error' />
        {currentView}
      </span>
    )
  }
}

Chat.propTypes = {
  currentuser: PropTypes.string.isRequired,
  currentuser_slug: PropTypes.string.isRequired,
  categories: PropTypes.string.isRequired,
  context: PropTypes.string,
  room: PropTypes.string,
  category: PropTypes.string
}

export default Chat
