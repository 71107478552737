import { gettext } from 'django'
import Hopscotch from 'hopscotch/dist/js/hopscotch'

// Define the tour!

export default function tourProjects () {
  return {
    id: "projects",
    i18n: {
      nextBtn: gettext('Next Step'),
      prevBtn: gettext('Back Step'),
      doneBtn: gettext('Done Step'),
      skipBtn: gettext('Skip Step'),
      closeTooltip: gettext('Close it')
    },
    steps: [
      {
        title: gettext('TG Projects step 1 title'),
        content: gettext('TG Projects step 1 content'),
        target: document.getElementsByClassName('page-title')[0],
        placement: "bottom"
      }],
    showPrevButton: true
  }
}
