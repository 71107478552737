import { gettext } from 'django'

let React = require('react')

// Register Translations here
// ----------------------------------------------------------------------
/* global gettext */
const transAccessProfile = gettext('Access Profile')
const transAccessProfileDescription = gettext('Access Profile Description')
// ----------------------------------------------------------------------

export default function AccessProfile ({accessProfile, accessProfiles, onChange}) {
  if (!accessProfiles.length) {
    return (<div />)
  }

  return (
    <div className='input'>
      <label htmlFor=''>{transAccessProfile}</label>
      <select
        name='accessProfile'
        id='accessProfile'
        value={accessProfile}
        onChange={onChange}>
        {accessProfiles.map(
          accessProfile => <option key={accessProfile.id} value={accessProfile.id}>{accessProfile.name}</option>)
        }
      </select>
      <div className='input__description'>
        {transAccessProfileDescription}
      </div>
    </div>
  )
}
