import { gettext } from 'django'
import Hopscotch from 'hopscotch/dist/js/hopscotch'

// Define the tour!

function tourAddressBook () {
  return {
    id: "addressBook",
    i18n: {
      nextBtn: gettext('Next Step'),
      prevBtn: gettext('Back Step'),
      doneBtn: gettext('Done Step'),
      skipBtn: gettext('Skip Step'),
      closeTooltip: gettext('Close it')
    },
    steps: [
      {
        title: gettext('TG AddressBook step 1 title'),
        content: gettext('TG AddressBook step 1 content'),
        target: 'addressbook-title',
        placement: "bottom"
      },
      {
        title: gettext('TG AddressBook step 2 title'),
        content: gettext('TG AddressBook step 2 content'),
        target: document.getElementsByClassName('rt-expander')[0],
        placement: "right"
      },
      {
        title: gettext('TG AddressBook step 3 title'),
        content: gettext('TG AddressBook step 3 content'),
        target: document.getElementsByClassName('icon--delete')[0],
        placement: "left"
      }],
    showPrevButton: true
  }
}

export default tourAddressBook
