import UserPermissionList from './UserPermissionList'
import PropTypes from 'prop-types'
import GrantUserForm from './GrantUserForm'

import 'whatwg-fetch'
import { gettext } from 'django'
import React from 'react'
import Alert from '../alert'
import Modal from '../modal'
import { workspace, account } from '../clproxy'
import ActionButton from '../action_button'

// Register Translations here
// ----------------------------------------------------------------------
/* global gettext */
const transTeamTitle = gettext('Team')
const transCreateInternalInvite = gettext('Grant access to user')
let transCreate = gettext('Grant access to user')
let transCancelBtn = gettext('Cancel')
let transDeleteBtn = gettext('Delete')
let transUndoneMsg = gettext('This step cannot be undone.')
let transDeleteMsg = gettext('Do you really want to delete this data entry?')
let transUserSuccessDeleteMsg = gettext('User successfully deleted')
let transAccessSuccessDeleteMsg = gettext('Workspace Access successfully granted')
let transLoadingMessage = gettext('Loading team...')
let transContacteAdded = gettext('Contact added succesfully')
let transEmptyWorkspace = gettext('You have invited some people to this workspace but still has not started sharing content with them. Visit the export view to start sharing with them.')

// ----------------------------------------------------------------------

class WorkspacePermissionsList extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      users: [],
      contacts: [],
      show_delete: false,
      statuses: JSON.parse(props.statuses.replace(/'/g, '"')),
      relationships: JSON.parse(props.relationships.replace(/'/g, '"')),
      allowed_relationships: [],
      showGrantForm: false,
      currentUser: JSON.parse(props.currentuser),
      alertMessage: null,
      loaded: false,
      errors: null,
      stats: {team_stats: []}
    }
  }

  componentDidMount () {
    account.loadAllowedRelationships(this.props.workspace, this.props.apiparams.split('=')[1])
      .then((relationships) => this.setState({allowed_relationships: relationships}))
    this._loadWorkspacePermissions()
    this._loadAddressBook()
    window.$('[data-toggle="tooltip"]').tooltip()
  }

  componentDidUpdate () {
    window.$('[data-toggle="tooltip"]').tooltip()
  }

  _loadAddressBook () {
    account.getUserAddressBook().then(contacts => this.setState({contacts: contacts}))
  }

  _loadWorkspacePermissions () {
    workspace.loadWorkspacePermissions(this.props.workspace, this.props.apiparams)
      .then((json) => {
        this.setState({
          users: json,
          loaded: true
        })
        return json
      })
      .then((users) => workspace.getRecipients(this.props.workspace)
        .then(recipients => {
          let empty_recipients = recipients.filter(r => r.is_empty)
          let empty_workspaces = users.filter(u => empty_recipients.find(r => r.slug === u.workspace_slug) != null)

          if (empty_workspaces.length > 0) {
            this.setState({alertMessage: {msg: transEmptyWorkspace, category: 'info'}})
          }
        }))

    if (this.props.show_stats === 'True') {
      workspace.loadStats(this.props.workspace)
        .then(stats => this.setState({stats: stats}))
    }
  }

  _deletePermission (permission) {
    let deleteFunct = () => {
      return workspace.deletePermission(permission.slug)
        .then((response) => {
          this._loadWorkspacePermissions()
          this.setState({show_delete: false, alertMessage: transUserSuccessDeleteMsg})
        })
        .catch(ex => this.setState({show_delete: false, alertMessage: {msg: ex.message, category: 'error'}}))
    }
    this.setState({show_delete: true, delete_funct: deleteFunct})
  }

  _grantUser (invite) {
    workspace.grantUser(this.props.workspace, invite, !this.state.selected)
      .then(
        (response) => {
          this.setState({alertMessage: transAccessSuccessDeleteMsg})
          this._hideGrantForm()
          this._loadWorkspacePermissions()
        }).catch(err => {
          this.setState({errors: err.message})
        }
      )
  }

  _hideGrantForm () {
    this.setState({'showGrantForm': false, selected: null, errors: null})
  }

  _addToAddressBook (user) {
    if (this.isAContact(user)) {
      window.location.href = '/account/address_book/'
    } else {
      return account.addUserToPersonalAddressBook(user.user.email)
                    .then(this._loadAddressBook.bind(this))
                    .then(() => this.setState({alertMessage: transContacteAdded}))
                    .catch(err => this.setState({alertMessage: {msg: err.message, category: 'error'}}))
    }
  }

  preventDeletion (user) {
    return this.state.currentUser.email === user.user.email ||
      !this.state.currentUser.relationshipsAllowedForDel.some(r => r === user.relationship) ||
      !this.state.currentUser.allowEditOtherUsers
  }

  preventEdition (user) {
    return this.state.currentUser.email === user.user.email ||
        !this.state.currentUser.relationshipsAllowedForDel.some(r => r === user.relationship) ||
        !this.state.currentUser.allowEditOtherUsers
  }

  canAddAsAContact (user) {
    return this.state.currentUser.email !== user.user.email &&
      user.status === 'accepted'
  }

  isAContact (user) {
    return this.state.contacts.some(c => c.email === user.user.email)
  }

  render () {
    if (!this.state.loaded) {
      return <div><br /><h1 className='h2 | align-center | mt-small mb-medium'>{transLoadingMessage}</h1></div>
    }

    return [
      <div key='header' className='page-title row mb-medium'>
        <div className='col-md-6'>
          <h2>{transTeamTitle}</h2>
        </div>
        <div className='col-md-6 align-right | mt-medium mb-small'>
          {this.state.allowed_relationships.length
          ? <button id='btn-grant-access-to-user' className='btn btn--big' onClick={() => this.setState({'showGrantForm': true})}>{transCreate}</button>
          : null}
        </div>
      </div>,
      <div key='alert' className='row'>
        <Alert
          message={(this.state.alertMessage && this.state.alertMessage.msg)
            ? this.state.alertMessage.msg : this.state.alertMessage}
          onClick={() => this.setState({'alertMessage': null})}
          category={(this.state.alertMessage && this.state.alertMessage.category) || 'success'} />
      </div>,
      <div key={'UserPermissionList'} className='row'>
        <UserPermissionList
          stats={this.state.stats}
          relationships={this.state.relationships}
          statuses={this.state.statuses}
          users={this.state.users}
          preventDeletion={this.preventDeletion.bind(this)}
          preventEdition={this.preventEdition.bind(this)}
          canAddAsAContact={this.canAddAsAContact.bind(this)}
          isAContact={this.isAContact.bind(this)}
          onEdit={(user) => this.setState({'showGrantForm': true, 'selected': user})}
          onAddToAddressBook={this._addToAddressBook.bind(this)}
          onDelete={this._deletePermission.bind(this)}
        />
      </div>,
      <Modal id='DeleteUserForm' show={this.state.show_delete} key={'DeleteUserForm'}
        onClose={() => this.setState({show_delete: false})}>
        <div title={transDeleteMsg}>
          <p>{transUndoneMsg}</p>
          <div className='input | align-right | mt-small'>
            <ActionButton
              onClick={this.state.delete_funct}
              className='btn btn--red mr-micro'>{transDeleteBtn}
            </ActionButton>
            <button onClick={() => this.setState({show_delete: false})} className='btn'>{transCancelBtn}</button>
          </div>
        </div>
      </Modal>,
      <Modal id='GrantUserForm' show={this.state.showGrantForm}
        key={'GrantUserForm'}
        onClose={this._hideGrantForm.bind(this)}>
        <GrantUserForm
          onClose={this._hideGrantForm.bind(this)}
          title={transCreateInternalInvite}
          errors={this.state.errors}
          relationships={this.state.allowed_relationships}
          slug={this.props.workspace}
          currentUser={this.props.currentuser}
          selectedUser={this.state.selected}
          onSave={this._grantUser.bind(this)}
          contacts={this.state.contacts}
        />
      </Modal>
    ]
  }
}

WorkspacePermissionsList.propTypes = {
  currentuser: PropTypes.string.isRequired,
  workspace: PropTypes.string.isRequired,
  show_stats: PropTypes.string.isRequired,
  apiparams: PropTypes.string.isRequired,
  relationships: PropTypes.string.isRequired,
  statuses: PropTypes.string.isRequired
}

export default WorkspacePermissionsList
