import { gettext } from 'django'

var React = require('react')

// Register Translations here
// ----------------------------------------------------------------------
const transEditTooltip = gettext('Edit Order Book Entry')
const transViewTooltip = gettext('View Order Book Entry')
const transDeleteTooltip = gettext('Delete Order Book Entry')
const transUnlimited = gettext('Unlimited')
const transEmptyValue = gettext('-')
// ----------------------------------------------------------------------
export default function OrderBookEntry (props) {
  let commentsValue = props.order.comments || transEmptyValue
  if (props.order.comments && props.order.comments.length > 10) {
    commentsValue = props.order.comments.substring(0, 8) + '[...]'
  }

  let nextStepsValue = props.order.next_steps || transEmptyValue
  if (props.order.next_steps && props.order.next_steps.length > 10) {
    nextStepsValue = props.order.next_steps.substring(0, 8) + '[...]'
  }

  let assignedToValue = props.order.assigned_to || transEmptyValue
  if (props.order.assigned_to && props.order.assigned_to.length > 10) {
    assignedToValue = props.order.assigned_to.substring(0, 8) + '[...]'
  }

  let orderLevelValue = props.order.limited ? (props.order.order_level_value && props.order.order_level_type
    ? (props.order.order_level_value.toString() + props.formattedType)
    : transEmptyValue)
    : transUnlimited

  let lenderTuple = props.lenders.find((entry) => entry[0] === props.order.lender)
  let lenderName = lenderTuple ? lenderTuple[1] : 'Inactive Lender'

  let currency = props.order.currency && props.currencies.find((currency) => currency[0] === props.order.currency)[1]

  return (
    <tr>
      <td>
        <div className={'mb-micro'} id={'viewOrder'}>
          {(props.onEdit)
            ? <a href='#' onClick={() => props.onView(props.order)} title={transViewTooltip} data-toggle='tooltip' data-placement='right'>
              <svg className='icon icon--light'>
                <use xlinkHref='#icon-eye' />
              </svg>
            </a> : null
          } &nbsp;
          {((props.relationship === 'core' || props.order.privacy === 'edit') && props.onEdit)
            ? <a href='#' onClick={() => props.onEdit(props.order)} title={transEditTooltip} data-toggle='tooltip' data-placement='right'>
              <svg className='icon icon--light'>
                <use xlinkHref='#icon-edit' />
              </svg>
            </a> : null
          }
        </div>
      </td>
      <td>{lenderName}</td>
      <td>{(props.order.status && props.statuses.find((entry) => entry[0] === props.order.status)[1]) || transEmptyValue}</td>
      <td
        align='right'>{(props.order.notional && parseFloat(props.order.notional).toLocaleString('de-DE') + currency) || transEmptyValue}</td>
      <td
        align='right'>{props.order.probability !== null ? parseInt(props.order.probability * 100) + '%' : transEmptyValue}</td>
      <td
        align='right'>{(props.order.prop_weighted_notional && props.order.prop_weighted_notional.toLocaleString('de-DE') + currency) || transEmptyValue}</td>
      <td align='right'>{orderLevelValue}</td>
      <td>{commentsValue}</td>
      <td>{nextStepsValue}</td>
      <td>{assignedToValue}</td>
      <td>{props.order.upload_field ? (<a
        href={props.order.upload_field.document}
        target='_blank'>{props.order.upload_field.name}></a>) : transEmptyValue}</td>
      {(props.relationship === 'core' || props.order.privacy === 'edit') ? (
        <td className='table__icon'>
          <a href='#' onClick={() => props.onDelete(props.order)} title={transDeleteTooltip} data-placement='left' data-toggle='tooltip'>
            <svg className='icon icon--delete'>
              <use xlinkHref='#icon-delete' />
            </svg>
          </a>
        </td>
      ) : null}
    </tr>
  )
}
