import { gettext } from 'django'
import cookie from 'cookie'
import TeamMembers from './TeamMembers'
import Modal from '../modal'
import Alert from '../alert'
import ActionButton from '../action_button'

var React = require('react')

// Register Translations here
// ----------------------------------------------------------------------
const transTeamTitle = gettext('Team')
const transDeleteBtn = gettext('Delete')
const transUndoneMsg = gettext('This step cannot be undone.')
const transDeleteMsg = gettext('You will remove the user from all ws of your company')
const transDeleteEmployee = gettext('You will remove the user from your company')
const transFirstName = gettext('First Name')
const transLastName = gettext('Last Name')
const transEmail = gettext('E-Mail-Address')
const transCompany = gettext('Company')
const transState = gettext('Re-send invite')
const transInviteSent = gettext('Invite sent')
const transError = gettext('An error has occurred. Try again later.')
const transNotAllowedErr = gettext('You cannot delete yourself from a workspace')
const transLoadingMessage = gettext('Entity team is loading...')

// ----------------------------------------------------------------------

class TeamMemberList extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      statuses: JSON.parse(props.statuses.replace(/'/g, '"')),
      memberList: [],
      show_delete: false,
      message: {},
      loaded: false
    }
  }

  loadTeam (additionalState = {}) {
    window.fetch('/api/entity/team', {'credentials': 'include'})
      .then(response => response.json())
      .then(json => json.map(member => ({...member, isOpen: false})))
      .then(members => this.setState({...additionalState, memberList: members, loaded: true}))
  }

  componentDidMount () {
    this.loadTeam()
    window.$('[data-toggle="tooltip"]').tooltip()
  }

  componentDidUpdate () {
    window.$('[data-toggle="tooltip"]').tooltip()
  }

  onExpand (member, expanding) {
    member.isOpen = !member.isOpen
    this._changeMember(member, member)
    if (!expanding || !!member.workspaces) {
      return
    }

    window.fetch(`/api/entity/team/${member.slug}/workspaces/`, {'credentials': 'include'})
      .then(response => {
        if (response.status === 200 || response.status === 201) {
          response.json().then(workspaces => {
            this._changeMember(member, {
              ...member,
              loaded: true,
              workspaces: workspaces.map(w => ({...w, current_user: member.current_user}))
            })
          })
        }
        if (response.status === 401) {
          window.location.href = '/auth/login/'
        }
      })
  }

  _changeMember (oldMember, newMember) {
    let members = this.state.memberList.map(m => {
      if (m.slug === oldMember.slug) {
        return newMember
      }
      return m
    })
    this.setState({memberList: members})
  }

  resend (inviteCode) {
    window.fetch(`/api/entity/invite/${inviteCode}/resend/`,
      {
        'credentials': 'include',
        method: 'put',
        headers: new window.Headers({
          'X-CSRFToken': cookie.parse(document.cookie).csrftoken,
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        })
      }).then(response => {
        if (response.status === 200) {
          this.setState({message: {category: 'success', text: transInviteSent}})
        } else if (response.status === 401) {
          window.location.href = '/auth/login/'
        } else {
          this.setState({message: {category: 'error', text: transError}})
        }
      }
    )
  }

  deletePermission (member, permission) {
    let title = member.is_employee ? transDeleteEmployee : transDeleteMsg

    let deleteFunct = () => {
      var path = '/api/permissions/all/'
      var body = JSON.stringify({username: member.email})
      if (permission) {
        path = `/api/permissions/${permission}/`
        body = ''
      }

      return window.fetch(path, {
        method: 'delete',
        contentType: 'json',
        credentials: 'include',
        headers: new window.Headers({
          'X-CSRFToken': cookie.parse(document.cookie).csrftoken,
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        }),
        body: body
      }).then(response => {
        if (response.status === 204) {
          this.loadTeam({show_delete: false, delete_funct: null})
        } else if (response.status === 403) {
          this.loadTeam({show_delete: false, message: {category: 'error', text: transNotAllowedErr}, delete_funct: null})
        } else {
          this.setState({show_delete: false, message: {category: 'error', text: transError}, delete_funct: null})
        }
      })
    }
    this.setState({show_delete: true, delete_title: title, delete_funct: deleteFunct})
  }

  render () {
    if (!this.state.loaded) {
      return (
        <h1 className='h2 | align-center | mt-small mb-medium'>{transLoadingMessage}</h1>
      )
    }

    return (
      <div className='col-md-12'>
        <Modal show={this.state.show_delete}
          onClose={() => this.setState({show_delete: false})}>
            <div title={this.state.delete_title}>
              <p>{transUndoneMsg}</p>
              <div className='input | align-right | mt-small'>
                <ActionButton
                  id='modal-delete'
                  onClick={this.state.delete_funct}
                  className='btn btn--red'>{transDeleteBtn}</ActionButton>
              </div>
            </div>
        </Modal>
        <div className='page-title row mb-medium'>
          <div className='col-md-12'>
            <h2>{transTeamTitle}</h2>
          </div>
        </div>
        <div className='row'>
          <Alert message={this.state.message.text} category={this.state.message.category}
            onClick={() => this.setState({message: {}})} />
        </div>
        <div className='row'>
          <div className='table col-md-12'>
            <table>
              <thead>
                <tr>
                  <th className='table__icon' />
                  <th>{transFirstName}</th>
                  <th>{transLastName}</th>
                  <th>{transEmail}</th>
                  <th>{transCompany}</th>
                  <th>{transState}</th>
                  <th className='table__icon' />
                </tr>
              </thead>
              <TeamMembers
                data={this.state.memberList}
                statuses={this.state.statuses}
                currentUser={this.props.currentuser}
                onExpand={this.onExpand.bind(this)}
                onDeleteMember={this.deletePermission.bind(this)}
                onResend={this.resend.bind(this)}
                onDeletePermission={this.deletePermission.bind(this)}
              />
            </table>
          </div>
        </div>
      </div>
    )
  }
}

export default TeamMemberList
