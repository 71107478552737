import PropTypes from 'prop-types'
import Datetime from 'react-datetime'
import moment from 'moment'

var React = require('react')

function updateDate (name, callback, value) {
  let date = moment(value, ['DD.MM.YYYY', 'DD.MM.YY'], true)

  callback({target: {name: name, value: date.isValid() ? date.format('YYYY-MM-DD') : value}})
}

let DateField = function ({value, name, label, helptext, disabled, onUpdateHandler}) {
  return (
    <div className='input input--date'>
      <label>{label}</label>
      <Datetime
        id={name}
        inputProps={{disabled: disabled, placeholder: 'DD.MM.YYYY'}}
        value={value && moment(value, 'YYYY-MM-DD', true).isValid()
               ? moment(value, 'YYYY-MM-DD').valueOf() : value}
        dateFormat={'DD.MM.YYYY'}
        timeFormat={false}
        onChange={(selectedDatetime) => updateDate(name, onUpdateHandler, selectedDatetime)}
      />
      <div className='input__description'>{helptext}</div>
    </div>
  )
}

DateField.propTypes = {
  value: PropTypes.object
}

export default DateField
