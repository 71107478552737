import React from 'react'
import PropTypes from 'prop-types'
import { gettext } from 'django'

// Register Translations here
// ----------------------------------------------------------------------
const transGroupNameLabel = gettext('Group title')
const transGroupNameHelp = gettext('A name for your group')
const transBack = gettext('< Back')
const transConfirm = gettext('Create group')
const transUsers = gettext('Participants in this group')
const transOwnerMustBePart = gettext('You should be part of the group')
const transLeavingGroup = gettext('Are you sure you want to leave the group')
// ----------------------------------------------------------------------

class RoomEditor extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      room: {
        slug: props.room ? props.room.slug : null,
        name: props.room ? props.room.name : '',
        participants: []
      },
      availableParticipantEntities: [],
      availableParticipants: []
    }
  }

  componentDidMount () {
    return this.props.participants().then(participants => {
      let availableParticipants = participants.filter(
                                        p => !this.props.room ||
                                        p.username === this.props.currentUser ||
                                        !this.props.room.participants.find(rp => rp.username === p.username))

      let availableParticipantEntities = availableParticipants.reduce((res, participant) => {
        if (res.indexOf(participant.company) === -1) {
          res.push(participant.company)
        }

        return res
      }, [])

      let roomParticipants = this.props.room
          ? this.props.room.participants.map(rp => {
            let p = participants.find(p => rp.username === p.username)

            return p ? p.slug : rp.slug
          })
          : [participants.find(p => p.username === this.props.currentUser).slug]

      this.setState({
        room: {...this.state.room, participants: roomParticipants},
        participants,
        availableParticipantEntities,
        availableParticipants
      })
    })
  }

  toogleEntity (e) {
    let entityParticipants = this.state.availableParticipants.filter(p => p.company === e.target.value).map(p => p.slug)
    this.setState({
      room: {
        ...this.state.room,
        participants: e.target.checked
          ? this.state.room.participants.concat(entityParticipants.filter(p => this.state.room.participants.indexOf(p) === -1))
          : this.state.room.participants.filter(p => entityParticipants.indexOf(p) === -1)
      }
    })
  }

  toogleParticipant (e) {
    this.setState({
      room: {
        ...this.state.room,
        participants: e.target.checked
          ? this.state.room.participants.concat([e.target.value])
          : this.state.room.participants.filter(p => p !== e.target.value)
      }
    })
  }

  setName (e) {
    this.setState({room: {...this.state.room, name: e.target.value}})
  }

  allParticipantSelected (company) {
    return !this.state.participants.filter(p => p.company === company).some(p => this.state.room.participants.indexOf(p.slug) === -1)
  }

  participantSelected (slug) {
    return this.state.room.participants.indexOf(slug) > -1
  }

  onSave () {
    if (this.state.room.participants.indexOf(this.state.participants.find(p => p.username === this.props.currentUser).slug) === -1) {
      if (!this.props.room) {
        window.alert(transOwnerMustBePart)
      } else if (window.confirm(transLeavingGroup)) {
        this.props.onCreate(this.state.room)
      }
    } else {
      this.props.onCreate(this.state.room)
    }
  }

  render () {
    return [
      <div className='row' key='backButton'>
        <div className='col-md-12'>
          <h5 className='align-left'><a href='#' onClick={this.props.onCancel}>{transBack}</a></h5>
        </div>
      </div>,
      <div className='row chat__content' key='groupName'>
        <div className='col-md-12'>
          <div className='input'>
            <label>{transGroupNameLabel}</label>
            <input name='title' maxLength={50} type='text' value={this.state.room.name} onChange={this.setName.bind(this)} />
            <div className='input__description'>{transGroupNameHelp}</div>
          </div>
        </div>
        {this.props.room ? <div className='col-md-12 alert alert--secondary'>
          <span>{transUsers}</span>
          <ul>
            {this.props.room.participants.map(p => <li key={p.username}>{`${p.first_name} ${p.last_name} (${p.company})`}</li>)}
          </ul>
        </div> : null}
        <div className='input col-md-12'>
          <ul className='accordion | '>
            {this.state.availableParticipantEntities.map(company => (<li className='accordion__item is-open' key={company}>
              <div className='accordion__title'>
                <div className='input'>
                  <fieldset>
                    <input id={`checkbox_${company}`} type='checkbox' value={company}
                      checked={this.allParticipantSelected(company)} onChange={this.toogleEntity.bind(this)} />
                    <label htmlFor={`checkbox_${company}`}>{company}</label>
                  </fieldset>
                </div>
              </div>
              <div className='accordion__content accordion__content--padding | text'>
                {this.state.availableParticipants.filter(p => p.company === company).map(p => (
                  <fieldset key={p.slug}>
                    <input id={`checkbox_${p.slug}`} type='checkbox' value={p.slug}
                      checked={this.participantSelected(p.slug)} onChange={this.toogleParticipant.bind(this)} />
                    <label htmlFor={`checkbox_${p.slug}`}>{`${p.first_name} ${p.last_name}`}</label>
                  </fieldset>
                ))}
              </div>
            </li>))}
          </ul>
        </div>
        <button className='btn mr-micro' onClick={this.onSave.bind(this)}>{transConfirm}</button>
      </div>
    ]
  }
}

RoomEditor.propTypes = {
  participants: PropTypes.func.isRequired,
  room: PropTypes.object,
  currentUser: PropTypes.string.isRequired,
  onCreate: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
}

export default RoomEditor
