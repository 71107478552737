import Datetime from 'react-datetime'
import moment from 'moment'


var React = require('react')

function updateDate (initialValue, name, callback, value) {
  let date = moment(value)
  callback(initialValue.year(date.year()).month(date.month()).date(date.date()), name)
}

function updateTime (initialValue, name, callback, value) {
  callback(moment(initialValue).hour(value.split(':')[0]).minute(value.split(':')[1]).second(0), name)
}

let DateTimeField = function ({value, name, label, helptext, disabled, onUpdateHandler}) {
  const datetime = moment(value)
  datetime.minute(datetime.minute() - (datetime.minute() % 15))
  return (
    <div className='input input--date'>
      <label>{label}</label>
      <div className='row'>
        <div className='col-xs-6'>
          <Datetime
            id={name}
            inputProps={{disabled}}
            value={datetime.valueOf()}
            dateFormat={'DD.MM.YYYY'}
            timeFormat={false}
            onChange={(selectedDatetime) => updateDate(datetime, name, onUpdateHandler, selectedDatetime)}
            onBlur={(selectedDatetime) => updateDate(datetime, name, onUpdateHandler, selectedDatetime)}
          />
        </div>
        <div className='col-xs-6'>
          <select onChange={(e) => updateTime(datetime, name, onUpdateHandler, e.target.value)}
            value={datetime.format('H:m') || ''} disabled={disabled}>
            {[...Array(24).keys()].map(h => [...Array(4).keys()].map(m =>
              <option key={`${h}_${(m*15)}`} value={`${h}:${(m*15)}`}>
                {`${('00' + h).slice(-2)}:${('00' + (m*15)).slice(-2)}`}</option>))}
          </select>
        </div>
      </div>
      <div className='input__description'>{helptext}</div>
    </div>
  )
}

DateTimeField.propTypes = {
}

export default DateTimeField
