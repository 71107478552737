import PropTypes from 'prop-types'

var React = require('react')

function toFloatString (value) {
  return value.replace(/,$/gm, '.').replace(/[^0-9\.]/gm, '')
}

let FloatField = function ({value, template, onUpdateHandler}) {
  return (
    <div className='input'>
      <label>{template.label}</label>
      <input type='text' onChange={(evt) => onUpdateHandler(toFloatString(evt.target.value))} value={value} placeholder={template.default_value} />
      <div className='input__description'>{template.help_text}</div>
    </div>
  )
}

FloatField.propTypes = {
  id: PropTypes.number.isRequired,
  value: PropTypes.number,
  template: PropTypes.object.isRequired
}

export default FloatField
