import { gettext } from 'django'
import React from 'react'

// Register Translations here
// ----------------------------------------------------------------------
/* global gettext */
const transEmployeeWarning = gettext('This user will be invited as Employee of your Company.')

// ----------------------------------------------------------------------

export default function EmployeeAlert ({category = 'info'}) {
  return (
    <div className={`alert alert--${category} mb-small`}>
      {transEmployeeWarning}
    </div>
  )
}
