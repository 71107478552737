import { gettext } from 'django'
import Hopscotch from 'hopscotch/dist/js/hopscotch'

// Define the tour!

export default function tourCorporateStructure () {
  return {
    id: "corporate_structure",
    i18n: {
      nextBtn: gettext('Next Step'),
      prevBtn: gettext('Back Step'),
      doneBtn: gettext('Done Step'),
      skipBtn: gettext('Skip Step'),
      closeTooltip: gettext('Close it')
    },
    steps: [
      {
        title: gettext('TG CorporateStructure step 1 title'),
        content: gettext('TG CorporateStructure step 1 content'),
        target: document.getElementsByClassName('page-title')[0],
        placement: "bottom"
      },{
        title: gettext('TG CorporateStructure step 2 title'),
        content: gettext('TG CorporateStructure step 2 content'),
        target: document.getElementsByClassName('ReactTable')[0],
        placement: "top"
      },{
        title: gettext('TG CorporateStructure step 3 title'),
        content: gettext('TG CorporateStructure step 3 content'),
        target: 'add-new-entity',
        placement: "bottom"
      },{
        title: gettext('TG CorporateStructure step 4 title'),
        content: gettext('TG CorporateStructure step 4 content'),
        target: 'go-to-cw',
        placement: "left"
      }],
    showPrevButton: true
  }
}
