import React, { useState } from 'react'
import { gettext } from 'django'
import Modal from '../modal'
import OwnersTable from './OwnersTable'
import EntityForm from './EntityForm'
import AccordionElement from './AccordionElement'

// Register Translations here
// ----------------------------------------------------------------------
const transTitle = gettext('Owners information')
const transFormOwners = gettext('Form owners')
const transEditEntityTitle = gettext('View entity')
// ----------------------------------------------------------------------

class OwnersExportGroup extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
     showEditForm: false,
    }
  }

  render () {
    if (!this.props.companyOwners) return null

    return [
      <h4 key='_title'>{transTitle}</h4>,
      <div className='row' key='content'>
        <div className='col-md-12'>
          <Modal id='entity_form'
            show={this.state.showEditForm}
            onClose={() => this.setState({showEditForm: false})}>
            <EntityForm readOnly title={transEditEntityTitle} entity={this.props.companyOwners} />
          </Modal>
          <ul className='accordion'>
            <AccordionElement id='export_form_ownership_info'
              title={transFormOwners} onSelect={this.props.onCompanySelectionChanged}
              checked={this.props.isFormOwnersSelected}>
              <OwnersTable entity={this.props.companyOwners} onEdit={() => this.setState({showEditForm: true})} />
            </AccordionElement>
          </ul>
        </div>
      </div>
    ]
  }
}

export default OwnersExportGroup
