import PropTypes from 'prop-types'

var React = require('react')

let TextField = function ({value, template, onUpdateHandler}) {
  return (
    <div className='input'>
      <label>{template.label}</label>
      <input type='text' onChange={(evt) => onUpdateHandler(evt.target.value)} value={value} placeholder={template.default_value} />
      <div className='input__description'>{template.help_text}</div>
    </div>
  )
}

TextField.propTypes = {
  id: PropTypes.number.isRequired,
  value: PropTypes.string,
  template: PropTypes.object.isRequired
}

export default TextField
