import http from './http'

function getRooms (context) {
  return http.fetch(`/api/chat/rooms/?context=${context}`, 'get')
}

function createOrUpdateRoom (context, room, currentuser) {
  let url = room.slug ? `/api/chat/rooms/${room.slug}/?context=${context}` : `/api/chat/rooms/?context=${context}`
  let method = room.slug ? 'put' : 'post'
  let participants = room.participants.filter(p => !!p).map(p => ({slug: p}))
  let body = {name: room.name, participants: participants}
  if (room.slug) { body.slug = room.slug }

  return http.fetch(url, method, body)
}

function getRoomMessages (context, room, category) {
  return http.fetch(`/api/chat/messages/?context=${context}&room=${room}&category=${category}`, 'get')
}

function addMessage (context, room, category, content) {
  let body = { context, room, category, content: content.message }
  if (content.attachment) { body.attachment = {slug: content.attachment.slug} }

  return http.fetch(`/api/chat/messages/`, 'post', body)
}

function getAvailableParticipants (context) {
  return http.fetch(`/api/chat/participants/?context=${context}`, 'get')
}

function getAvailableRoomParticipants (room) {
  return http.fetch(`/api/chat/participants/${room.slug}`, 'get')
}

function markEverythingAsRead (context, room, category) {
  return http.fetch(`/api/chat/messages/set_read/?context=${context}&room=${room}&category=${category}`, 'get')
}

export default {
  getRooms,
  createOrUpdateRoom,
  getRoomMessages,
  addMessage,
  getAvailableParticipants,
  getAvailableRoomParticipants,
  markEverythingAsRead
}
