import PropTypes from 'prop-types'
import Datetime from 'react-datetime'
import moment from 'moment'

var React = require('react')

let DateField = function ({value, template, onUpdateHandler}) {
  return (
    <div className='input input--date'>
      <label>{template.label}</label>
      <Datetime dateFormat='DD.MM.YYYY' timeFormat={false} value={value}
        onChange={(date) => onUpdateHandler(date.format ? date.format() : date)}
        onBlur={(date) => onUpdateHandler(date.format ? date.format() : moment(date).format())} />
      <div className='input__description'>{template.help_text}</div>
    </div>
  )
}

DateField.propTypes = {
  id: PropTypes.number.isRequired,
  value: PropTypes.string,
  template: PropTypes.object.isRequired
}

export default DateField
