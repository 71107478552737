import { gettext } from 'django'

let React = require('react')

// Register Translations here
// ----------------------------------------------------------------------
const transTeamInviteAccepted = gettext('Accepted')
const transResendBtn = gettext('Resend')
// ----------------------------------------------------------------------

export default function TeamMemberStatus ({canResendInvitation, resend}) {
  let res = transTeamInviteAccepted
  if (canResendInvitation) {
    res = <span><button className='btn' onClick={resend}>{transResendBtn}</button></span>
  }

  return res
}
