import { gettext } from 'django'
import React from 'react'
import PropTypes from 'prop-types'
import CorporateStructureTable from './CorporateStructureTable'
import { entity } from '../clproxy'
import EntityForm from './EntityForm'
import Modal from '../modal'

// Register Translations here
// ----------------------------------------------------------------------
const transTitle = gettext('CWS Dashboard')
const transNothingAddedYet = gettext('Nothing added yet')
const transAddEntityTitle = gettext('Add new entity')
const transUpdateEntityTitle = gettext('Update entity')
const transCorporateStructureTitle = gettext('Corporate structure')
const transExport = gettext('Export corporate structure as pdf')
// ----------------------------------------------------------------------

class CorporateStructureTab extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      structure: [],
      shareholders: [],
      showEntityForm: false
    }
  }

  componentDidMount () {
    entity.loadStructure(this.props.workspace).then(structure => this.setState({structure}))
  }

  componentDidUpdate () {
  }

  _structureUpdated (structure) {
    this.setState({structure, showEntityForm: false, selectedEntity: null})
  }

  _viewEntity (slug) {
    let entity = this.state.structure.find(e => e.slug === slug)
    this.setState({showEntityForm: true, selectedEntity: entity})
  }

  render () {
    return (
      <div className='col-md-12'>
        <Modal id='entity_form'
          show={this.state.showEntityForm}
          onClose={() => this.setState({showEntityForm: false, selectedEntity: null})}>
          <EntityForm title={this.state.selectedEntity ? transUpdateEntityTitle : transAddEntityTitle}
            entity={this.state.selectedEntity} onEntitySaved={this._structureUpdated.bind(this)}
            onEntityDeleted={this._structureUpdated.bind(this)} mdmType={this.props.mdm_type} readOnly={true} />
        </Modal>
        <div key='form-row' className='page-title row mb-medium' style={{borderBottom: '0px'}}>
          <div className='col-md-8'>
            <h2 id='wk_name' style={{textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}}>
              {transTitle}
            </h2>
          </div>
          <div className='col-md-4'>
            <a href={this.props.corporateStructurePdf}>
              <button className='btn btn--flex btn--align-center' >
                  {transExport}
              </button>
            </a>
          </div>
        </div>
        <div className='row mt-medium'>
          <h4 className='col-md-12'>{transCorporateStructureTitle}</h4>
        </div>
        <div className='row mt-medium'>
          <div className='col-md-12'>
            {this.state.structure.length
            ? <div className='table'>
              <CorporateStructureTable workspace={this.props.default_corporate_workspace} structure={this.state.structure}
                onView={this._viewEntity.bind(this)} readOnly/>
            </div>
            : <div>
              <h2>{transNothingAddedYet}</h2>
            </div>}
          </div>
        </div>
      </div>
    )
  }
}

CorporateStructureTab.propTypes = {

}

export default CorporateStructureTab
