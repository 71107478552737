import PropTypes from 'prop-types'
import { gettext } from 'django'
import React from 'react'
import { workspace, entity } from '../clproxy'
import scroll from '../scroll'
import FormGroups from './FormGroups'
import Alert from '../alert'
import OwnersExportGroup from '../company_structure/OwnersExportGroup'

// Register Translations here
// ----------------------------------------------------------------------
const transExportTo = gettext('Export to')
const transExport = gettext('Apply changes')
const transSelectAll = gettext('Select all')
const transLostChanges = gettext('All changes will get lost unless you applied the changes.')
const transNoAvailableTarget = gettext('There isn\'t a project workspace/lender to use as target')
const transNoData = gettext('Your workspace does not contain any data capable to being exported')
const transEmptyError = gettext('workspace empty')
const transDataTitle = gettext('Data')
const transNoDataExported = gettext('You must select some data to export owners\'s info')
// ----------------------------------------------------------------------

class ExportView extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      initial_selection: {formData:{}},
      selection: {formData:{}},
      workspaces: [],
      disabled: true
    }
  }

  componentDidMount () {
    this._loadData(this.props.targetWorkspace)
    window.$('[data-toggle="tooltip"]').tooltip()
    if (this.props.formValue) {
      workspace.loadFormsStructure(this.props.workspace, this.props.formValue.slug)
        .then(structure => this.setState({companyOwners: structure.length && structure[0]}))
    }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    window.$('[data-toggle="tooltip"]').tooltip()

    if ((prevProps.group !== this.props.group)) {
      let anchor = document.getElementById(`${this.props.form}_${this.props.group}`)
      scroll(anchor, 300, 'easeOutQuad')
    }

    if (!this.props.formValue) { return }
    if (prevProps.formValue !== this.props.formValue
        || prevProps.targetWorkspace !== this.props.targetWorkspace) {
      this._loadData(this.props.targetWorkspace)
      workspace.loadFormsStructure(this.props.workspace, this.props.formValue.slug)
        .then(structure => this.setState({companyOwners: structure.length && structure[0]}))
    }
  }

  _valueSelectionChanged (key, value) {
    let newSelection = JSON.parse(JSON.stringify(this.state.selection))
    newSelection.formData[key].selected = value

    this.setState({selection: newSelection})
  }

  _exportValues () {
    if (this._selectionIsEmpty() && this.state.selection.companyOwners) { window.alert(transNoDataExported); return; }

    let newSelection = {
      ...this.state.selection,
      formData: Object.keys(this.state.selection.formData).reduce((res, key) => {
        res[key] = this.state.selection.formData[key].selected
        return res
      }, {})
    }
    // TODO PATCH
    if (!this.state.companyOwners) {
      delete newSelection.companyOwners
    }

    return workspace.updateSharingFormGroupStatus(this.props.targetWorkspace, this.props.formValue.slug, newSelection)
      .then(() => this._loadData(this.props.targetWorkspace))
      .then(() => this.setState({initial_selection: this.state.selection}))
      .catch(ex => this.setState({error: ex.message}))
  }

  _loadData (transaction) {
    this.setState({disabled: true})
    workspace.getRecipients(this.props.workspace).then((recipients) => {
      if (recipients.length === 0) { throw new Error(transNoAvailableTarget) }
      let recipient = transaction || recipients[0].slug
      return workspace.getWorkspaceForms(recipient).then(forms => {
        this.props.setVisibleForms(forms)
        this.props.setTargetWorkspace(recipient)
        entity.loadStructure(recipient)
          .then(structure => this.props.setCorporateTabVisibility(structure.length > 0))
          .catch(() => this.props.setCorporateTabVisibility(false))
        this.setState({
          workspaces: recipients,
          error: recipients.some(r => r.is_empty)
            ? `${recipients.filter(r => r.is_empty).map(r => r.name).join(', ')} ${transEmptyError}` : null})
        if (this.props.formValue) {
          return this._loadSelection(recipient, this.props.formValue)
            .then(selection => this.setState(
              {initial_selection: selection, selection: selection, disabled: false}))
            .catch(ex => this.setState(
              {initial_selection: {formData:{}}, selection: {formData:{}}, disabled: true}))
        }
      })
    }).catch(ex => this.setState({error: ex.message, selection: {formData: {}}}))
  }

  _loadSelection (transaction, formObj) {
    if (!formObj) { throw new Error(transNoData) }
    return workspace.getFormSharingStatus(this.props.workspace, formObj.slug, transaction)
  }

  _selectAll (e, groupId) {
    let newSelection = JSON.parse(JSON.stringify(this.state.selection))
    let groups = groupId ? this.props.formValue.groups.filter(g => g.id === groupId) : this.props.formValue.groups

    groups.map(
      g => g.values.map(v => { newSelection.formData[v['id']].selected = e.target.checked })
    )

    this.setState({selection: newSelection})
  }

  _selectionHasNotChanged () {
    return Object.keys(this.state.initial_selection.formData).every(key => {
      return this.state.selection.formData[key].selected === this.state.initial_selection.formData[key].selected
    }) && this.state.selection.companyOwners === this.state.initial_selection.companyOwners
  }

  _selectionIsEmpty () {
    return Object.keys(this.state.selection.formData).every(key => !this.state.selection.formData[key].selected)
  }

  _toggleFormOwnersSelection (e) {
    this.setState({selection: {...this.state.selection, companyOwners: e.target.checked}})
  }

  render () {
    return [
      <div className='col-md-12' key='buttons'>
        <div key='header' className='block block--space-between | mt-medium'>
          <div className='input'>
            <label htmlFor='export_select'>{transExportTo}</label>
            <select id='export_select' onChange={(e) => this._loadData(e.target.value)}
              value={this.props.targetWorkspace || ''}>
              {this.state.workspaces.map(w => {
                return <option key={w.slug} value={w.slug}>{w.name} {w.is_empty ? `(${transEmptyError})` : null}</option>
              })}
            </select>
          </div>
          {!this.state.disabled ?
          <div className='input'>
            <label htmlFor='btn-apply'>&nbsp;</label>
            <button id='btn-apply' disabled={this._selectionHasNotChanged()}
              className='btn' onClick={() => this._exportValues()}>
              {transExport}
            </button>
          </div> : null}
        </div>
      </div>,
      <h5 key='title' className='mt-small'>{transLostChanges}</h5>,
      <OwnersExportGroup key='ownersgroup' companyOwners={this.state.companyOwners}
        onCompanySelectionChanged={!this.state.disabled && this._toggleFormOwnersSelection.bind(this)}
        isFormOwnersSelected={this.state.selection.companyOwners}/>,
      <br key='break'/>,
      <h4 key='data_title'>{transDataTitle}</h4>,
      <Alert key='error-msg' message={this.state.error} category='error' />,
      <div key='content' className='row'>
        <div className='input'>
          {this.state.disabled || this.props.formValue?.groups.every(g => g.values.length === 0) ? null :
          <fieldset>
            <input id='select_all' type='checkbox' onChange={this._selectAll.bind(this)}
              checked={this.props.formValue
                ? this.props.formValue.groups.every(g => g.values.every(v => this.state.selection.formData[v['id']]?.selected))
                : false} />
            <label htmlFor='select_all'>{transSelectAll}</label>
          </fieldset>}
        </div>
        <FormGroups groups={this.props.formValue?.groups.filter(g => g.values.length > 0)}
          formSlug={this.props.formValue && this.props.formValue.slug}
          selectedGroup={this.props.group}
          selection={this.state.selection.formData}
          disabled={this.state.disabled}
          selectAll={this._selectAll.bind(this)}
          onValueSelection={(key, value) => this._valueSelectionChanged(key, value)}
        />
      </div>
    ]
  }
}

ExportView.propTypes = {
  workspace_name: PropTypes.string.isRequired,
  workspace: PropTypes.string.isRequired,
  setVisibleForms: PropTypes.func.isRequired,
  setTargetWorkspace: PropTypes.func.isRequired,
  targetWorkspace: PropTypes.string
}

export default ExportView
