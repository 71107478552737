import { gettext } from 'django'
import Hopscotch from 'hopscotch/dist/js/hopscotch'

// Define the tour!

const tourDeadlines = {
  id: "deadlines",
  i18n: {
    nextBtn: gettext('Next Step'),
    prevBtn: gettext('Back Step'),
    doneBtn: gettext('Done Step'),
    skipBtn: gettext('Skip Step'),
    closeTooltip: gettext('Close it')
  },
  steps: [
    {
      title: gettext('TG Deadlines step 1 title'),
      content: gettext('TG Deadlines step 1 content'),
      target: 'addDeadline',
      placement: "left"
    },{
      title: gettext('TG Deadlines step 2 title'),
      content: gettext('TG Deadlines step 2 content'),
      target: 'showCalendar',
      placement: "left"
    },{
      title: gettext('TG Deadlines step 3 title'),
      content: gettext('TG Deadlines step 3 content'),
      target: 'exportAgenda',
      placement: "left"
    }],
  showPrevButton: true
}

export default tourDeadlines
