import React from 'react'
import { gettext } from 'django'

import RoomEntry from './room_entry'

// Register Translations here
// ----------------------------------------------------------------------
const transGroups = gettext('Groups')
const transAddNewChat = gettext('New Chat Group')
// ----------------------------------------------------------------------

export default function RoomList ({rooms, categories, onNewGroup, onAddNewCategory, onClickCategory, onEdit}) {
  return (
    <span>
      <div className='row'>
        <div className='col-md-11'>
          <h5 className='align-left'>{transGroups}</h5>
        </div>
        <div className='col-md-1'>
          <a href='#' data-toggle='tooltip' title={transAddNewChat}
            data-placement='top' className='detail-list__add' id='new-group-btn' onClick={onNewGroup}>
            <svg className='icon'>
              <use xlinkHref='#icon-edit' />
            </svg>
          </a>
        </div>
      </div>
      <div className='row chat__content'>
        <ul className='detail-list'>
          {rooms.map((r, i) => <RoomEntry key={i} room={r} categories={categories} onEdit={() => onEdit(r)}
            onAddNewCategory={onAddNewCategory} onClickCategory={onClickCategory} />)}
        </ul>
      </div>
    </span>)
}
