import React from 'react'
import ReactTable from 'react-table'
import { gettext } from 'django'

// Register Translations here
// ----------------------------------------------------------------------
const transExpandRow = gettext('Click here to see Data & Documents')
const transEditBtn = gettext('Edit')
const transEditEntityTitle = gettext('Edit Entity')
// ----------------------------------------------------------------------

const AccordionElement = ({id, title, children, checked, onSelect}) => {
  let accordion = React.createRef()

  return (
    <li className={'accordion__item'} ref={accordion}>
      <div className='accordion__title'>
        {onSelect ? <div className='input'>
          <fieldset>
            <input id={id || 'acc_item'} type='checkbox' onChange={onSelect}
              checked={checked} />
            <label htmlFor={id || 'acc_item'}></label>
          </fieldset>
        </div> : null}
        <span title={transExpandRow} data-toggle='tooltip'
          onClick={() => {
            accordion.current.className = 'accordion__item '
              + (accordion.current.className.search('is-open') > 0 ? '' : 'is-open')}}>
          <svg className='icon'>
            <use xlinkHref='#icon-arrow' />
          </svg>
        </span>
        <span>{title}</span>
      </div>
      <div className='accordion__content' style={{'paddingRight': '20px'}} >
        <div className='table table--scroll table--no-wrap'>
          {children}
        </div>
      </div>
    </li>
  )
}

export default AccordionElement
