import { gettext } from 'django'
import React from 'react'
import Alert from '../alert'
import PropTypes from 'prop-types'
import UsersActivity from './UsersActivity'
import DataAccess from './DataAccess'
import { workspace, account } from '../clproxy'
import debounce from 'debounce'

// Register Translations here
// ----------------------------------------------------------------------
const transLogTitle = gettext('Workspace log')
const transUserActivity = gettext('User activity')
const transDataAccess = gettext('Data access')
const transExport = gettext('Export activity table')
const transRefresh = gettext('Refresh')
// ----------------------------------------------------------------------
class WorkspaceLog extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      usersActivity: {},
      dataAccess: {},
      dataAccessColumns: [],
      usersActivityColumns: [],
      loadingUsersActivity: false,
      loadingDataAccess: false,
      usersActivityIsOpen: false,
      dataAccessIsOpen: false,
      pageSize: 10,
      dataFilters: '',
      usersFilters: ''
    }
    this._loadUsersActivityDeb = debounce((state, instance) => {
      this.setState({loadingUsersActivity: true, userTableState: state, usersFilters: state.filtered.reduce((val, f) => `${val}&${f.id}=${f.value}`, '')})
      workspace.getUsersActivity(this.props.workspace, state.pageSize, state.page, state.sorted, state.filtered)
        .then(usersActivity =>
          this.setState({
            usersActivity: usersActivity,
            loadingUsersActivity: false,
            usersActivityColumns: this._calculateColumns(this.state.usersActivityColumns, usersActivity)
          }))
    }, 200)
    this._loadDataAccessDeb = debounce((state, instance) => {
      this.setState({loadingDataAccess: true, dataTableState: state, dataFilters: state.filtered.reduce((val, f) => `${val}&${f.id}=${f.value}`, '')})
      workspace.getDataAccess(this.props.workspace, state.pageSize, state.page, state.sorted, state.filtered)
        .then(dataAccess =>
          this.setState({
            dataAccess: dataAccess,
            loadingDataAccess: false,
            dataAccessColumns: this._calculateColumns(this.state.dataAccessColumns, dataAccess)
          }))
    }, 200)
  }

  componentDidMount () {
    let initialState = {pageSize: this.state.pageSize, page: 0, sorted: [{id: this.props.defaultorder, desc: false}], filtered: []}
    this._loadUsersActivity(initialState)
    this._loadDataAccess(initialState)
    account.loadAllowedRelationships(this.props.workspace, 'members')
      .then(membersRel => account.loadAllowedRelationships(this.props.workspace, 'lenders')
            .then(lendersRel => this.setState({relationships: membersRel.concat(lendersRel)})))
  }

  _calculateColumns (oldCollection, response) {
    return [...new Set([...oldCollection, ...(response.data.length > 0 ? Object.keys(response.data[0]) : [])])]
  }

  _reloadData () {
    this._loadUsersActivity(this.state.userTableState)
    this._loadDataAccess(this.state.dataTableState)
  }

  _loadUsersActivity (state, instance) {
    this._loadUsersActivityDeb(state, instance)
  }

  _loadDataAccess (state, instance) {
    this._loadDataAccessDeb(state, instance)
  }

  render () {
    return [
      <div key='header' className='page-title row mb-medium'>
        <div className='col-md-6'>
          <h2>{transLogTitle}</h2>
        </div>
        <div className='col-md-6 align-right | mt-medium mb-small'>
          <button disabled={this.state.loading} id='btn-refresh'
            className='btn btn--big' onClick={() => this._reloadData()}>
            {transRefresh}
          </button>
        </div>
      </div>,
      <div key='alert' className='row'>
        <Alert
          message={(this.state.alertMessage && this.state.alertMessage.msg)
            ? this.state.alertMessage.msg : this.state.alertMessage}
          onClick={() => this.setState({'alertMessage': null})}
          category={(this.state.alertMessage && this.state.alertMessage.category) || 'success'} />
      </div>,
      <div key='log' className='row'>
        <ul className='accordion'>
          <li className={'accordion__item' + (this.state.usersActivityIsOpen ? ' is-open' : '')} >
            <div onClick={() => this.setState({usersActivityIsOpen: !this.state.usersActivityIsOpen})}
              className='accordion__title'>
              <svg className='icon'>
                <use xlinkHref='#icon-arrow' />
              </svg>
              <div className='detail-list__title'>{transUserActivity}</div>
              <span className='accordion__meta | text-micro | mr-micro'>
                <a id={'export-' + transUserActivity} target='_blank'
                  href={`/api/workspace/workspaces/${this.props.workspace}/activities/user/?format=csv${this.state.usersFilters}`}>
                  {transExport}
                </a>
              </span>
            </div>
            <div className='accordion__content'>
              <UsersActivity data={this.state.usersActivity.data} pageSize={this.state.pageSize}
                relationships={this.state.relationships} columns={this.state.usersActivityColumns}
                defaultSorted={{id: this.props.defaultorder, desc: false}}
                onFetchData={(state, instance) => this._loadUsersActivity(state, instance)}
                pages={this.state.usersActivity.totalPages} loading={this.state.loadingUsersActivity} />
            </div>
          </li>
          <li className={'accordion__item' + (this.state.dataAccessIsOpen ? ' is-open' : '')} >
            <div onClick={() => this.setState({dataAccessIsOpen: !this.state.dataAccessIsOpen})}
              className='accordion__title'>
              <svg className='icon'>
                <use xlinkHref='#icon-arrow' />
              </svg>
              <div className='detail-list__title'>{transDataAccess}</div>
              <span className='accordion__meta | text-micro | mr-micro'>
                <a id={'export-' + transUserActivity} target='_blank'
                  href={`/api/workspace/workspaces/${this.props.workspace}/activities/data/?format=csv${this.state.dataFilters}`}>
                  {transExport}
                </a>
              </span>
            </div>
            <div className='accordion__content'>
              <DataAccess data={this.state.dataAccess.data} pageSize={this.state.pageSize}
                defaultSorted={{id: this.props.defaultorder, desc: false}} columns={this.state.dataAccessColumns}
                onFetchData={(state, instance) => this._loadDataAccess(state, instance)}
                pages={this.state.dataAccess.totalPages} loading={this.state.loadingDataAccess} />
            </div>
          </li>
        </ul>
      </div>
    ]
  }
}

WorkspaceLog.propTypes = {
  currentuser: PropTypes.string.isRequired,
  workspace: PropTypes.string.isRequired
}

export default WorkspaceLog
