import {gettext} from 'django'
import MemberWorkspaces from './MemberWorkspaces'
import TeamMemberStatus from './TeamMemberStatus'
import TeamMemberActions from './TeamMemberActions'

var React = require('react')
// Register Translations here
// ----------------------------------------------------------------------
const transWorkspace = gettext('Workspace')
const transRole = gettext('AccessProfile')
const transRoleStatus = gettext('AccessProfile status')
const transArrowTooltip = gettext('Show workspaces')
const transLoadingMessage = gettext('Loading workspaces...')
// ----------------------------------------------------------------------

let TeamMembers = function ({data, statuses, currentUser, onExpand, onResend, onDeleteMember, onDeletePermission}) {
  let members = data.map(m => {
    let isCurrentUser = currentUser === m.email
    return [
      <tr key={m.email} className={m.isOpen ? 'is-open' : ''}>
        {m.has_workspaces
          ? <td className='table__icon'>
            <a href='#' onClick={() => onExpand(m, !m.isOpen)} title={transArrowTooltip} data-toggle='tooltip'>
              <svg className='icon icon--arrow icon--light'>
                <use xlinkHref='#icon-arrow' />
              </svg>
            </a>
          </td>
        : <td className='table__icon' />}
        <td>{m.first_name}</td>
        <td>{m.last_name}</td>
        <td>{m.email}</td>
        <td>{m.company}</td>
        <td><TeamMemberStatus canResendInvitation={m.can_resend_invitation} resend={() => onResend(m.token)} /></td>
        <td className='table__icon'><TeamMemberActions currentUser={isCurrentUser} deleteMember={() => onDeleteMember(m)} /></td>
      </tr>,
      <tr key={`workspaces_of_${m.email}`} className='table__details table__details--background table__details--indentation'>
        <td colSpan='7'>
          <div className='table__details__content'>
            <div className='table'>
              {m.loaded ? <table>
                <thead>
                  <tr>
                    <th>{transWorkspace}</th>
                    <th>{transRole}</th>
                    <th>{transRoleStatus}</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  <MemberWorkspaces data={m.workspaces} statuses={statuses} deletePermission={onDeletePermission.bind(null, m)} />
                </tbody>
              </table> : <p>{transLoadingMessage}</p>}
            </div>
          </div>
        </td>
      </tr>
    ]
  }).reduce((result, element) => result.concat(element), [])
  return (
    <tbody>
      {members}
    </tbody>
  )
}

export default TeamMembers
