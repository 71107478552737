import { gettext } from 'django'

var React = require('react')

// Register Translations here
// ----------------------------------------------------------------------
const transReinvite = gettext('reinvite')
const transCancelReinvite = gettext('cancel reinvite')
// ----------------------------------------------------------------------

export default function DeadlineEntry ({participant, participants, reinvites, onReinvite, onCancelReinvite}) {
  if (participants.find(dp => dp.username===participant.username)?.isdeclining) {
    if(reinvites && reinvites.indexOf(participant.username) > -1) {
      return (<span>
                {participant.first_name} {participant.last_name} &nbsp;
                <a href='#' id={`reinvite_${participant.username}`} onClick={onCancelReinvite}>
                  {transCancelReinvite}
                </a>
              </span>)
    } else {
      return (<span>
                <del>
                  {participant.first_name} {participant.last_name}
                </del>&nbsp;
                <a href='#' id={`reinvite_${participant.username}`} onClick={onReinvite}>
                  {transReinvite}
                </a>
              </span>)
    }
  } else {
    return `${participant.first_name} ${participant.last_name}`
  }
}
