import { gettext } from 'django'
import Hopscotch from 'hopscotch/dist/js/hopscotch'

// Define the tour!

export default function tourNotifications () {
  return {
    id: "notifications",
    i18n: {
      nextBtn: gettext('Next Step'),
      prevBtn: gettext('Back Step'),
      doneBtn: gettext('Done Step'),
      skipBtn: gettext('Skip Step'),
      closeTooltip: gettext('Close it')
    },
    steps: [
      {
        title: gettext('TG Notifications step 1 title'),
        content: gettext('TG Notifications step 1 content'),
        target: document.getElementsByClassName('workspace-nav__primary')[0],
        placement: "bottom"
      },
      {
        title: gettext('TG Notifications step 2 title'),
        content: gettext('TG Notifications step 2 content'),
        target: document.getElementsByClassName('notification__list')[0],
        placement: "bottom"
      }],
    showPrevButton: true
  }
}
