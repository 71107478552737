import * as Sentry from '@sentry/browser'

import cookie from 'cookie'
import { gettext } from 'django'

// Register Translations here
// ----------------------------------------------------------------------
const messages = {
  transNotImplementedError: gettext('Operation not implemented'),
  transDefault: gettext('Something was wrong, if it happens again please contact with support')
}
// ----------------------------------------------------------------------

function _isJSON (str) {
  try {
    return (JSON.parse(str) && !!str)
  } catch (e) {
    return false
  }
}

function _parseJSON (str) {
  return _isJSON(str) ? JSON.parse(str) : str
}

function _translateUnhandledErrors (text) {
  let unhandledErrors = [/^(NotImplementedError).*/]

  for (let i = 0; i < unhandledErrors.length; i++) {
    let res = text.match(new RegExp(unhandledErrors[i]))

    if (res) return messages['trans' + res[1]]
  }

  return messages.transDefault
}

function isAString (object) {
  return (typeof object === 'string' || object instanceof String)
}

function _getStringError (errors) {
  return Object.keys(errors).reduce((string, key) => {
    if (key === 'message') {
      return errors[key]
    } else if (!isAString(errors[key][0])) {
      return string + _getStringError(errors[key])
    } else if (['detail', 'non_field_errors'].indexOf(key) === -1) {
      return `${string}${gettext(key)}: ${errors[key][0]} `
    } else {
      return `${string}${errors[key][0]} `
    }
  }, '')
}

function fetch (url, method, body) {
  return window.fetch(encodeURI(url), {
    method: method,
    contentType: 'json',
    credentials: 'include',
    headers: new window.Headers({
      'X-CSRFToken': cookie.parse(document.cookie).csrftoken,
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
    }),
    body: body && JSON.stringify(body)

  }).then(response => {
    return response.text().then((text) => {
      if (response.status >= 200 && response.status < 300) {
        return _parseJSON(text)
      } else if (response.status === 401) {
        window.location.href = '/auth/login/'
      } else {
        if (!_isJSON(text)) {
          Sentry.setExtra({extra: {response: text}})
          throw new Error(_translateUnhandledErrors(text))
        } else {
          let errors = _parseJSON(text)
          if (Array.isArray(errors)) {
            throw new Error(errors.join(', '))
          } else {
            throw new Error(_getStringError(errors))
          }
        }
      }
    })
  })
}

export default { fetch }
