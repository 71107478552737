import PropTypes from 'prop-types'
import { gettext } from 'django'
import React from 'react'
import ReactTable from 'react-table'
import moment from 'moment'

// Register Translations here
// ----------------------------------------------------------------------
const transExpandRow = gettext('Click here to see Data & Documents')
const transLastViewChange = gettext('Last view change')
const transIsExported = gettext('Is exported?')
// ----------------------------------------------------------------------

class FormGroup extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      isOpen: props.id === parseInt(props.selectedgroup)
    }
  }

  componentDidMount () {
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (parseInt(nextProps.selectedgroup) === this.props.id && nextProps.selectedgroup !== this.props.selectedgroup) {
      this.setState({isOpen: true})
    }
  }

  _selectAll (e) {
    this.props.selectAll(e, this.props.id)
  }

  _parseValues (k, value) {
    if (!value) { return null }

    switch (this.props.definition[k].type) {
      case 'document-field':
        return <a href={value.document}>{value.name}</a>

      case 'date-field':
        return moment(value).format()

      default:
        return value
    }
  }

  render () {
    let selectionCount = this.props.values.filter(value => this.props.selection[value.id]?.selected).length
    let total = this.props.values.length
    const columns = [
      {
        id: `selector`,
        Header: transIsExported,
        Cell: props =>
          <div className='input' text-align='center'>
            {!this.props.disabled ?
            <fieldset>
              <input id={`checkbox${props.original.id}`} checked={this.props.selection[props.original.id]?.selected}
                type='checkbox' onChange={(e) => this.props.onSelect(props.original.id, e.target.checked)} />
              <label htmlFor={`checkbox${props.original.id}`}></label>
            </fieldset> : null}
          </div>
      }
    ].concat(Object.keys(this.props.definition).filter(k => ['approved', 'status', 'id', 'lastViewChange'].indexOf(k) === -1).map((k, index) => ({
      id: `${index}-${k}`,
      Header: k,
      accessor: value => value[k],
      Cell: props => this._parseValues(k, props.value)
    })))



    return (
      <li id={this.props.formslug + '_' + this.props.id} className={'accordion__item' + (this.state.isOpen ? ' is-open' : '')}>
        <div className='accordion__title' onClick={() => this.setState({isOpen: !this.state.isOpen})}>
          <div className='input'>
            {!this.props.disabled
            ? <fieldset>
              <input id={`select_all_in_${this.props.id}`} type='checkbox' onChange={this._selectAll.bind(this)}
                checked={this.props.values.every(v => this.props.selection[v['id']]?.selected)} />
              <label htmlFor={`select_all_in_${this.props.id}`}>{`${selectionCount}/${total}`}</label>
            </fieldset> : null}
          </div>
          <span title={transExpandRow} data-toggle='tooltip'>
            <svg className='icon'>
              <use xlinkHref='#icon-arrow' />
            </svg>
          </span>
          <span>{this.props.title}</span>
          <span className='accordion__meta | text-micro | mr-micro' />
        </div>
        <div className='accordion__content' style={{paddingRight: '20px'}}>
          <div className='table table--scroll table--no-wrap'>
            <ReactTable
              minRows='1'
              pageSize={this.props.values ? this.props.values.length : 10}
              showPagination={false}
              filterable={false}
              data={this.props.values}
              columns={columns}
            />
          </div>
        </div>
      </li>
    )
  }
}

FormGroup.propTypes = {
  definition: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  values: PropTypes.array.isRequired,
  onSelect: PropTypes.func,
  selection: PropTypes.object
}

export default FormGroup
