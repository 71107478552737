import { gettext } from 'django'

let React = require('react')

// Register Translations here
// ----------------------------------------------------------------------
/* global gettext */
const transRelationship = gettext('Relationship')
const transRelationshipDescription = gettext('Relationship with Workspace')

// ----------------------------------------------------------------------

export default function Relationship ({relationships, relationship, disabled, onChange}) {
  return (
    <div className='input'>
      <label htmlFor=''>{transRelationship}</label>
      <select name='relationship' id='relationship' value={relationship} onChange={onChange} disabled={disabled}>
        {relationships.map(
          relation => <option key={relation.value} value={relation.value}>{relation.name}</option>)
        }
      </select>
      <div className='input__description'>
        {transRelationshipDescription}
      </div>
    </div>
  )
}
