import { gettext } from 'django'
import Modal from '../modal'
import ActionButton from '../action_button'

var React = require('react')
// Register Translations here
// ----------------------------------------------------------------------
const transAddBtn = gettext('Add new form')
const transTitle = gettext('Add new form to this workspace')
const transDeleteTitle = gettext('Delete form from this workspace')
const transFormTemplate = gettext('Form template')
const transDescription = gettext('Select the form template')
const transInfoColumn = gettext('Workpsace Form info')
const transUndoneMsg = gettext('This step cannot be undone.')
const transDeleteBtn = gettext('Delete')

// ----------------------------------------------------------------------

function AddNewForm ({templates, addNewForm}) {
  return (
    <div>
      <div className='row'>
        <div className='col-lg-4'>
          <form>
            <div className='input'>
              <label htmlFor=''>{transFormTemplate}</label>
              <select id='form_template' style={{width: '450px'}}>
                {templates.map(t => <option key={t.id} value={t.id}>{t.name}</option>)}
              </select>
              <div className='input__description'>{transDescription}</div>
            </div>
          </form>
        </div>
        <div className='col-lg-8'>
          <h3>{transInfoColumn}</h3>
          {templates.map(template =>
            <div key={template.id} className='advanced__list'>
              <h4>{window.django_locale === 'de' ? template.title_de : template.title_en}</h4>
              <p dangerouslySetInnerHTML={{__html: window.django_locale === 'de' ? template.description_de : template.description_en}} />
            </div>
          )}
        </div>
      </div>

      <div className='input | align-right | mt-medium'>
        <ActionButton
          className='btn'
          type='button'
          onClick={() => addNewForm(document.getElementById('form_template').value)}>{transAddBtn}
        </ActionButton>
      </div>
    </div>

  )
}

AddNewForm.title = transTitle

export default function Actions ({toogleVisibility, showForm, templates, disabled, deleteDisabled,
                                        showDeleteForm, toogleDeleteVisibility, deleteForm, addNewForm}) {
  return [
    <Modal key='modal' show={showForm} onClose={toogleVisibility}>
      <AddNewForm title={AddNewForm.title} templates={templates} addNewForm={addNewForm} />
    </Modal>,
    <Modal show={showDeleteForm} key='delete-modal'
      onClose={toogleDeleteVisibility}>
      <div title={transDeleteTitle}>
        <p>{transUndoneMsg}</p>
        <div className='input | align-right | mt-small'>
          <ActionButton
            id='modal-delete'
            onClick={deleteForm}
            className='btn btn--red'>{transDeleteBtn}</ActionButton>
        </div>
      </div>
    </Modal>,
    <div key={'actionButtons'} className='action-wrapper multiple-buttons'>
      <button
        id='addNewFormButton'
        className='btn'
        onClick={toogleVisibility}
        disabled={disabled}>{transAddBtn}
      </button>
      <button
        id='deleteFormButton'
        className='btn second'
        onClick={toogleDeleteVisibility}
        disabled={deleteDisabled}>{transDeleteBtn}
      </button>
    </div>
  ]
}
