import cookie from 'cookie'
import { gettext } from 'django'
import React from 'react'
import Hopscotch from 'hopscotch/dist/js/hopscotch'

import tourDashboard from './Dashboard'
import tourAccount from './Account'
import tourAddressBook from './AddressBook'
import tourNotifications from './Notifications'
import tourEntityTeam from './EntityTeam'
import tourFallback from './Fallback'
import tourOrderBook from './OrderBook'
import tourWorkspaceForms from './WorkspaceForms'
import tourExport from './Export'
import tourWorkspaceMembers from './WorkspaceMembers'
import tourDeadlines from './Deadlines'
import tourUserDeadlines from './UserDeadlines'
import tourFAQ from './FAQ'
import tourLog from './Log'
import tourProjects from './Projects'
import tourCorporateStructure from './CorporateStructure'

// Register Translations here
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function takeTour (requestURL) {
  var tour
  if (requestURL.search('/dashboard/projects/') !== -1) {
    tour = tourProjects()
  } else if (requestURL.search(/\/dashboard\/structure/) !== -1) {
    tour = tourCorporateStructure()
  } else if (requestURL.search('/dashboard/') !== -1) {
    tour = tourDashboard
  } else if (requestURL.search('/account/notification/open/') !== -1) {
    tour = tourNotifications()
  } else if (requestURL.search('/account/address_book/') !== -1) {
    tour = tourAddressBook()
  } else if (requestURL.search('/account/') !== -1) {
    tour = tourAccount
  } else if (requestURL.search('/agenda/') !== -1) {
    tour = tourUserDeadlines
  } else if (requestURL.search(/\/[a-z]{2,3}\/faq\//) !== -1) {
    tour = tourFAQ
  } else if (requestURL.search('/entity/team/*') !== -1) {
    tour = tourEntityTeam
  } else if (requestURL.search(/\/workspace\/[A-Za-z0-9]*\/orders/) !== -1) {
    tour = tourOrderBook
  } else if (requestURL.search(/\/workspace\/[A-Za-z0-9]*\/forms/) !== -1) {
    tour = tourWorkspaceForms()
  } else if (requestURL.search(/\/workspace\/[A-Za-z0-9]*\/export/) !== -1) {
    tour = tourExport()
  } else if (requestURL.search(/\/workspace\/[A-Za-z0-9]*\/deadlines/) !== -1) {
    tour = tourDeadlines
  } else if (requestURL.search(/\/workspace\/[A-Za-z0-9]*\/log/) !== -1) {
    tour = tourLog()
  } else if (requestURL.search(/\/workspace\/[A-Za-z0-9]*\/(members|lenders)/) !== -1) {
    tour = tourWorkspaceMembers()
  } else {
    tour = tourFallback
  }
  return (
    Hopscotch.startTour(tour)
  )
}
