import React from 'react'
import ReactTable from 'react-table'
import { gettext } from 'django'

// Register Translations here
// ----------------------------------------------------------------------
const transLegalEntity = gettext('Legal entity')
const transShareholder = gettext('Shareholders')
const transShareholderParticipation = gettext('Shareholders %')
const transComment = gettext('Comment')
const transEdit = gettext('Edit')
// ----------------------------------------------------------------------

const CorporateStructureTable = ({workspace, structure, readOnly, onView, onEdit}) => {
  let tableColumns = [
    {
      Header: transLegalEntity,
      accessor: 'name',
      Cell: row => readOnly
        ? <a href='#' id={`edit-${row.original.slug}-entity`} onClick={() => onView(row.original.slug)}>
          {row.original.name}
        </a>
        : <a href={`/workspace/${workspace}/forms/${row.original.slug}`}>{row.original.name}</a>
    },
    { Header: transShareholder,
      accessor: 'shareholders',
      filterMethod: (filter, row) => row[filter.id].reduce((res, s) => res +' '+ s.name, '').includes(filter.value),
      Cell: row => <ol>
        {row.original.institutions.map((s, i) => <li key={`i_n_${i}`}>{s.name}</li>)
                                  .concat(row.original.individuals.map((s, i) => <li key={i}>{s.first_name} {s.last_name}</li>))}
      </ol>
    },
    {
      Header: transShareholderParticipation,
      id: 'shareholders_participations',
      accessor: 'shareholders',
      filterMethod: (filter, row) => row[filter.id].reduce((res, s) => res +' '+ s.percentage, '').includes(filter.value),
      Cell: row => <ol>
        {row.original.institutions.concat(row.original.individuals).map((s, i) => <li key={`i_p_${i}`}>{s.percentage}</li>)}
      </ol>
    },
    {
      Header: transComment,
      id: 'shareholders_comment',
      accessor: 'shareholders',
      filterMethod: (filter, row) => row[filter.id].reduce((res, s) => res +' '+ s.comment, '').includes(filter.value),
      Cell: row => <ol>
        {row.original.institutions.concat(row.original.individuals).map((s, i) => <li key={`i_c_${i}`}>{s.comment}</li>)}
      </ol>
    },
    {
      Header: '',
      width: 50,
      filterable: false,
      Cell: row => (<span key={row.original.slug}>
          <a href='#' id={`edit-${row.original.slug}-entity`} onClick={() => onEdit(row.original.slug)}
            title={transEdit} data-placement='left' data-toggle='tooltip'>
            <svg className='icon icon--primary'>
              <use xlinkHref='#icon-edit' />
            </svg>
          </a>
        </span>)
    }
  ]

  return (
    <ReactTable
      defaultSorted={readOnly ? [] : [{id: 'name', desc: false}]}
      minRows={2}
      sortable={!readOnly}
      filterable={!readOnly}
      showPagination={false}
      pageSize={structure ? structure.length : 10}
      data={structure}
      columns={readOnly ? tableColumns.slice(0,-1) : tableColumns}
    />
  )
}

export default CorporateStructureTable
