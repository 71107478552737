import http from './http'
import moment from 'moment'

function loadTemplates (type) {
  return http.fetch(`/api/form/templates/?type=${type}`, 'get')
}

function _loadFormTemplate (formTemplateId) {
  return http.fetch(`/api/form/templates/${formTemplateId}/`, 'get')
}

function _flattenDeep (arr1) {
  return arr1.reduce((acc, val) => Array.isArray(val) ? acc.concat(_flattenDeep(val)) : acc.concat(val), [])
}

function _getOptionalGroups (result) {
  let optionalGroups = _flattenDeep(result.groups
                                          .map(group => Object.keys(group.definition)
                                                              .filter(key => group.definition[key].options)
                                                              .map((key) => group.definition[key].options
                                                                .filter(opt => opt.rel !== null && !group.values.some(v => v[key]===opt.name))
                                                                .map(opt => opt.rel))))

  return {
    noOptionalOrSelected: (formGroupId) =>(optionalGroups.indexOf(formGroupId) < 0)
  }
}

function loadFormValue2 (workspace, form, previewUser) {
  return http.fetch(`/api/workspace/workspaces/${workspace}/form/${form.slug}/groups/?viewas=${previewUser}`, 'get')
    .then(result => {
      let optionalGroups = _getOptionalGroups(result)
      return {...result,
        type_in_workspace: form.type,
        groups: result.groups.filter(group => optionalGroups.noOptionalOrSelected(group.form_group_id))
      }
    })
}

function _parseValues (definition, value) {
  return Object.keys(definition).map(label => {
    let parsedValue
    if (definition[label].type === 'date-field') {
      parsedValue = moment(value[label], moment.defaultFormat).format('YYYY-MM-DD')
    } else if (definition[label].type === 'document-field' && value[label] && value[label].slug) {
      parsedValue = value[label].slug
    } else {
      parsedValue = (typeof value[label] !== 'undefined' && value[label] !== null) ? value[label] : ''
    }
    return { form_field_id: definition[label].id, value: parsedValue }
  })
}

function addNewValue (formId, groupId, definition, value) {
  const method = value.id ? 'put' : 'post'
  const url = value.id ? `/api/form/groups/${value.id}/` : '/api/form/groups/'

  return http.fetch(
    url,
    method,
    {
      form: formId,
      form_group_template_position_id: groupId,
      value: _parseValues(definition, value)
    })
}

function approveChangeset (workspaceSlug, formId, groupId, groupPosition, value) {
  return http.fetch(
    `/api/form/groups/${groupId}/`,
    'put',
    {
      form: formId,
      form_group_template_position_id: groupPosition,
      value: value.filter(f => !!f.value).map(field => ({form_field_id: field.position, value: field.value}))
    })
    .then(() => loadHistory(workspaceSlug, groupId))
    .then(history => ({key: Object.keys(history).reverse()[0], history: history}))
    .then(version => approveVersion(version.history[version.key].id, groupId))
}

function removeValue (formId, groupId, valueId) {
  return http.fetch(`/api/form/groups/${valueId}/`, 'delete',
    {
      form: formId,
      form_group_template_position_id: groupId
    }
  )
}

function loadHistory (workspace, valueId) {
  return http.fetch(`/api/workspace/workspaces/${workspace}/history/${valueId}/`, 'get')
}

function approveVersion (approvedId, valueId) {
  return http.fetch(`/api/form/group-value/${valueId}/`, 'PATCH', {'approved': approvedId})
}

function getChangesets (workspaceSlug, formSlug) {
  return http.fetch(`/api/workspace/workspaces/${workspaceSlug}/form/${formSlug}/changes/`, 'get')
}

export default {
  loadFormValue2,
  addNewValue,
  removeValue,
  loadHistory,
  approveVersion,
  getChangesets,
  approveChangeset,
  loadTemplates
}
