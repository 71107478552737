import PropTypes from 'prop-types'
import { gettext } from 'django'
import React from 'react'
import ReactTable from 'react-table'
import { account } from '../clproxy'

// Register Translations here
// ----------------------------------------------------------------------
const transFirstName = gettext('First name')
const transLastName = gettext('Last name')
const transEmail = gettext('Email')
const transEntity = gettext('Company')
const transDeleteTooltip = gettext('Delete user from your address book')
const transPosition = gettext('Position')
const transCountryCode = gettext('Phone number country code')
const transPhone = gettext('Phone number')
const transWorkspace = gettext('Workspace address book')
const transLoading = gettext('Loading...')
const transDeleteConfirm = gettext('Deleting contact')
const transContactDetail = gettext('Contact details')
const transAlternativePhone = gettext('Secondary phone')
const transAlternativePhoneType = gettext('Secondary phone type')
const transPhoneType = gettext('Phone type')
const transTitle = gettext('Title')
const transHomepage = gettext('Homepage')
const transWorkspaceList = gettext('Projects')
const transDeleteHeader = gettext('Delete')
const transFormOfAddress = gettext('Form of address')
// ----------------------------------------------------------------------

class AddressBook extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      addressBook: [],
      loading: {},
      workspaceHistory: {},
      expandedRows: {}
    }
  }

  componentDidMount () {
    this._loadAddressBook()
    window.$('[data-toggle="tooltip"]').tooltip()
  }

  componentDidUpdate () {
    window.$('[data-toggle="tooltip"]').tooltip()
  }

  _loadAddressBook () {
    return account.getUserAddressBook()
      .then(addressBook => this.setState({
        addressBook: addressBook,
        expandedRows: addressBook.map(v => false)
      }))
  }
  _fetchWorkspaces (index, email) {
    this.setState({loading: {...this.state.loading, [index]: true}})
    account.getUserPermissionHistory(email).then(workspaces => {
      this.setState({
        workspaceHistory: {...this.state.workspaceHistory, [index]: workspaces},
        loading: {...this.state.loading, [index]: false}})
    })
  }

  _onDelete (id) {
    account.deleteUserFromPersonalAddressBook(id).then(this._loadAddressBook.bind(this))
  }

  _trackExpandedRows (index) {
    this.setState({expandedRows: {
      ...this.state.expandedRows,
      [index]: !this.state.expandedRows[index]
    }})
  }

  render () {
    var tableColumns = [
      {Header: transEntity, accessor: 'company'},
      {Header: transFirstName, accessor: 'first_name'},
      {Header: transLastName, accessor: 'last_name'},
      {Header: transPosition, accessor: 'position'},
      {Header: transEmail, accessor: 'email'},
      {Header: transPhoneType, Cell: row => row.original.phone_number && row.original.phone_type},
      {Header: transCountryCode, Cell: row => row.original.country_code && `+${row.original.country_code}`},
      {Header: transPhone, accessor: 'national_phone_number'},
      {
        Header: transDeleteHeader,
        Cell: row => {
          return <a href='#' onClick={() => { if (window.confirm(transDeleteConfirm)) this._onDelete(row.original.slug) }}
            title={transDeleteTooltip} data-toggle='tooltip'>
            <svg className='icon icon--delete'>
              <use xlinkHref='#icon-delete' />
            </svg>
          </a>
        }
      }
    ]

    var subTableColumns = [
      {Header: transEntity, accessor: 'company'},
      {Header: transWorkspace, accessor: 'workspace'}
    ]
    return (
      <ReactTable
        minRows={2}
        sortable
        showPagination={false}
        pageSize={this.state.addressBook ? this.state.addressBook.length : 10}
        data={this.state.addressBook}
        columns={tableColumns}
        expanded={this.state.expandedRows}
        onExpandedChange={(newExpanded, index, event) => this._trackExpandedRows(index)}
        SubComponent={row => {
          return (
            <div className='row'>
              <div className='col-md-4 container-has-sidebar'>
                <h1 className='h2 | align-center | mt-medium mb-medium'>{transContactDetail}</h1>
                <div className='row approve-row'>
                  <div className=''>
                    <h5>{transAlternativePhoneType}</h5>
                    <p>{row.original.alternative_phone_type
                          ? row.original.alternative_phone_type
                          : '-------'
                    }</p>
                  </div>
                </div>
                <div className='row approve-row'>
                  <div className=''>
                    <h5>{transAlternativePhone}</h5>
                    <p>{row.original.alternative_country_code
                          ? '+' + row.original.alternative_country_code + ' ' + row.original.alternative_national_phone_number
                          : '-------'
                    }</p>
                  </div>
                </div>
                <div className='row approve-row'>
                  <div className=''>
                    <h5>{transTitle}</h5>
                    <p>{row.original.title || '-------'}</p>
                  </div>
                </div>
                <div className='row approve-row'>
                  <div className=''>
                    <h5>{transFormOfAddress}</h5>
                    <p>{JSON.parse(this.props.form_of_address)[row.original.form_of_address] || '-------'}</p>
                  </div>
                </div>
                <div className='row approve-row'>
                  <div className=''>
                    <h5>{transHomepage}</h5>
                    <p>{row.original.company_homepage || '-------'}</p>
                  </div>
                </div>
              </div>
              <div className='col-md-5 container-has-sidebar'>
                <h1 className='h2 | align-center | mt-medium mb-medium'>{transWorkspaceList}</h1>
                <ReactTable
                  manual
                  minRows={2}
                  sortable
                  showPagination={false}
                  loading={this.state.loading[row.index]}
                  loadingText={transLoading}
                  onFetchData={() => this._fetchWorkspaces(row.index, row.original.slug)}
                  pageSize={this.state.workspaceHistory[row.index] ? this.state.workspaceHistory[row.index].length : 10}
                  data={this.state.workspaceHistory[row.index]}
                  columns={subTableColumns} />
              </div>
            </div>
          )
        }}
      />
    )
  }
}

AddressBook.propTypes = {
}

export default AddressBook
