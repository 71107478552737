import PropTypes from 'prop-types'
import { gettext } from 'django'
import moment from 'moment'
import { form } from '../clproxy'
import ReactTable from 'react-table'
import StringEx from '../string_ex'

var React = require('react')

// Register Translations here
// ----------------------------------------------------------------------
const transIsApproved = gettext('is Approved')
const transUser = gettext('User')
const transTimestamp = gettext('Timestamp')
const transApproveBtn = gettext('To be Approved')
const transAlreadyApproveBtn = gettext('Approved')
const transCloseBtn = gettext('Close')
const transApproveTitle = gettext('Approve')

// ----------------------------------------------------------------------

class FormApprove extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      data: null,
      key: `history_table_${props.valueId}`
    }
  }

  componentDidMount () {
    this.loadData(this.props.approvedValue)
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    this.loadData(nextProps.approvedValue)
  }

  _getDateValues (date, data) {
    return Object.keys(this.props.groupDefinition)
      .map((label) => ({
        label: label,
        value: data[date].values.find(v => v.form_field_template_position_id === this.props.groupDefinition[label].id)
      }))
  }

  loadData (approvedVersion) {
    form.loadHistory(this.props.workspace, this.props.valueId).then((json) => {
      let data = Object.keys(json).map(date => this._getDateValues(date, json).reduce((res, property) => {
        res[StringEx(property.label).clean().toString()] = property.value ? property.value.value : ''
        return res
      }, {
        timestamp: date,
        user: json[date].user,
        isApproved: approvedVersion === json[date].id ? 'YES' : 'NO',
        id: json[date].id
      }))
      this.setState({data: data})
    }).catch(function (ex) {
      console.log('parsing failed', ex)
    })
  }

  onRowClick (id) {
    form.approveVersion(id, this.props.valueId)
      .then(() => {
        this.props.onRestored(id)
      }).catch(function (ex) {
        console.log('parsing failed', ex)
      })
  }

  _formatField (value) {
    if (value && value.match && value.match(/^\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/)) {
      return moment(value, 'YYYY-MM-DD').format()
    }

    if (value && value.name && value.document) {
      return <a href={value.document} target='_blank'>{value.name}</a>
    }

    return value
  }

  render () {
    if (this.state.data) {
      let columns = [
        {
          Header: '',
          accessor: 'icon',
          Filter: () => null,
          Cell: ({original, row, index}) => row.isApproved === 'NO'
            ? <a href='#' id={'approve-link-' + index} onClick={this.onRowClick.bind(this, original.id)}>{transApproveBtn}</a>
            : <a href='#' id={'approved-link-' + index} className={'link--disabled'}>{transAlreadyApproveBtn}</a>
        },
        {
          Header: transTimestamp,
          accessor: 'timestamp',
          Cell: ({row, index}) => <span id={'timestamp-' + index}>
            {moment(row.timestamp, 'YYYY-MM-DD HH:mm:ss.SSSSSSZ').format(moment.defaultFormat + ', HH:mm')}
          </span>
        },
        {
          Header: transUser,
          accessor: 'user'
        },
        {
          Header: transIsApproved,
          accessor: 'isApproved',
          Cell: ({value, index}) => <span className={value === 'YES' ? 'text-true' : ''} id={`status-approved-${index}`}>
            {value}
          </span>
        }
      ].concat(Object.keys(this.props.groupDefinition).map(
        (template) => ({
          Header: template,
          accessor: StringEx(template).clean().toString(),
          Cell: ({value, index}) => <span id={`${template}-value-${index}`}>
            {this._formatField(value)}
          </span>
        })
      ))

      return [
        <div key='modal__content' className='modal__content'>
          <ReactTable
            key='approve_table'
            minRows='1'
            filterable={false}
            showPagination={false}
            data={this.state.data}
            pageSize={this.state.data ? this.state.data.length : 10}
            columns={columns}
          />
        </div>,
        <div key='modal_footer' className='modal__footer'>
          <div className='right'>
            <button
              key='close_button'
              onClick={this.props.onFinish}
              className='btn'
              disabled={this.state.saving}>{transCloseBtn}</button>
          </div>
        </div>
      ]
    } else { return <h1>Loading...</h1> }
  }
}

FormApprove.title = transApproveTitle

FormApprove.propTypes = {
  approvedValue: PropTypes.number.isRequired,
  valueId: PropTypes.number.isRequired,
  groupDefinition: PropTypes.object.isRequired,
  workspace: PropTypes.string.isRequired,
  onFinish: PropTypes.func.isRequired,
  onRestored: PropTypes.func.isRequired
}

export default FormApprove
