import PropTypes from 'prop-types'
import React from 'react'
import { gettext } from 'django'
import Alert from '../alert'
import FileUpload from '../file_upload'
import ActionButton from '../action_button'

// Register Translations here
// ----------------------------------------------------------------------
const transCreateBtn = gettext('Add an Order')
const transSave = gettext('Save')
const transShowStats = gettext('Show Statistics')
const transDateCreated = gettext('Entered')
const transDateUpdated = gettext('Updated')
const transCreatedBy = gettext('Created By')
const transPrivacy = gettext('Privacy Settings')
const transLender = gettext('Lender')
const transStatus = gettext('Status')
const transNotional = gettext('Notional')
const transProbability = gettext('Probability')
const transNotionalProbability = gettext('Notional x Probability')
const transOrderLevelType = gettext('Order Level Type')
const transCurrency = gettext('Currency')
const transSupply = gettext('Supply')
const transOrderLevelValue = gettext('Order Level')
const transComments = gettext('Comments')
const transNextSteps = gettext('Next Steps')
const transAssignedTo = gettext('Assigned To')
const transUploadField = gettext('Upload')
// ----------------------------------------------------------------------

export default class OrderBookSettingsForm extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      errors: null,
      orderBookSettings: props.orderBookSettings,
    }
  }

  handleInputChange (event) {
    if (event.target.name === 'is_private') {
      this.setState({
        orderBookSettings: {
          ...this.state.orderBookSettings,
          [event.target.name]: !this.state.orderBookSettings.is_private
        }
      })
    } else {
      this.setState({
        orderBookSettings: {...this.state.orderBookSettings, [event.target.name]: event.target.value}
      })
    }
  }

  onSave () {
    return this.props.onSave(this.state.orderBookSettings)
  }

  render () {
    let inputSupply = <div className='input'>
      <label htmlFor='supply'>{transSupply} in {this.props.currency}</label>
      <input
        id={'supply'}
        type={'number'}
        value={this.state.orderBookSettings.supply}
        name={'supply'}
        onChange={this.handleInputChange.bind(this)}
      />
      <div className='input__description'>
        {transSupply}
      </div>
    </div>

    let dropDownOrderLevelType = this.props.types.length > 0 ? (<div className='input'>
      <label htmlFor=''>{transOrderLevelType}</label>
      <select
        name='order_level_type'
        value={this.state.orderBookSettings.order_level_type}
        onChange={this.handleInputChange.bind(this)}
      >
        {this.props.types.map(type => (<option key={type[0]} value={type[0]}>{type[1]}</option>))}
      </select>
      <div className='input__description'>
        {transOrderLevelType}
      </div>
    </div>) : null

    let dropDownCurrencies = this.props.currencies.length > 0 ? (<div className='input'>
      <label htmlFor=''>{transCurrency}</label>
      <select
        name='currency'
        value={this.state.orderBookSettings.currency}
        onChange={this.handleInputChange.bind(this)}
      >
        {this.props.currencies.map(currency => (<option key={currency[0]} value={currency[0]}>{currency[1]}</option>))}
      </select>
      <div className='input__description'>
        {transCurrency}
      </div>
    </div>) : null

    let dropDownPrivacy = this.props.privacies.length > 0 ? (<div className='input'>
      <label htmlFor=''>{transPrivacy}</label>
      <select
        name='privacy'
        value={this.state.orderBookSettings.privacy}
        onChange={this.handleInputChange.bind(this)}
      >
        {this.props.privacies.map(privacy => (<option key={privacy[0]} value={privacy[0]}>{privacy[1]}</option>))}
      </select>
      <div className='input__description'>
        {transCurrency}
      </div>
    </div>) : null

    let checkboxPrivate = (<div className='input'>
      <input
        name='is_private'
        type='checkbox'
        checked={this.state.orderBookSettings.is_private}
        onChange={this.handleInputChange.bind(this)}
      />
      <label htmlFor='id_terms_and_conditions'> Private Orderbook</label>
      <div className='input__description'>
        Make OrderBook Private.
      </div>
    </div>)

    return (
      <form action='#' className='mt-micro'>
        <Alert message={this.props.errors} category={'error'} />
        {inputSupply}
        {dropDownOrderLevelType}
        {dropDownCurrencies}
        {dropDownPrivacy}
        <div className='input'>
          <ActionButton className='btn' type='button' onClick={this.onSave.bind(this)}>{transSave}</ActionButton>
        </div>
      </form>
    )
  }
}

OrderBookSettingsForm.propTypes = {
  lenders: PropTypes.array.isRequired,
  members: PropTypes.array.isRequired,
  statuses: PropTypes.array.isRequired,
  types: PropTypes.array.isRequired,
  currencies: PropTypes.array.isRequired,
  orderBookSettings: PropTypes.object.isRequired
}
