import { gettext } from 'django'
import React from 'react'
import PropTypes from 'prop-types'
import CorporateStructureTable from './CorporateStructureTable'
import { entity } from '../clproxy'
import EntityForm from './EntityForm'
import Modal from '../modal'

// Register Translations here
// ----------------------------------------------------------------------
const transTitle = gettext('CWS Dashboard')
const transNewLegalEntityDesc = gettext('Add legal entity tooltip')
const transNewLegalEntity = gettext('Add new legal entity')
const transGoToCWDesc = gettext('Go to corporate wks desc')
const transGoToCW = gettext('Go to corporate wks')
const transNothingAddedYet = gettext('Nothing added yet')
const transAddEntityTitle = gettext('Add new entity')
const transUpdateEntityTitle = gettext('Update entity')
const transCorporateStructureTitle = gettext('Corporate structure')
// ----------------------------------------------------------------------

class CompanyStructure extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      structure: [],
      shareholders: [],
      showEntityForm: false
    }
  }

  componentDidMount () {
    entity.loadStructure().then(structure => this.setState({structure}))
  }

  componentDidUpdate () {
  }

  _structureUpdated (structure) {
    this.setState({structure, showEntityForm: false, selectedEntity: null})
  }

  _editEntity (slug) {
    let entity = this.state.structure.find(e => e.slug === slug)
    this.setState({showEntityForm: true, selectedEntity: entity})
  }

  render () {
    return (
      <div className='col-md-12'>
        <Modal id='entity_form'
          show={this.state.showEntityForm}
          onClose={() => this.setState({showEntityForm: false, selectedEntity: null})}>
          <EntityForm title={this.state.selectedEntity ? transUpdateEntityTitle : transAddEntityTitle}
            entity={this.state.selectedEntity} onEntitySaved={this._structureUpdated.bind(this)}
            onEntityDeleted={this._structureUpdated.bind(this)} mdmType={this.props.mdm_type}/>
        </Modal>
        <div className='page-title row'>
          <div className='col-md-6'>
            <h2>{transTitle}</h2>
          </div>
          <div className='col-md-6 align-right | mt-micro mt-medium'>
            <a href={`/workspace/${this.props.default_corporate_workspace}/forms/`} id='go-to-cw'>
              <button className='btn' data-placement='left' title={transGoToCWDesc} data-toggle='tooltip'>
                {transGoToCW}
              </button>
            </a> &nbsp;
            <a href='#' id='add-new-entity' onClick={() => this.setState({showEntityForm: true})}>
              <button className='btn' data-placement='left' title={transNewLegalEntityDesc} data-toggle='tooltip'>
                {transNewLegalEntity}
              </button>
            </a>
          </div>
        </div>
        <div className='row mt-medium'>
          <h4 className='col-md-12'>{transCorporateStructureTitle}</h4>
        </div>
        <div className='row mt-medium'>
          <div className='col-md-12'>
            {this.state.structure.length
            ? <div className='table'>
              <CorporateStructureTable workspace={this.props.default_corporate_workspace} structure={this.state.structure}
                onEdit={this._editEntity.bind(this)}/>
            </div>
            : <div>
              <h2>{transNothingAddedYet}</h2>
            </div>}
          </div>
        </div>
      </div>
    )
  }
}

CompanyStructure.propTypes = {

}

export default CompanyStructure
