/* eslint-disable no-new-wrappers */

function StringEx (str) {
  let strObj = new String(str)
  strObj.clean = function () {
    return StringEx(str.replace(/[^\w]|_/g, '').replace(/\s+/g, ''))
  }

  return strObj
}

export default StringEx
