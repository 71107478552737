import { gettext } from 'django'
import Hopscotch from 'hopscotch/dist/js/hopscotch'

// Define the tour!

var tourFAQ = {
  id: "faq",
  i18n: {
    nextBtn: gettext('Next Step'),
    prevBtn: gettext('Back Step'),
    doneBtn: gettext('Done Step'),
    skipBtn: gettext('Skip Step'),
    closeTooltip: gettext('Close it')
  },
  steps: [
    {
      title: gettext('TG FAQ step 1 title'),
      content: gettext('TG FAQ step 1 content'),
      target: document.getElementsByClassName('toc__content')[0],
      placement: "left"
    },
    {
      title: gettext('TG FAQ step 2 title'),
      content: gettext('TG FAQ step 2 content'),
      target: document.getElementsByClassName('accordion__title')[0],
      placement: "bottom"
    },
    {
      title: gettext('TG FAQ step 3 title'),
      content: gettext('TG FAQ step 3 content'),
      target: document.getElementsByClassName('tag')[0],
      placement: "left"
    },
    {
      title: gettext('TG FAQ step 4 title'),
      content: gettext('TG FAQ step 4 content'),
      target: document.getElementsByClassName('tabs-icon')[0],
      placement: "left"
    }],
  showPrevButton: true
}

export default tourFAQ
