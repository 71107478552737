import React from 'react'

class ActionButton extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      waiting: false
    }
  }

  _onClick (e) {
    if (this.props.onClick) {
      this.setState({waiting: true})

      let res = this.props.onClick(e)

      if (res && res.then) {
        res.then(() => this.setState({waiting: false}))
      } else {
        this.setState({waiting: false})
      }
    }
  }

  render () {
    return <button id={this.props.id} type={this.props.type}
      className={this.props.className} disabled={this.props.disabled || this.state.waiting}
      onClick={this._onClick.bind(this)}>{this.props.children}</button>
  }
}

export default ActionButton
