import { gettext } from 'django'

var React = require('react')
// Register Translations here
// ----------------------------------------------------------------------
// ----------------------------------------------------------------------
export default function MemberWorkspaces ({data, statuses, deletePermission}) {
  if (!data) { return null }

  return data.map(wk => {
    return (
      <tr key={wk.wk_slug} aria-label='workspace'>
        <td>{wk.authorized ? (<a href={`/workspace/${wk.wk_slug}/forms/`}>{wk.name}</a>) : (wk.name)}</td>
        <td>{wk.access_profile}</td>
        <td>{statuses[wk.status] || wk.status}</td>
        <td className='table__icon'>
          {wk.authorized && !wk.current_user ? <a href='#' onClick={() => deletePermission(wk.pm_slug)}>
            <svg className='icon icon--delete'>
              <use xlinkHref='#icon-delete' />
            </svg>
          </a> : null}
        </td>
      </tr>
    )
  })
}
