import PropTypes from 'prop-types'
import { gettext } from 'django'
import React from 'react'
import cookie from 'cookie'
import OrderBookEntry from './OrderBookEntry'
import OrderView from './OrderView'
import OrderBookSettingsForm from './OrderBookSettingsForm'
import StatisticView from './StatisticView'
import Alert from '../alert'
import Modal from '../modal'
import ActionButton from '../action_button'

// Register Translations here
// ----------------------------------------------------------------------
const transOrderBookTitle = gettext('Order Book')
const transCreateBtn = gettext('Add an Order')
const transOrder = gettext('Order')
const transErrorDeleteOrderBookEntry = gettext('Order Book Entry could not be deleted.')
const transShowStats = gettext('Show Statistics')
const transSettings = gettext('Order Book Settings')
const transStatistic = gettext('Statistics')
const transLender = gettext('Lender')
const transStatus = gettext('Status')
const transNotional = gettext('Notional')
const transProbabilityShort = gettext('Prob.')
const transNotionalProbability = gettext('Prob.-weighted Notional')
const transOrderLevelValue = gettext('Order Level')
const transComments = gettext('Comments')
const transNextSteps = gettext('Next Steps')
const transAssignedTo = gettext('Assigned To')
const transUploadField = gettext('Upload')
const transSummary = gettext('Summary')
const transUpdateSettingsSuccess = gettext('Order Book Settings successfully saved')
const transUpdateCreateEntrySuccess = gettext('Order Book Entry successfully saved')
const transDeleteEntrySuccess = gettext('Order Book Entry successfully deleted')
const transNoLendersWarning = gettext('You must first invite a Lender in order to create Order Book Entries.')

const transCurrency = gettext('Currency')
const transSupply = gettext('Supply')
const transPrivacy = gettext('Privacy')
const transOrderLevelType = gettext('Order Level Type')

const transDeleteBtn = gettext('Delete')
const transUndoneMsg = gettext('This step cannot be undone.')
const transDeleteMsg = gettext('Do you really want to delete this data entry?')

// ----------------------------------------------------------------------

class OrderBook extends React.Component {
  constructor (props) {
    super(props)

    let typeFormatter = {}
    typeFormatter['price'] = '%'
    typeFormatter['spread'] = 'bps'
    typeFormatter['interest'] = '%'

    this.state = {
      orders: [],
      orderBookSettings: null,
      alertMessage: null,
      deleteMessage: null,
      showCreateForm: false,
      showSettingsForm: false,
      showStatistic: false,
      selected: null,
      lenders: (props.lenderteams && JSON.parse(props.lenderteams)) || [],
      members: (props.members && JSON.parse(props.members)) || [],
      statuses: (props.statuses && JSON.parse(props.statuses)) || [],
      types: (props.types && JSON.parse(props.types)) || [],
      privacies: (props.privacies && JSON.parse(props.privacies)) || [],
      currencies: (props.currencies && JSON.parse(props.currencies)) || [],
      errors: null,
      editMode: null,
      typeFormatter: typeFormatter,
      formattedType: '-'
    }
  }

  getFormattedType () {
    let key = this.state.orderBookSettings && this.state.orderBookSettings.order_level_type
    return this.state.typeFormatter[key]
  }

  getCurrency () {
    let currency = this.state.orderBookSettings && this.state.currencies.find((entry) => entry[0] === this.state.orderBookSettings.currency)[1]
    return currency
  }

  componentDidMount () {
    this.loadOrderBook()
    this.loadOrderBookSettings()
    window.$('[data-toggle="tooltip"]').tooltip()
  }

  componentDidUpdate () {
    window.$('[data-toggle="tooltip"]').tooltip()
  }

  removeEmpty (obj) {
    Object.keys(obj).forEach((key) => (obj[key] == null) && delete obj[key])
  }

  saveOrderBookSettings (orderBookSettings) {
    return window.fetch(`/api/workspace/workspaces/${this.props.workspace}/order-book/${orderBookSettings.slug}/`, {
      method: 'put',
      contentType: 'json',
      credentials: 'include',
      headers: new window.Headers({
        'X-CSRFToken': cookie.parse(document.cookie).csrftoken,
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify(orderBookSettings)
    }).then(response => {
      if (response.status >= 200 && response.status < 300) {
        return response.json()
      } else if (response.status === 401) {
        window.location.href = '/auth/login/'
      } else {
        var error = new Error(response.statusText)
        error.response = response
        throw error
      }
    }).then(response => {
      this.setState({errors: null, showSettingsForm: false, alertMessage: transUpdateSettingsSuccess})
      this.loadOrderBook()
      this.loadOrderBookSettings()
    }).catch(ex => {
      ex.response.json().then(message => {
        this.setState({errors: message})
      })
    })
  }

  saveOrderBookEntry (order) {
    this.removeEmpty(order)

    if (order.upload_field) {
      order.upload_field_slug = order.upload_field.slug
      delete order.upload_field
    } else {
      order.upload_field_slug = undefined
      delete order.upload_field
    }

    return window.fetch(order.slug
      ? `/api/workspace/workspaces/${this.props.workspace}/orders/${order.slug}/`
      : `/api/workspace/workspaces/${this.props.workspace}/orders/`, {
      method: order.slug ? 'put' : 'post',
      contentType: 'json',
      credentials: 'include',
      headers: new window.Headers({
        'X-CSRFToken': cookie.parse(document.cookie).csrftoken,
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify(order)
    }).then(response => {
      if (response.status >= 200 && response.status < 300) {
        return response.json()
      } else if (response.status === 401) {
        window.location.href = '/auth/login/'
      } else {
        var error = new Error(response.statusText)
        error.response = response
        throw error
      }
    }).then(response => {
      this.setState({errors: null, showCreateForm: false, alertMessage: transUpdateCreateEntrySuccess})
      this.loadOrderBook()
      this.loadOrderBookSettings()
    }).catch(ex => {
      ex.response.json().then(message => {
        this.setState({errors: message})
      })
    })
  }

  _deleteOrderBookEntry (order) {
    let deleteFunct = () => { this.deleteOrderBookEntry(order) }
    this.setState({show_delete: true, delete_funct: deleteFunct})
  }

  deleteOrderBookEntry (order) {
    return window.fetch(`/api/workspace/workspaces/${this.props.workspace}/orders/${order.slug}/`, {
      method: 'delete',
      contentType: 'json',
      credentials: 'include',
      headers: new window.Headers({
        'X-CSRFToken': cookie.parse(document.cookie).csrftoken,
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      })
    }).then(response => {
      if (response.status === 204) {
        this.loadOrderBook()
        this.setState({show_delete: false, alertMessage: transDeleteEntrySuccess})
      } else {
        this.setState({
          deleteMessage: transErrorDeleteOrderBookEntry
        })
      }
    })
  }

  onEdit (order) {
    this.setState({
      showCreateForm: true,
      editMode: true,
      selected: order
    })
  }

  onView (order) {
    this.setState({
      showCreateForm: true,
      editMode: false,
      selected: order
    })
  }

  loadOrderBook () {
    window.fetch(`/api/workspace/workspaces/${this.props.workspace}/orders/`, {
      method: 'get',
      contentType: 'json',
      credentials: 'include',
      headers: new window.Headers({
        'X-CSRFToken': cookie.parse(document.cookie).csrftoken,
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      })
    }).then(response => {
      if (response.status >= 200 && response.status < 300) {
        return response.json()
      } else if (response.status === 401) {
        window.location.href = '/auth/login/'
      } else {
        var error = new Error(response.statusText)
        error.response = response
        throw error
      }
    }).then(data => {
      this.setState({
        orders: data
      })
    }).catch(ex => {
    })
  }

  loadOrderBookSettings () {
    if (this.props.relationship === 'core') {

      window.fetch(`/api/workspace/workspaces/${this.props.workspace}/order-book/`, {
        method: 'get',
        contentType: 'json',
        credentials: 'include',
        headers: new window.Headers({
          'X-CSRFToken': cookie.parse(document.cookie).csrftoken,
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        })
      }).then(response => {
        if (response.status >= 200 && response.status < 300) {
          return response.json()
        } else if (response.status === 401) {
          window.location.href = '/auth/login/'
        } else {
          var error = new Error(response.statusText)
          error.response = response
          throw error
        }
      }).then(data => {
        this.setState({
          orderBookSettings: data[0]
        })
      }).catch(ex => {
      })

    }
  }

  render () {
    return (<div>
      {this.props.relationship === 'core' && this.state.showStatistic
        ? <Modal show={this.state.showStatistic} onClose={() => this.setState({showStatistic: false, errors: null})}>
          <StatisticView
            title={transStatistic}
            orderBookSettings={this.state.orderBookSettings}
            statuses={this.state.statuses}
            currency={this.getCurrency()}
            formattedType={this.getFormattedType()}
          />
        </Modal>
        : null}

      {this.state.showCreateForm
        ? <Modal show={this.state.showCreateForm} onClose={() => this.setState({showCreateForm: false, errors: null})}>
          <OrderView
            title={transOrder}
            errors={this.state.errors}
            lenders={this.state.lenders}
            members={this.state.members}
            statuses={this.state.statuses}
            types={this.state.types}
            editMode={this.state.editMode}
            currentUser={this.props.currentuser}
            workspace={this.props.workspace}
            currencies={this.state.currencies}
            typeFormatter={this.state.typeFormatter}
            currency={this.getCurrency()}
            onSave={this.saveOrderBookEntry.bind(this)}
            selected={this.state.selected}
          />
        </Modal> : null}

      {this.props.relationship === 'core' && this.state.showSettingsForm
        ? <Modal show={this.state.showSettingsForm}
                 onClose={() => this.setState({showSettingsForm: false, errors: null})}>
          <OrderBookSettingsForm
            title={transSettings}
            errors={this.state.errors}
            lenders={this.state.lenders}
            members={this.state.members}
            statuses={this.state.statuses}
            types={this.state.types}
            privacies={this.state.privacies}
            currency={this.getCurrency()}
            currencies={this.state.currencies}
            workspace={this.props.workspace}
            orderBookSettings={this.state.orderBookSettings}
            onSave={this.saveOrderBookSettings.bind(this)}
          />
        </Modal> : null}

      <Modal show={this.state.show_delete} onClose={() => this.setState({show_delete: false})}>
        <div title={transDeleteMsg}>
          <p>{transUndoneMsg}</p>
          <div className='input | align-right | mt-small'>
            <ActionButton
              onClick={this.state.delete_funct}
              className='btn btn--red'>{transDeleteBtn}
            </ActionButton>
          </div>
        </div>
      </Modal>
      <div className='page-title row mb-medium'>
        <div className='col-md-6'>
          <h2>{transOrderBookTitle}</h2>
        </div>
        {this.props.relationship === 'core' ? (
          <div className='align-right col-md-6 | mt-medium mb-small'>
            <button
              id={'statisticButton'}
              className='btn btn--green | mr-small'
              onClick={() => this.setState({'showStatistic': true})}>
              {transShowStats}
            </button>
            {this.state.lenders.length > 0 ? (
              <button
                id={'addAnOrder'}
                className='btn'
                onClick={() => this.setState({'showCreateForm': true, 'editMode': true, 'selected': null})}>
                {transCreateBtn}
              </button>
            ) : null}
          </div>
        ) : <div className='col-md-6' />}
      </div>

      {(this.props.relationship === 'core') ? (
        <div className='row'>
          <div className='col-md-12 option-menu'>
            {this.props.relationship === 'core' ? (
              <ul>
                <li id={'orderBookSettings'}>
                  <b>{transSettings}: </b>
                  {this.props.canEditSettings === 'True' ? (
                    <a href='#' onClick={() => this.setState({showSettingsForm: true})}>
                      <svg className='icon icon--primary '>
                        <use xlinkHref='#icon-edit' />
                      </svg>
                    </a>
                  ) : null}
                </li>
                <li>{transCurrency}: {(this.state.orderBookSettings && this.state.currencies.find((currency) => currency[0] === this.state.orderBookSettings.currency)[1])}.</li>
                <li>{transSupply}: {this.state.orderBookSettings && this.state.orderBookSettings.supply && parseFloat(this.state.orderBookSettings.supply).toLocaleString('de-DE')}{this.getCurrency()}.</li>
                <li>{transPrivacy}: {(this.state.orderBookSettings && this.state.privacies.find((privacy) => privacy[0] === this.state.orderBookSettings.privacy)[1])}</li>
                <li>{transOrderLevelType}: {(this.state.orderBookSettings && this.state.types.find((type) => type[0] === this.state.orderBookSettings.order_level_type)[1])}.</li>
              </ul>
            ) : <div className='mt-medium mb-medium row' />}
          </div>
        </div>
      ) : null}
      <div className='row'>
        <Alert
          key='alert'
          message={this.state.alertMessage}
          onClick={() => this.setState({'alertMessage': null})}
          category={'success'} />
      </div>
      <div className='row'>
        {(this.state.lenders.length === 0) ? <div className={`alert alert--warning mb-medium`}>
          {transNoLendersWarning}
        </div> : null}
      </div>
      <div className='row mt-medium'>
        <div key={'table'} className='table table--scroll col-md-12'>
          <table>
            <thead>
              <tr>
                <th className='table__icon' />
                <th id={'lenderHeader'}>{transLender}</th>
                <th id={'statusHeader'}>{transStatus}</th>
                <th id={'notionalHeader'}>{transNotional}</th>
                <th>{transProbabilityShort}</th>
                <th id={'propWeightedNotionalHeader'}>{transNotionalProbability}</th>
                <th id={'orderLevelHeader'}>{transOrderLevelValue}</th>
                <th id={'commentsHeader'}>{transComments}</th>
                <th id={'nextStepsHeader'}>{transNextSteps}</th>
                <th id={'assignedToHeader'}>{transAssignedTo}</th>
                <th id={'uploadHeader'}>{transUploadField}</th>
                <th className='table__icon' />
              </tr>
              <tr>
                <th className='table__icon' id={'orderTableSummary'}><b>{transSummary}</b></th>
                <th>
                  <b>{`# ${this.state.orders.map(order => order.lender).filter((val, i, arr) => i === arr.lastIndexOf(val)).length}`}</b>
                </th>
                <th>{}</th>
                <th align='right'><b>{this.state.orders
                  .map((order) => order.notional)
                  .filter((notional) => notional !== null)
                  .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
                  .toLocaleString('de-DE')}
                  {(this.state.orderBookSettings && this.state.currencies.find((entry) => entry[0] === this.state.orderBookSettings.currency)[1])}
                </b>
                </th>
                <th>{}</th>
                <th align='right'><b>{this.state.orders
                  .map((order) => order.prop_weighted_notional)
                  .filter((propWeightedNotional) => propWeightedNotional !== null)
                  .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
                  .toLocaleString('de-DE')}
                  {(this.state.orderBookSettings && this.state.currencies.find((entry) => entry[0] === this.state.orderBookSettings.currency)[1])}
                </b>
                </th>
                <th align='right'>
                  <b>{(this.state.orderBookSettings && this.state.types.find((entry) => entry[0] === this.state.orderBookSettings.order_level_type)[1].split(' ')[0])}</b>
                </th>
                <th>{}</th>
                <th>{}</th>
                <th>{}</th>
                <th>{}</th>
                <th className='table__icon' />
              </tr>
            </thead>
            <tbody>
              {this.state.orders.map((order) =>
                <OrderBookEntry
                  key={order.slug}
                  lenders={this.state.lenders}
                  statuses={this.state.statuses}
                  types={this.state.types}
                  order={order}
                  formattedType={this.getFormattedType()}
                  relationship={this.props.relationship}
                  currencies={this.state.currencies}
                  onEdit={(order) => this.onEdit(order)}
                  onView={(order) => this.onView(order)}
                  onDelete={(order) => this._deleteOrderBookEntry(order)}
                />)
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>)
  }
}

OrderBook.propTypes = {
  workspace: PropTypes.string.isRequired,
  currentuser: PropTypes.string.isRequired,
  orderBook: PropTypes.string.isRequired,
  canEditSettings: PropTypes.string.isRequired,
  lenderteams: PropTypes.string.isRequired,
  members: PropTypes.string.isRequired,
  currencies: PropTypes.string.isRequired,
  statuses: PropTypes.string.isRequired,
  privacies: PropTypes.string.isRequired,
  relationship: PropTypes.string.isRequired,
  types: PropTypes.string.isRequired
}

export default OrderBook
