import React from 'react'
import ReactTable from 'react-table'
import { gettext } from 'django'

// Register Translations here
// ----------------------------------------------------------------------
const transNameEntityHeader = gettext('Entity\'s name header')
const transPercentageHeader = gettext('% header')
const transCommentHeader = gettext('Comment header')
const transEditBtn = gettext('Edit')
const transViewBtn = gettext('View')
// ----------------------------------------------------------------------

const Table = ({entity, allowEdit, onEdit}) => {
  const columns = [
    {
      Header: '',
      width: 80,
      accessor: 'icon',
      className: 'sticky',
      headerClassName: 'sticky',
      Filter: () => null,
      Cell: (props) => <span className='accordion__tooltip'>
        <a id={'update-' + props.original.slug} href='#'
          onClick={onEdit}>
          {allowEdit ? transEditBtn : transViewBtn}
        </a>
      </span>
    },
    {
      Header: transNameEntityHeader,
      accessor: 'name'
    },
    {
      Header: transPercentageHeader,
      accessor: 'percentage'
    },
    {
      Header: transCommentHeader,
      accessor: 'comment'
    }
  ]

  let data = entity.institutions.concat(entity.individuals.map(i => ({...i, name: `${i.first_name} ${i.last_name}`})))
  return (
    <ReactTable
      minRows='1'
      pageSize={data.length || 10}
      showPagination={false}
      filterable={false}
      data={data}
      columns={columns}
    />
  )
}

export default Table
