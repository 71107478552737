import PropTypes from 'prop-types'
import React from 'react'
import { gettext } from 'django'
import Alert from '../alert'
import FileUpload from '../file_upload'

// Register Translations here
// -----------------------------y----------------------------------------
const transAnalysis = gettext('Stage Analysis')
const transFunnel = gettext('Funnel Analysis')
const transSensitivity = gettext('Sensitivity Analysis')

const transOrder = gettext('Orders')
const transStatus = gettext('Status/Stage')
const transNotional = gettext('Notional')
const transNotionalProbability = gettext('Prob.-weighted Notional')

const transLevel = gettext('Level')
const transSupply = gettext('Supply')
const transDemand = gettext('Demand')
const transCover= gettext('Cover at Price')
const transProbDemand = gettext('Demand(prob-weighted)')
const transProbCover = gettext('Cover at Price(prob-weighted)')
// ----------------------------------------------------------------------

export default function StatisticView (props) {
  let statistic = props.orderBookSettings && props.orderBookSettings.statistic

  if (statistic) {
    return (
      <div>

        <div key={'table_stage'} className='table mb-micro'>
          <h5>{transAnalysis}</h5>
          <table>
            <thead>
              <tr>
                <th>{transStatus}</th>
                <th># {transOrder}</th>
                <th>Σ {transNotional}</th>
                <th>Σ {transNotionalProbability}</th>
              </tr>
            </thead>
            <tbody>
              {statistic['stage'].map((statusDict, i) => (<tr key={i}>
                <td key={'stage_name'}>
                  {props.statuses && props.statuses.find((status) => status[0] === statusDict['name'])[1]}
                </td>
                <td key={'stage_lenders'}>
                  {statusDict['lenders']}
                </td>
                <td key={'stage_notional'}>
                  {statusDict['notional'].toLocaleString('de-DE')}{props.currency}
                </td>
                <td key={'stage_prob_weighted_notional'}>
                  {statusDict['prob_weighted_notional'].toLocaleString('de-DE')}{props.currency}
                </td>
              </tr>))}
            </tbody>
          </table>
        </div>

        <div key={'table_funnel'} className='table mb-micro'>
          <h5>{transFunnel}</h5>
          <table>
            <thead>
              <tr>
                <th>{transStatus}</th>
                <th># {transOrder}</th>
                <th>Σ {transNotional}</th>
                <th>Σ {transNotionalProbability}</th>
              </tr>
            </thead>
            <tbody>
              {statistic['funnel'].map((statusDict, i) => (<tr key={i}>
                <td key={'funnel_name'}>
                  {props.statuses && props.statuses.find((status) => status[0] === statusDict['name'])[1]}
                </td>
                <td key={'funnel_lenders'}>
                  {statusDict['lenders']}
                </td>
                <td key={'funnel_notional'}>
                  {statusDict['notional'].toLocaleString('de-DE')}{props.currency}
                </td>
                <td key={'funnel_prob_weighted_notional'}>
                  {statusDict['prob_weighted_notional'].toLocaleString('de-DE')}{props.currency}
                </td>
              </tr>))}
            </tbody>
          </table>
        </div>

        <div key={'table_sensitivity'} className='table mb-micro'>
          <h5>{transSensitivity}</h5>
          <table>
            <thead>
              <tr>
                <th>{transLevel}</th>
                <th>{transSupply}</th>
                <th>{transDemand}</th>
                <th>{transCover}</th>
                <th>{transProbDemand}</th>
                <th>{transProbCover}</th>
              </tr>
            </thead>
            <tbody>
              {statistic['sensitivity'].map(sensitivityDict => (<tr>
                <td key={'sensitivity_price'}>
                  {(sensitivityDict['price'] === 'No Limit')
                    ? (sensitivityDict['price'].toLocaleString('de-DE'))
                    : (sensitivityDict['price'].toLocaleString('de-DE') + props.formattedType) }
                </td>
                <td key={'sensitivity_supply'}>
                  {sensitivityDict['supply'].toLocaleString('de-DE')}{props.currency}
                </td>
                <td key={'sensitivity_demand'}>
                  {sensitivityDict['demand'].toLocaleString('de-DE')}{props.currency}
                </td>
                <td key={'sensitivity_cover'}>
                  {parseInt(sensitivityDict['cover'] * 100)}%
                </td>
                <td key={'sensitivity_prob_demand'}>
                  {sensitivityDict['prob_demand'].toLocaleString('de-DE')}{props.currency}
                </td>
                <td key={'sensitivity_prob_cover'}>
                  {parseInt(sensitivityDict['prob_cover'] * 100)}%
                </td>
              </tr>))}
            </tbody>
          </table>
        </div>

      </div>)
  } else {
    return null
  }
}
