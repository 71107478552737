import { gettext } from 'django'
import Hopscotch from 'hopscotch/dist/js/hopscotch'

// Define the tour!

export default function tourExport () {
  return {
    id: "export",
    i18n: {
      nextBtn: gettext('Next Step'),
      prevBtn: gettext('Back Step'),
      doneBtn: gettext('Done Step'),
      skipBtn: gettext('Skip Step'),
      closeTooltip: gettext('Close it')
    },
    steps: [
      {
        title: gettext('TG Export step 1 title'),
        content: gettext('TG Export step 1 content'),
        target: 'form',
        placement: "bottom"
      },
      {
        title: gettext('TG Export step 2 title'),
        content: gettext('TG Export step 2 content'),
        target: 'export_select',
        placement: "bottom"
      },
      {
        title: gettext('TG Export step 3 title'),
        content: gettext('TG Export step 3 content'),
        target: document.getElementsByClassName('accordion__title')[0],
        placement: "top"
      },
      {
        title: gettext('TG Export step 4 title'),
        content: gettext('TG Export step 4 content'),
        target: document.getElementsByClassName('tabs-icon')[0].getElementsByTagName('a')[0],
        placement: "left"
      },
      {
        title: gettext('TG Export step 5 title'),
        content: gettext('TG Export step 5 content'),
        target: document.getElementsByClassName('tabs-icon')[0].getElementsByTagName('a')[1],
        placement: "left"
      },
      {
        title: gettext('TG Export step 6 title'),
        content: gettext('TG Export step 6 content'),
        target: document.getElementsByClassName('tabs-icon')[0].getElementsByTagName('a').length > 2
          ? document.getElementsByClassName('tabs-icon')[0].getElementsByTagName('a')[2] : null,
        placement: "left"
      }
    ],
    showPrevButton: true
  }
}
