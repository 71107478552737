import PropTypes from 'prop-types'
import React from 'react'
import { gettext } from 'django'
import { entity, form } from '../clproxy'

import InstitutionalShareholderRow from './InstitutionalShareholderRow'
import IndividualShareholderRow from './IndividualShareholderRow'
import Alert from '../alert'
import ActionButton from '../action_button'
import Autosuggest from 'react-autosuggest'
import Fuse from 'fuse.js'
import Highlight from 'react-highlighter'

// Register Translations here
// ----------------------------------------------------------------------
const transEntityHeader = gettext('Add new entity')
const transEntityName = gettext('Legal name of entity')
const transEntityNameDescription = gettext('Legal entity name description')
const transShareholderHeader = gettext('Institutional Shareholders')
const transIndividualShareholderHeader = gettext('Individual Shareholders')
const transAdd = gettext('Add')
const transEntityTemplate = gettext('Template')
const transTemplateDescription = gettext('Template description')
const transSave = gettext('Save')
const transConfirmationMsg = gettext('Are you sure?')
const transDelete = gettext('Delete')
const errorKeyDateOfBirth = gettext('date_of_birth')
const transDeleteMsg = gettext('irrevocable and data will be deleted from all workspaces + company group structure')
// ----------------------------------------------------------------------

const emptyEntity = {
                      name: '',
                      institutions: [{slug: '', name: '', comment: '', percentage: ''}],
                      individuals: [{first_name: '', last_name: '',
                                    place_of_residence: '', comment: '', percentage: ''}]
                    }

class EntityForm extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      errors: null,
      templates: [],
      shareholders: [],
      suggestions: [],
      entity: props.entity || emptyEntity
    }
    this.fuse_options = {
      shouldSort: true,
      tokenize: true,
      threshold: 0.0,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys: ['name']
    }
    this.fuse = new Fuse([], this.fuse_options)
  }

  componentDidMount () {
    this._loadShareholders()
    form.loadTemplates(this.props.mdmType).then(templates => this.setState({templates}))
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if(nextProps.entity !== this.state.entity) {
      this.setState({entity: nextProps.entity || emptyEntity, errors: null})
    }
  }

  _handleInputChange (event, extraArgs) {
    if (extraArgs && extraArgs.newValue) {
      this.setState({entity: {...this.state.entity, 'name': extraArgs.newValue}})
    } else {
      this.setState({
        entity: {...this.state.entity, [event.target.name]: event.target.value}
      })
    }
  }

  _handleShareholderChange (index, event) {
    this.setState({
      entity: {
        ...this.state.entity,
        institutions: this.state.entity.institutions
          .map((s, i) => {
            if (i!==index) return s

            if (event.target.name === 'slug') {
              return {...s, 'slug': event.target.value, 'name': ''}
            } else if (event.target.name === 'name') {
              return {...s, 'name': event.target.value, 'slug': ''}
            } else {
              return {...s, [event.target.name]: event.target.value}
            }
          })
      }
    })
  }

  _addShareholder () {
    this.setState({
      entity: {
        ...this.state.entity,
        institutions: this.state.entity.institutions
          .concat([{slug: '', name:'', comment: '', percentage: ''}])
      }
    })
  }

  _deleteShareholder (index) {
    if (confirm(transConfirmationMsg)) {
      this.setState({
        entity: {
          ...this.state.entity,
          institutions: this.state.entity.institutions
            .filter((s, i) => i!==index )
        }
      })
    }
  }

  _handleIndividualShareholderChange (index, event) {
    this.setState({
      entity: {
        ...this.state.entity,
        individuals: this.state.entity.individuals
          .map((s, i) => {
            if (i!==index) return s

            return {...s, [event.target.name]: event.target.value}
          })
      }
    })
  }

  _addIndividualShareholder () {
    this.setState({
      entity: {
        ...this.state.entity,
        individuals: this.state.entity.individuals
          .concat([{first_name:'', last_name: '', place_of_residence: '',
                    comment: '', percentage: ''}])
      }
    })
  }

  _deleteIndividualShareholder (index) {
    if (confirm(transConfirmationMsg)) {
      this.setState({
        entity: {
          ...this.state.entity,
          individuals: this.state.entity.individuals
            .filter((s, i) => i!==index )
        }
      })
    }
  }

  _removeNull (obj) {
    let newObj = {};
    Object.keys(obj).forEach((prop) => {
      if (obj[prop] !== null) { newObj[prop] = obj[prop]; }
    });
    return newObj;
  }

  onSave () {
    if (this.state.entity.slug) {
      let newEntity = {
        ...this.state.entity,
        individuals: this.state.entity.individuals.map(i => this._removeNull(i))
      }
      entity.updateEntity(newEntity).then((newStructure) => {
        this.props.onEntitySaved(newStructure)
        this.setState({entity: emptyEntity})
      }).then(this._loadShareholders.bind(this))
      .catch(e => this.setState({errors: e.message}))
    } else {
      let newEntity = {...this.state.entity,
                    template: this.state.entity.template || this.state.templates[0].slug}
      entity.addEntity(newEntity).then((newStructure) => {
        this.props.onEntitySaved(newStructure)
        this.setState({entity: emptyEntity})
      }).then(this._loadShareholders.bind(this))
      .catch(e => this.setState({errors: e.message}))
    }
  }

  onDelete () {
    if(!confirm(transDeleteMsg)) return

    entity.deleteEntity(this.state.entity.slug).then((newStructure) => {
      this.props.onEntityDeleted(newStructure)
      this.setState({entity: emptyEntity})
    }).catch(e => this.setState({errors: e.message}))
  }

  _loadShareholders (){
    entity.loadShareholders().then(shareholders => {
      let orderedShareholders = shareholders.sort((f1, f2) => f1.name.localeCompare(f2.name))
      this.setState({shareholders: orderedShareholders})
      this.fuse = new Fuse(orderedShareholders, this.fuse_options)
    })
  }

  render () {
    return (
      <div className='mt-micro'>
        <Alert message={this.state.errors} category={'error'} />
        <div className='row'>
          <div className='col-xs-8'>
            <div className='input'>
              <label htmlFor='name'>{transEntityName}*</label>
              <Autosuggest
                suggestions={this.state.suggestions}
                onSuggestionsFetchRequested={({value, reason}) => this.setState({suggestions: this.fuse.search(value)})}
                onSuggestionsClearRequested={() => this.setState({suggestions: []})}
                getSuggestionValue={(suggestion) => suggestion.name}
                renderSuggestion={(suggestion, {query}) =>
                  <span>
                    <Highlight search={query}>{suggestion.name}</Highlight>
                  </span>}
                inputProps={{
                  onChange: this._handleInputChange.bind(this),
                  value: this.state.entity.name,
                  disabled: this.props.readOnly,
                  id: 'name',
                  type: 'text',
                  name: 'name',
                  required: true
                }}
              />
              <div className='input__description'>
                {transEntityNameDescription}
              </div>
            </div>
          </div>
          <div className='col-xs-4'>
            {this.state.templates.length === 0 ? null : <div className='input'>
              <label htmlFor='template'>{transEntityTemplate}</label>
              <select
                id='template'
                disabled={this.props.readOnly}
                name='template'
                disabled={this.state.entity.slug}
                value={this.state.entity && this.state.entity.template}
                onChange={this._handleInputChange.bind(this)}>
                {this.state.templates.map(template => (<option key={template.slug} value={template.slug}>{template.name}</option>))}
              </select>
              <div className='input__description'>
                {transTemplateDescription}
              </div>
            </div>}
          </div>
        </div>
        <div className='row'>
          <h4 className='col-xs-11'>{transShareholderHeader}</h4>
          {this.props.readOnly
          ? null
          :<a className='col-xs-1' href='#' id='add_shareholder' onClick={this._addShareholder.bind(this)}>{transAdd}</a>}
        </div>
        {this.state.entity?.institutions.map((sh, index) =>
          <InstitutionalShareholderRow key={'sh_' + this.state.entity.name + index} shareholder={sh} shareholders={this.state.shareholders}
            handleInputChange={this._handleShareholderChange.bind(this,index)} onDeleteRow={() => this._deleteShareholder(index)}
            readOnly={this.props.readOnly} />)}
        <div className='row'>
          <h4 className='col-xs-11'>{transIndividualShareholderHeader}</h4>
          {this.props.readOnly
          ? null
          :<a className='col-xs-1' href='#' id='add_individual_shareholder' onClick={this._addIndividualShareholder.bind(this)}>{transAdd}</a>}
        </div>
        {this.state.entity?.individuals.map((sh, index) =>
          <IndividualShareholderRow key={'individual_sh_' + this.state.entity.name + index} shareholder={sh} readOnly={this.props.readOnly}
            handleInputChange={this._handleIndividualShareholderChange.bind(this,index)} onDeleteRow={() => this._deleteIndividualShareholder(index)} />)}
        <div className='row'>
          {this.props.readOnly ? null : <div className='input col-xs-12'>
            <ActionButton id='save_btn' className='btn' type='button' onClick={this.onSave.bind(this)}>{transSave}</ActionButton> &nbsp;
            {this.state.entity.slug && <ActionButton id='delete_btn' className='btn btn--red' type='button' onClick={this.onDelete.bind(this)}>{transDelete}</ActionButton>}
          </div>}
        </div>
      </div>
    )
  }
}

EntityForm.propTypes = {
}

export default EntityForm
