import PropTypes from 'prop-types'
import React from 'react'

document.addEventListener('keydown', escFunction, false)

function escFunction (event) {
  if (event.keyCode === 27) {
    let evt = document.createEvent('CustomEvent')
    evt.initCustomEvent('closeModals', false, false, {})
    window.dispatchEvent(evt)
  }
}

class Modal extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      id: props.id || 'modal-' + Math.random().toString(36).substring(5),
      selectedChildren: React.Children.toArray(this.props.children)[0].type
    }
  }

  componentDidMount () {
    window.addEventListener('closeModals', (e) => {
      if (this.props.show) {
        this.props.onClose()
      }
    }, false)
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (!nextProps.show) {
      this.setState({
        selectedChildren: React.Children.toArray(this.props.children)[0].type
      })
    }
  }

  selectChild (childType) {
    this.setState({selectedChildren: childType})
  }

  componentDidUpdate (prevProps, prevState) {
    // TODO:
    // TEMP SAFARI PATCH
    // STRONGLY COUPLED TO SIDEBAR CODE
    let modalIsOpen = document.getElementsByClassName('modal is-open').length > 0
    if (document.getElementsByClassName('sidebar').length > 0) {
      if (this.props.show || modalIsOpen) {
        document.getElementsByClassName('sidebar')[0].style['overflow-y'] = 'unset'
      } else {
        document.getElementsByClassName('sidebar')[0].style['overflow-y'] = 'scroll'
      }
    }
  }

  render () {
    return (
      <div
        id={this.state.id}
        className={(this.props.show ? 'modal | is-open' : 'modal') + ' ' + this.props.className || ''}
        aria-hidden='true'
      >
        <div className='modal__overlay' tabIndex='-1' data-modal-close onClick={this.props.onClose}>
          <div className='modal__container' role='dialog' aria-modal='true' onClick={(e) => e.stopPropagation()}>
            <header className='has-dismiss-button'>
              <ul className='tabs'>
                {React.Children.map(this.props.children, (child) => {
                  if (!child) { return }
                  return (
                    <li className={child.type === this.state.selectedChildren ? 'is-active' : ''}>
                      <a href='#' onClick={() => this.selectChild(child.type)}>
                        {child.props && child.props.title}
                      </a>
                    </li>
                  )
                })}
              </ul>
              {this.props.extraHeader}
              <button
                onClick={this.props.onClose}
                className='dismiss-button dismiss-button--top-small'
                aria-label='Dismiss alert'
                type='button'
              >
                <svg className='icon icon--dark icon--close'>
                  <use xlinkHref='#icon-delete' />
                </svg>
              </button>
            </header>
            <div className='modal__content | text'>
              {React.Children.toArray(this.props.children).map(
                c => c.type === this.state.selectedChildren
                  ? <span key={c.props.title}>{c}</span>
                  : <span key={c.props.title} style={{display: 'none'}}>{c}</span>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Modal.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  id: PropTypes.string
}

export default Modal
