import PropTypes from 'prop-types'
import React from 'react'
import { gettext } from 'django'
import moment from 'moment'
import Alert from '../alert'
import ActionButton from '../action_button'
import DateTimeField from '../fields/DateTimeField'
import ParticipantLabel from './ParticipantLabel'

// Register Translations here
// ----------------------------------------------------------------------
const transSave = gettext('Save')
const transContent = gettext('Deadline Content')
const transTitle = gettext('title')
const transTitleDescription = gettext('Title')
const transStart = gettext('datetime_start')
const transStartDescription = gettext('Start Date')
const transEnd = gettext('datetime_end')
const transEndDescription = gettext('End Date')
const transAssignedTo = gettext('Assigned To')
const transContentDescription = gettext('Content')
const transDeleteBtn = gettext('Delete')
const transLocation = gettext('Location/dial-in/url')
const transLocationDescription = gettext('Location/dial-in/url description')
const transEntryType = gettext('entry_type')
const transDecline = gettext('Decline')
const transConfirmationMessage = gettext('Do you really want to decline this date? If so, the date will be removed from your calendar but remain in the system')
const transCreatedBy = gettext('Created by')
const transEntryHeader = gettext('Entry Header')
const transParticipantsHeader = gettext('Participants Header')
// ----------------------------------------------------------------------

export default class DeadlineView extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      ...this._initState(props.selected),
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.selected !== this.state.deadline) {
      this.setState(this._initState(nextProps.selected))
    }
  }

  _initState (entry) {
    if (entry) {
      return {
        deadline: {
          ...entry,
          datetime_start: entry.datetime_start,
          datetime_end: entry.datetime_end
        },
        initialParticipantsSelection: entry.participants.map(p => p.username),
        availableParticipants: entry.workspace.available_participants,
        availableParticipantEntities: entry.workspace.available_participants.filter(p => p.username !== this.props.currentUser)
          .reduce((companies, user) => companies.indexOf(user.company) > -1 ? companies : companies.concat([user.company]), [])
      }
    } else {
      return {
        deadline: {
          title: '',
          entry_type: '',
          extra_info: '',
          datetime_start: moment().minute(moment().minute() - (moment().minute() % 15)),
          datetime_end: moment().minute(moment().minute() - (moment().minute() % 15)).add(1, 'hour'),
          details: '',
          assign_to: null,
          reinvites: [],
          participants: [{username: this.props.currentUser, isdeclining: false}],
          workspace: {
            can_edit: true,
          }
        },
        availableParticipants: this.props.availableParticipants,
        availableParticipantEntities: this.props.availableParticipants.filter(p => p.username !== this.props.currentUser)
          .reduce((companies, user) => companies.indexOf(user.company) > -1 ? companies : companies.concat([user.company]), []),
        initialParticipantsSelection: []
      }
    }
  }

  handleInputChange (event) {
    this.setState({
      deadline: {...this.state.deadline, [event.target.name]: event.target.value}
    })
  }

  handleDateTimeChange (value, name) {
    if (name === 'datetime_start') {
      this.setState({
        deadline: {
          ...this.state.deadline,
          datetime_start: value,
          datetime_end: moment(value).add(1, 'hour')
        }
      })
    } else {
      this.setState({
        deadline: {...this.state.deadline, [name]: value}
      })
    }
  }

  onSave () {
    return this.props.onSave({...this.state.deadline, participants: this.state.deadline.participants.map(p => p.username)})
  }

  onReinvite(participant) {
    this.setState({
      deadline: {
        ...this.state.deadline,
        reinvites: this.state.deadline.reinvites ? this.state.deadline.reinvites.concat([participant]) : [participant]
      }
    })
  }

  onCancelReinvite(participant) {
    this.setState({
      deadline: {
        ...this.state.deadline,
        reinvites: this.state.deadline.reinvites.filter(p => p!==participant)
      }
    })
  }

  onDecline () {
    if (confirm(transConfirmationMessage)) {
      return this.props.onDecline(this.state.deadline)
    }
  }

  toogleEntity (e) {
    let entityParticipants = this.state.availableParticipants.filter(p => p.company === e.target.value).map(p => p.username)
    this.setState({
      deadline: {
        ...this.state.deadline,
        participants: e.target.checked
          ? this.state.deadline.participants.concat(entityParticipants.filter(p => !this.state.deadline.participants
                                                                      .find(dp => dp.username === p))
                                                                      .map(p => ({username: p, isdeclining: false})))
          : this.state.deadline.participants.filter(p => entityParticipants.indexOf(p.username) === -1
                                                    || this.state.initialParticipantsSelection.indexOf(p.username) > -1)
      }
    })
  }

  toogleParticipant (e) {
    this.setState({
      deadline: {
        ...this.state.deadline,
        participants: e.target.checked
          ? this.state.deadline.participants.concat([{username: e.target.value, isdeclining: false}])
          : this.state.deadline.participants.filter(p => p.username !== e.target.value)
      }
    })
  }

  allParticipantSelected (company) {
    return this.state.availableParticipants.filter(p => p.company === company).every(p => this.state.deadline.participants.find(dp => dp.username === p.username))
  }

  participantSelected (username) {
    return this.state.deadline.participants.find(p => p.username === username)
  }

  render () {
    return (
      <div className='mt-micro'>
        <Alert message={this.props.errors} category={'error'} />
        <div className='row'>
          <div className='col-xs-6'>
            <h5>{transEntryHeader}</h5>
            <div className='input'>
              <label htmlFor='title'>{transTitle}*</label>
              <input
                id='title'
                disabled={!this.state.deadline.workspace.can_edit}
                type='text'
                value={this.state.deadline.title}
                name='title'
                onChange={this.handleInputChange.bind(this)}
              />
              <div className='input__description'>
                {transTitleDescription}
              </div>
            </div>
            <div className='input'>
              <label htmlFor='entry_type'>{transEntryType}*</label>
              <select
                disabled={!this.state.deadline.workspace.can_edit}
                name='entry_type'
                value={this.state.deadline.entry_type}
                onChange={this.handleInputChange.bind(this)}>
                <option key={'blank'} value={''}>{'Please select...'}</option>
                {this.props.entryTypes.map(type => (<option key={type.key} value={type.value}>{type.description}</option>))}
              </select>
            </div>
            <div className='input'>
              <label htmlFor='extra_info'>{transLocation}</label>
              <input
                id='extra_info'
                disabled={!this.state.deadline.workspace.can_edit}
                type='text'
                value={this.state.deadline.extra_info}
                name='extra_info'
                onChange={this.handleInputChange.bind(this)}
              />
              <div className='input__description'>
                {transLocationDescription}
              </div>
            </div>
            <DateTimeField value={this.state.deadline.datetime_start} name='datetime_start' label={transStart}
              disabled={!this.state.deadline.workspace.can_edit}
              helptext={transStartDescription} onUpdateHandler={this.handleDateTimeChange.bind(this)} />
            <DateTimeField value={this.state.deadline.datetime_end} name='datetime_end' label={transEnd}
              disabled={!this.state.deadline.workspace.can_edit}
              helptext={transEndDescription} onUpdateHandler={this.handleDateTimeChange.bind(this)} />
            <div className='input'>
              <label htmlFor='details'>{transContent}</label>
              <textarea
                id='details'
                disabled={!this.state.deadline.workspace.can_edit}
                type='text'
                value={this.state.deadline.details}
                name='details'
                onChange={this.handleInputChange.bind(this)}
              />
              <div className='input__description'>
                {transContentDescription}
              </div>
            </div>
            <div className='input'>
              <label htmlFor='assigned_to'>{transAssignedTo}</label>
              <select
                name={'assigned_to'}
                disabled={!this.state.deadline.workspace.can_edit}
                value={this.state.deadline.assigned_to || ''}
                onChange={this.handleInputChange.bind(this)}>
                <option key={'blank'} value={''}>{'Please select...'}</option>
                {this.state.availableParticipants.filter(ap => this.state.deadline.participants.find(dp => dp.username === ap.username))
                  .map(p => <option key={p.username} value={p.username}>{p.first_name} {p.last_name}</option>)}
              </select>
            </div>
            {this.state.deadline.created_by
            ? <div className='input'>
              <label htmlFor='created_by'>{transCreatedBy}</label>
              <input
                type='text'
                name={'created_by'}
                value={this.state.deadline.created_by}
                disabled />
            </div>
            : null}
          </div>
          <div className='col-xs-6'>
            <h5>{transParticipantsHeader}</h5>
            <div className='row'>
              <div className='input col-md-12'>
                <ul className='accordion | '>
                  {this.state.availableParticipantEntities.map((company, i) => (<li className='accordion__item is-open' key={i}>
                    <div className='accordion__title'>
                      <div className='input'>
                        <fieldset>
                          <input id={`checkbox_${company}`} type='checkbox' value={company}
                            checked={this.allParticipantSelected(company)} onChange={this.toogleEntity.bind(this)} />
                          <label htmlFor={`checkbox_${company}`}>{company}</label>
                        </fieldset>
                      </div>
                    </div>
                    <div className='accordion__content accordion__content--padding | text'>
                      {this.state.availableParticipants.filter(p => p.company === company && p.username !== this.props.currentUser)
                        .map((p, i) => (<fieldset key={i}>
                          <input id={`checkbox_${p.username}`} type='checkbox'
                            value={p.username} disabled={this.state.initialParticipantsSelection.indexOf(p.username) > -1}
                            checked={this.participantSelected(p.username)} onChange={this.toogleParticipant.bind(this)} />
                          <label htmlFor={`checkbox_${p.username}`}>
                            <ParticipantLabel participant={p} {...this.state.deadline}
                              onReinvite={() => this.onReinvite(p.username)} onCancelReinvite={() => this.onCancelReinvite(p.username)}/>
                          </label>
                        </fieldset>
                      ))}
                    </div>
                  </li>))}
                </ul>
              </div>
            </div>
          </div>
          <div className='input col-xs-10'>
            <ActionButton className='btn' type='button' onClick={this.onSave.bind(this)}>{transSave}</ActionButton>
            {this.props.selected ? <span>
              <ActionButton
                onClick={() => this.props.onDelete(this.state.deadline)}
                disabled={!this.state.deadline.workspace.can_edit}
                className='btn btn--red mr-micro | ml-micro'
                type='button'>{transDeleteBtn}
              </ActionButton>
            </span> : (null)}
          </div>
          <div className='input col-xs-2'>
            {this.props.selected ? <span>
              <ActionButton className='btn btn--red' type='button' onClick={this.onDecline.bind(this)}>{transDecline}</ActionButton>
            </span> : (null)}
          </div>
        </div>
      </div>
    )
  }
}

DeadlineView.propTypes = {
  errors: PropTypes.array,
  selected: PropTypes.object,
  entryTypes: PropTypes.array.isRequired,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
}
