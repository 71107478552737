import PropTypes from 'prop-types'
import { gettext } from 'django'
import React from 'react'
import { workspace, entity } from '../clproxy'
import CorporateStructureTable from '../company_structure/CorporateStructureTable'
import EntityForm from './EntityForm'
import Modal from '../modal'
import Alert from '../alert'

// Register Translations here
// ----------------------------------------------------------------------
const transExportTo = gettext('Export to')
const transExport = gettext('Apply changes')
const transEmptyError = gettext('workspace empty')
const transNothingAddedYet = gettext('Nothing added yet')
const transAddEntityTitle = gettext('Add new entity')
const transUpdateEntityTitle = gettext('Update entity')
const transCorporateStructureTitle = gettext('Export corporate structure')
const transNoAvailableTarget = gettext('There isn\'t a project workspace/lender to use as target')
// ----------------------------------------------------------------------

class CorporateStructureExportTab extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      workspaces: [],
      structure: [],
      shareholders: [],
      showEntityForm: false,
      error: '',
      selected: false,
      selectedInitialValue: false
    }
  }

  componentDidMount () {
    window.$('[data-toggle="tooltip"]').tooltip()
    workspace.getRecipients(this.props.workspace).then((recipients) => {
      if (recipients.length === 0) { throw new Error(transNoAvailableTarget) }
      let recipient = this.props.targetWorkspace || recipients[0].slug

      this.setState({workspaces: recipients})
      this._loadData(recipient)
    }).catch(ex => this.setState({error: ex.message, selected: false}))
    entity.loadStructure(this.props.workspace).then(structure => this.setState({structure}))
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (prevProps.targetWorkspace !== this.props.targetWorkspace) {
      this._loadData(this.props.targetWorkspace)
    }
  }

  _viewEntity (slug) {
    let entity = this.state.structure.find(e => e.slug === slug)
    this.setState({showEntityForm: true, selectedEntity: entity})
  }

  _loadData (recipient) {
    this._isSelected(recipient).then(selected => {
      this.setState({selected, selectedInitialValue: selected})
      this.props.setCorporateTabVisibility(selected)
    }).catch(ex => {
      this.setState({selected: false, selectedInitialValue: false})
      this.props.setCorporateTabVisibility(false)
    })
    this.props.setTargetWorkspace(recipient)
    workspace.getWorkspaceForms(recipient).then(forms => this.props.setVisibleForms(forms))
  }

  _isSelected (recipient) {
    return entity.loadStructure(recipient).then(structure => structure.length > 0)
  }

  _exportValues () {
    return workspace.updateWorkspace(this.props.targetWorkspace,
                                     {slug: this.props.targetWorkspace, has_corporate_structure: this.state.selected})
      .then(() => this.setState({selectedInitialValue: this.state.selected}))
      .then(() => this._loadData(this.props.targetWorkspace))
      .catch(ex => this.setState({error: ex.message}))
  }

  _selectionHasNotChanged () {
    return this.state.selected === this.state.selectedInitialValue
  }

  render () {
    return (
      <div className='col-md-12'>
        <div key='header' className='block block--space-between | mt-medium'>
          <div className='input'>
            <label htmlFor='export_select'>{transExportTo}</label>
            <select id='export_select' onChange={(e) => this._loadData(e.target.value)}
              value={this.props.targetWorkspace || ''}>
              {this.state.workspaces.map(w => {
                return <option key={w.slug} value={w.slug}>{w.name} {w.is_empty ? `(${transEmptyError})` : null}</option>
              })}
            </select>
          </div>
          <div className='input'>
            <label htmlFor='btn-apply'>&nbsp;</label>
            <button id='btn-apply' disabled={this._selectionHasNotChanged()}
              className={this.state.modified ? 'btn btn--red' : 'btn'} onClick={() => this._exportValues()}>
              {transExport}
            </button>
          </div>
        </div>
        <Alert key='error-msg' message={this.state.error} category='error' />
        <Modal id='entity_form'
          show={this.state.showEntityForm}
          onClose={() => this.setState({showEntityForm: false, selectedEntity: null})}>
          <EntityForm title={this.state.selectedEntity ? transUpdateEntityTitle : transAddEntityTitle}
            entity={this.state.selectedEntity} mdmType={this.props.mdm_type} readOnly={true}/>
        </Modal>
        <div className='row mt-medium'>
          <div className='col-md-12'>
            <fieldset>
              <input id='selection' type='checkbox' checked={this.state.selected}
                onChange={(e) => this.setState({selected: e.target.checked})} />
              <label htmlFor='selection'>{transCorporateStructureTitle}</label>
            </fieldset>
          </div>
        </div>
        <div className='row mt-medium'>
          <div className='col-md-12'>
            {this.state.structure.length
            ? <div className='table'>
              <CorporateStructureTable workspace={this.props.default_corporate_workspace} structure={this.state.structure}
                onView={this._viewEntity.bind(this)} readOnly />
            </div>
            : <div>
              <h2>{transNothingAddedYet}</h2>
            </div>}
          </div>
        </div>
      </div>)
  }
}

CorporateStructureExportTab.propTypes = {
  setCorporateTabVisibility: PropTypes.func.isRequired,
  setTargetWorkspace: PropTypes.func.isRequired
}

export default CorporateStructureExportTab
