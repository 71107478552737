import { gettext } from 'django'
import React from 'react'

import DeadlineEntry from './DeadlineEntry'

// Register Translations here
// ----------------------------------------------------------------------
/* global gettext */
// ----------------------------------------------------------------------

export default function AgendaView ({entryTypes, workspace, deadlines, showFuture, onEdit, onView, onDelete}) {
  if (showFuture) {
    deadlines = deadlines.filter((deadline) => new Date(deadline.datetime_end || deadline.datetime_start).getTime() > Date.now())
  } else {
    deadlines = deadlines.filter((deadline) => new Date(deadline.datetime_end || deadline.datetime_start).getTime() <= Date.now()).reverse()
  }

  return (
    <div>
      {deadlines.map(
        (deadline) =>
          <DeadlineEntry
            key={deadline.slug}
            deadline={deadline}
            onEdit={onEdit}
            onView={onView}
            onDelete={onDelete}
            entryTypes={entryTypes}
          />)
      }
    </div>
  )
}
