import { gettext } from 'django'
import ReactTable from 'react-table'
import moment from 'moment'

let React = require('react')

// Register Translations here
// ----------------------------------------------------------------------
const transPrevious = gettext('Previous')
const transNext = gettext('Next')
const transLoading = gettext('Loading')
const transNoData = gettext('No rows found')
const transPage = gettext('Page')
const transOf = gettext('of')
const transRows = gettext('rows')
// ----------------------------------------------------------------------

export default function DataAccess ({data, pages, loading, pageSize, defaultSorted, columns, onFetchData}) {
  var tableColumns = []
  if (columns && columns.length > 0) {
    tableColumns = columns.map(k => {
      return {
        Header: k,
        accessor: k,
        Cell: row => {
          if (moment(row.value, moment.ISO_8601, true).isValid()) {
            return moment(row.value).format(moment.defaultFormat + ', HH:mm')
          }

          return row.value
        }
      }
    })
  }

  return (
    <ReactTable
      manual
      filterable
      previousText={transPrevious}
      nextText={transNext}
      pageText={transPage}
      ofText={transOf}
      rowsText={transRows}
      noDataText={transNoData}
      loadingText={transLoading}
      pages={pages}
      loading={loading}
      onFetchData={onFetchData}
      data={data}
      columns={tableColumns}
      defaultPageSize={pageSize}
      defaultSorted={[defaultSorted]}
    />
  )
}
