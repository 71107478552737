import http from './http'

function loadAllowedRelationships (workspaceSlug, type) {
  return http.fetch(`/api/relationships/?wks=${workspaceSlug}&type=${type}`, 'get')
    .then(response => response.map(r => ({...r, accessProfiles: r.access_profiles})))
}

function getUserAddressBook () {
  return http.fetch(`/api/account/address-book/`, 'get')
}

function addUserToPersonalAddressBook (username) {
  return http.fetch(`/api/account/address-book/`, 'post', {'email': username})
}

function deleteUserFromPersonalAddressBook (id) {
  return http.fetch(`/api/account/address-book/${id}/`, 'delete')
}

function getUserPermissionHistory (id) {
  return http.fetch(`/api/address-book/history/?id=${id}`, 'get')
}

export default {
  deleteUserFromPersonalAddressBook,
  getUserPermissionHistory,
  loadAllowedRelationships,
  getUserAddressBook,
  addUserToPersonalAddressBook
}
