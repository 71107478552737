import PropTypes from 'prop-types'
import React from 'react'
import { gettext } from 'django'
import DateField from '../fields/DateField'

// Register Translations here
// ----------------------------------------------------------------------
const transFirstName = gettext('First name')
const transLastName = gettext('Last name')
const transPlaceOfResidence = gettext('Place of residence')
const transDateOfBirth = gettext('Date of birth')
const transPercentage = gettext('%')
const transComment = gettext('Comment')
const transPercentageDescription = gettext('Percentage description')
const transCommentDescription = gettext('Comment description')
// ----------------------------------------------------------------------

class ShareholderRow extends React.Component{
  constructor (props) {
    super(props)

    this.state = {isAddingNew: false}
  }

  _onNewShareholder () {
    this.setState({isAddingNew: true})
  }

  render () {
    return (
      <div className='row'>
        <div className='col-xs-2'>
          <div className='input'>
            <label htmlFor='first_name'>{transFirstName}</label>
            <input
              type='text'
              name='first_name'
              disabled={this.props.readOnly}
              value={this.props.shareholder?.first_name}
              placeholder={transFirstName}
              onChange={this.props.handleInputChange}
            />
          </div>
        </div>
        <div className='col-xs-2'>
          <div className='input'>
            <label htmlFor='slug'>{transLastName}</label>
            <input
              type='text'
              name='last_name'
              disabled={this.props.readOnly}
              value={this.props.shareholder?.last_name}
              placeholder={transLastName}
              onChange={this.props.handleInputChange}
            />
          </div>
        </div>
        <div className='col-xs-2'>
          <div className='input'>
            <label htmlFor='slug'>{transPlaceOfResidence}</label>
            <input
              type='text'
              name='place_of_residence'
              value={this.props.shareholder?.place_of_residence}
              disabled={this.props.readOnly}
              placeholder={transPlaceOfResidence}
              onChange={this.props.handleInputChange}
            />
          </div>
        </div>
        <div className='col-xs-2'>
          <DateField value={this.props.shareholder?.date_of_birth} name='date_of_birth' label={transDateOfBirth}
            disabled={this.props.readOnly}
            onUpdateHandler={this.props.handleInputChange.bind(this)}/>
        </div>
        <div className='col-xs-1'>
          <div className='input'>
            <label htmlFor='percentage'>{transPercentage}</label>
            <input
              type='number'
              step='any'
              value={this.props.shareholder?.percentage}
              disabled={this.props.readOnly}
              name='percentage'
              onChange={this.props.handleInputChange}
            />
            <div className='input__description'>
              {transPercentageDescription}
            </div>
          </div>
        </div>
        <div className='col-xs-2'>
          <div className='input'>
            <label htmlFor='comment'>{transComment}</label>
            <input
              type='text'
              value={this.props.shareholder?.comment}
              disabled={this.props.readOnly}
              name='comment'
              onChange={this.props.handleInputChange}
            />
            <div className='input__description'>
              {transCommentDescription}
            </div>
          </div>
        </div>
        <div className='col-xs-1' >
          {this.props.readOnly ? null : <a href='#' onClick={this.props.onDeleteRow} style={{ paddingTop: '38px', display: 'inline-block' }}>
            <svg className='icon icon--delete'>
              <use xlinkHref='#icon-delete' />
            </svg>
          </a>}
        </div>
      </div>
    )
  }
}

export default ShareholderRow
