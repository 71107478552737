import http from './http'

function loadWorkspacePermissions (workspaceSlug, queryparams) {
  return http.fetch(`/api/workspace/workspaces/${workspaceSlug}/permissions/?${queryparams}`, 'get')
}

function deletePermission (permissionSlug) {
  return http.fetch(`/api/permissions/${permissionSlug}/`, 'delete')
}

function grantUser (workspaceSlug, invite, isNew) {
  invite.workspace = workspaceSlug
  return http.fetch(`/api/workspace/workspaces/${workspaceSlug}/permissions/`,
      isNew ? 'post' : 'put',
      invite)
}

function shareFormGroup (formGroupValueId, workspace) {
  let body = {
    form_group_value: formGroupValueId,
    'owner_workspace_slug': workspace
  }

  return http.fetch(`/api/workspace/workspaces/${workspace}/`, 'put', body)
}

function unShareFormGroup (formGroupValueId, workspace) {
  return http.fetch(`/api/workspace/workspaces/${workspace}/`, 'delete',
    {'form_group_value': formGroupValueId})
}

function getSharingStatuses (workspace, valueId) {
  return http.fetch(`/api/workspace/workspaces/${workspace}/groups/${valueId}/statuses/`, 'get')
}

function getFormSharingStatus (workspaceSource, form, workspaceTarget, valueId) {
  return http.fetch(`/api/workspace/workspaces/${workspaceTarget}/form/${form}/status/`, 'get')
}

function updateSharingFormGroupStatus (workspaceTarget, form, status) {
  return http.fetch(`/api/workspace/workspaces/${workspaceTarget}/form/${form}/status/`, 'put', status)
}

function zipFormMediaContent (workspace, form) {
  return http.fetch(`/api/workspace/workspaces/${workspace}/form/zip/`, 'post', {form_slug: form})
}

function getAvailableForms (workspace) {
  return http.fetch(`/api/workspace/workspaces/${workspace}/available_forms/`, 'get')
}

function getRecipients (workspace) {
  return http.fetch(`/api/workspace/workspaces/${workspace}/recipients/`, 'get')
}

function loadStats (workspace) {
  return http.fetch(`/api/workspace/workspaces/${workspace}/stats/`, 'get')
}

function loadFormsStructure (workspace, form) {
  return http.fetch(`/api/workspace/workspaces/${workspace}/forms_structure/?form=${form}`, 'get')
}

function addNewForm (workspace, templateSlug) {
  return http.fetch(`/api/workspace/workspaces/${workspace}/form/`,
                    'post',
                    {form_template: templateSlug})
}

function deleteForm (workspace, formSlug) {
  return http.fetch(`/api/workspace/workspaces/${workspace}/form/${formSlug}/`,
                    'delete')
}

function updateWorkspace (slug, workspace) {
  return http.fetch(`/api/workspace/${slug}/`, 'put', workspace)
}

function deleteWorkspace (slug, workspace) {
  return http.fetch(`/api/workspace/${slug}/`, 'delete', workspace)
}

function getUsersActivity (workspace, pageSize, page, sorted, filtered) {
  return http.fetch(`/api/workspace/workspaces/${workspace}/activities/user/?limit=${pageSize}` +
                    `&offset=${page * pageSize}${_buildFilters(filtered)}${_buildOrder(sorted)}`, 'get')
    .then(result => ({
      data: result.results,
      totalPages: Math.floor(result.count / pageSize) + 1,
      pageNum: page
    }))
}

function getDataAccess (workspace, pageSize, page, sorted, filtered) {
  return http.fetch(`/api/workspace/workspaces/${workspace}/activities/data/?limit=${pageSize}` +
                    `&offset=${page * pageSize}${_buildFilters(filtered)}${_buildOrder(sorted)}`, 'get')
    .then(result => ({
      data: result.results,
      totalPages: Math.floor(result.count / pageSize) + 1,
      pageNum: page
    }))
}

function getWorkspaceForms (workspace) {
  return http.fetch(`/api/workspace/workspaces/${workspace}/form/`)
}

function loadAgendaEntries (workspace) {
  return http.fetch(`/api/workspace/workspaces/${workspace}/deadlines/`, 'get')
}

function addAgendaEntry (workspace, entry) {
  return http.fetch(`/api/workspace/workspaces/${workspace}/deadlines/`, 'post', entry)
}

function _buildFilters (filtered) {
  return filtered.reduce((res, filter) => res + `&${filter.id}=${filter.value}`, '')
}

function _buildOrder (sorted) {
  return sorted.reduce((res, order) => `&order_by=${order.id}&ordering=${order.desc ? 'desc' : 'asc'}`, '')
}

export default {
  loadStats,
  getUsersActivity,
  getDataAccess,
  updateSharingFormGroupStatus,
  getFormSharingStatus,
  getRecipients,
  loadWorkspacePermissions,
  deletePermission,
  grantUser,
  shareFormGroup,
  unShareFormGroup,
  getSharingStatuses,
  getAvailableForms,
  zipFormMediaContent,
  addNewForm,
  deleteForm,
  getWorkspaceForms,
  addAgendaEntry,
  loadAgendaEntries,
  loadFormsStructure,
  updateWorkspace,
  deleteWorkspace
}
