import React from 'react'

export default function FormTab ({f, value, activeSidebar, visibleForms, getTabColor, navigateToForm, refreshFormData}) {
  const tabClicked = () => {
    if (value && f.slug === value.slug) {
      refreshFormData()
    } else {
      navigateToForm(f.slug, activeSidebar)
    }
  }

  return (
    <li key={f.slug} id={value && f.slug === value.slug ? 'form' : null}
      data-toggle='tooltip' title={f.name}
      className={value && f.slug === value.slug ? 'active' : null}>
      <a href='#form' data-toggle='tab' className={getTabColor(f.template_type)}
        onClick={tabClicked} style={{cursor: 'pointer'}}>
          {visibleForms.indexOf(f.slug) < 0 ? <del>{f.name}</del> : f.name}
      </a>
    </li>)
}
