import { gettext } from 'django'
import moment from 'moment/moment'

var React = require('react')

// Register Translations here
// ----------------------------------------------------------------------
const transEditTooltip = gettext('Edit Deadline')
const transViewTooltip = gettext('View Deadline')
const transDeleteTooltip = gettext('Delete Deadline')
const transEmptyValue = gettext('-')
// ----------------------------------------------------------------------

export default function DeadlineEntry (props) {
  let datetimeStart = props.deadline.datetime_start
    ? moment(props.deadline.datetime_start).format(moment.defaultFormat + ', HH:mm')
    : null
  let datetimeEnd = props.deadline.datetime_end
    ? moment(props.deadline.datetime_end).format(moment.defaultFormat + ', HH:mm')
    : null

  let datetime = datetimeEnd ? (datetimeStart + ' - ' + datetimeEnd) : datetimeStart

  let colorDict = {
    'red': '#E72E44',
    'orange': '#F5A623',
    'blue': '#2E8FE8'
  }

  let color
  if (66 < props.deadline.progress) {
    color = colorDict['red']
  }

  if (33 < props.deadline.progress && props.deadline.progress <= 66) {
    color = colorDict['orange']
  }

  if (props.deadline.progress <= 33) {
    color = colorDict['blue']
  }

  return (
    <div className='col-lg-4'>
      <a href='#' onClick={() => props.onView(props.deadline)}>
        <div className='calendar-card'>
          <span className='progress-bar' data-placement='top' title={(moment(props.deadline.datetime_start)).fromNow()} data-toggle='tooltip'>
            <span className='fill' style={{'width': `${props.deadline.progress}%`, 'background': color}} />
          </span>
          <div className='content'>
            {/*<img src={props.deadline.avatar} className='logo' style={{'width': '40px', 'height': '40x'}} />
            <span className={`privacy-icon ${props.deadline.deadline_type}`} />*/}
            <p>{props.deadline.workspace.entity_name}</p>
            <p>{props.deadline.workspace.name}</p>
            <h3>{props.deadline.title}</h3>
            <p>{props.entryTypes.find(e => e.value === props.deadline.entry_type)?.description}</p>
            <p>{props.deadline.extra_info}</p>
          </div>
          <div className='detail-bar'>
            <span className='dates'>{datetime}</span>
            <div className='icons'>
              <span title={props.deadline.participants.filter(p => !p.isdeclining).reduce((list, p) => list + ' ' + p.username, '')}
                data-toggle='tooltip' data-placement='left'>
                <span className="badge badge--red">
                  {props.deadline.participants.filter(p => !p.isdeclining).length}
                </span>
                <svg className='icon icon--person'>
                  <use xlinkHref='#icon-person' />
                </svg>
              </span>
            </div>
          </div>
        </div>
      </a>
    </div>
  )
}
