import PropTypes from 'prop-types'
import React from 'react'
import { gettext } from 'django'

// Register Translations here
// ----------------------------------------------------------------------
const transShareholder = gettext('Shareholder')
const transPleaseSelect = gettext('Please select ...')
const transAddNew = gettext('Add new')
const transPercentage = gettext('% of ownership')
const transComment = gettext('Comment')
const transPercentageDescription = gettext('Percentage description')
const transCommentDescription = gettext('Comment description')
// ----------------------------------------------------------------------

class ShareholderRow extends React.Component{
  constructor (props) {
    super(props)

    this.state= {isAddingNew: false}
  }

  _onNewShareholder () {
    this.setState({isAddingNew: true})
  }

  render () {
    return (
      <div className='row'>
        <div className='col-xs-5'>
          <div className='input'>
            <label htmlFor='slug'>{transShareholder}</label>
            {this.state.isAddingNew || this.props.readOnly
            ? <input
              type='text'
              name='name'
              value={this.props.shareholder?.name}
              disabled={this.props.readOnly}
              placeholder={transShareholder}
              onChange={this.props.handleInputChange}
            />
            : <select
              name='slug'
              disabled={this.props.readOnly}
              value={this.props.shareholder?.slug}
              onChange={(e) => e.target.value === 'new' ? this._onNewShareholder() : this.props.handleInputChange(e)}>
              <option key='blank' value=''>{transPleaseSelect}</option>
              <option key='new' value='new'>{transAddNew}</option>
              {this.props.shareholders
                .map((shareholder, i) => (<option key={i} value={shareholder.slug}>{shareholder.name}</option>))}
            </select>}
          </div>
        </div>
        <div className='col-xs-2'>
          <div className='input'>
            <label htmlFor='percentage'>{transPercentage}</label>
            <input
              type='number'
              step='any'
              disabled={this.props.readOnly}
              value={this.props.shareholder?.percentage}
              name='percentage'
              onChange={this.props.handleInputChange}
            />
            <div className='input__description'>
              {transPercentageDescription}
            </div>
          </div>
        </div>
        <div className='col-xs-4'>
          <div className='input'>
            <label htmlFor='comment'>{transComment}</label>
            <input
              type='text'
              disabled={this.props.readOnly}
              value={this.props.shareholder?.comment}
              name='comment'
              onChange={this.props.handleInputChange}
            />
            <div className='input__description'>
              {transCommentDescription}
            </div>
          </div>
        </div>
        <div className='col-xs-1' >
          {this.props.readOnly ? null : <a href='#' onClick={this.props.onDeleteRow} style={{ paddingTop: '38px', display: 'inline-block' }}>
            <svg className='icon icon--delete'>
              <use xlinkHref='#icon-delete' />
            </svg>
          </a>}
        </div>
      </div>
    )
  }
}

export default ShareholderRow
