import PropTypes from 'prop-types'
import { gettext } from 'django'
import TextField from './TextField'
import IntegerField from './IntegerField'
import DropDownField from './DropDownField'
import FloatField from './FloatField'
import EmailField from './EmailField'
import DateField from './DateField'
import DocumentField from './DocumentField'
import Alert from '../alert'
import { form } from '../clproxy'
import moment from 'moment'
import ActionButton from '../action_button';

var React = require('react')
// Register Translations here
// ----------------------------------------------------------------------
const errorSaveWhileUploading = gettext('You can not save now, because your file is stil uploading.')
const transDeleteBtn = gettext('Delete')
const transSaveBtn = gettext('Save')
const transDeleteConfirm = gettext('Do you really want to delete this entry?')

// ---------------------------------------------------------

class FormGroupCreateEditView extends React.Component {
  constructor (props) {
    super(props)

    this.state = {value: this._parseValue(props.definition, props.value || {}), errors: null}
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    this.setState({value: this._parseValue(nextProps.definition, nextProps.value || {}), errors: null})
  }

  onFieldUpdate (label, value) {
    let newValue = {...this.state.value}
    newValue[label] = value

    this.setState({
      value: newValue
    })
  }

  onCancel () {
    this.setState({errors: null})
    this.props.onUpdateHandler()
  }

  _onDelete (value) {
    return form.removeValue(this.props.formId, this.props.groupId, value.id)
      .then(() => {
        this.setState({errors: null})
        this.props.onFinish()
      })
      .catch((ex) => this.setState({errors: ex.message}))
  }

  _onSave (value) {
    if (document.getElementById('uploading') !== null) {
      return window.alert(errorSaveWhileUploading)
    }

    return form.addNewValue(this.props.formId, this.props.groupId, this.props.definition, value)
      .then(() => {
        this.setState({errors: null})
        this.props.onFinish()
      }).catch((ex) => this.setState({errors: ex.message}))
  }

  _parseValue (definition, value) {
    return definition ? Object.keys(definition).reduce((res, label) => {
      const type = definition[label].type

      if (['document-field', 'integer-field', 'float-field'].indexOf(type) >= 0) {
        res[label] = (typeof value[label] !== 'undefined' && value[label] !== null) ? value[label] : ''
      } else if (type === 'date-field') {
        res[label] = moment(value[label] || moment.now()).format()
      } else if (type === 'drop-down-field') {
        res[label] = value[label] || definition[label].options[0].name
      } else {
        res[label] = value[label] || ''
      }

      return res
    }, {id: value && value.id}) : null
  }

  render () {
    let components = {
      'text-field': TextField,
      'integer-field': IntegerField,
      'drop-down-field': DropDownField,
      'float-field': FloatField,
      'email-field': EmailField,
      'date-field': DateField,
      'document-field': DocumentField
    }

    var fields = this.props.definition ? Object.keys(this.props.definition).map((label) => {
      let value = this.state.value[label]
      let definition = this.props.definition[label]
      definition.label = label // patch

      return React.createElement(components[definition.type], {
        key: label,
        onUpdateHandler: (value) => this.onFieldUpdate(label, value),
        onError: (error) => this.setState({errors: error}),
        value: value,
        template: definition,
        workspace: this.props.workspace
      })
    }) : null

    return [
      <div key='modal_content' className='modal__content'>
        <Alert message={this.state.errors} category={'error'} />
        <div ref='form'>
          {fields}
        </div>
      </div>,
      <div key='modal_footer' className='modal__footer'>
        <div className='right'>
          {this.state.value && this.state.value.id
          ? <ActionButton
            id={'deletebtn'}
            onClick={() => { if (window.confirm(transDeleteConfirm)) this._onDelete(this.state.value) }}
            className='btn btn--red mr-micro'>{transDeleteBtn}
          </ActionButton>
          : null}
          <ActionButton onClick={() => this._onSave(this.state.value)}
            id={'savebtn'}
            className='btn'
            type='button'>{transSaveBtn}</ActionButton>
        </div>
      </div>
    ]
  }
}

FormGroupCreateEditView.propTypes = {
  value: PropTypes.object,
  definition: PropTypes.object,
  workspace: PropTypes.string.isRequired
}

export default FormGroupCreateEditView
