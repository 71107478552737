import { gettext } from 'django'
import ReactTable from 'react-table'
import React from 'react'
import moment from 'moment'

// Register Translations here
// ----------------------------------------------------------------------
const transLastName = gettext('Last Name')
const transFirstName = gettext('First Name')
const transEmail = gettext('E-Mail-Adress')
const transAccessProfile = gettext('Access Profile')
const transRelationship = gettext('Relationship')
const transEntity = gettext('Entity')
const transStatus = gettext('Status ')
const transNDA = gettext('NDA ')
const transEditTooltip = gettext('Edit Access Profile')
const transDeleteTooltip = gettext('Delete')
const transLastAccess = gettext('Last access')
const transLastLogin = gettext('Last login')
const transUserNotRegistered = gettext('Registration pending')
const transAddToBookTooltip = gettext('Add to address book')
const transGoToInfo = gettext('Show contact info ...')
const transAnswered = gettext('Answered date')
// ----------------------------------------------------------------------

function userIsEmpty (user) {
  return !user || (user.entity === user.first_name && user.first_name === user.last_name)
}

export default function UserPermissionList ({users, statuses, relationships, preventEdition,
  stats, preventDeletion, isAContact, canAddAsAContact, onEdit, onDelete, onAddToAddressBook}) {
  var tableColumns = [
    {
      Header: '',
      Cell: row => {
        return preventEdition(row.original.user)
        ? null
        : <a href='#' onClick={() => onEdit(row.original.user)} title={transEditTooltip} data-toggle='tooltip' data-placement='right'>
          <svg className='icon icon--light'>
            <use xlinkHref='#icon-edit' />
          </svg>
        </a>
      }
    },
    {Header: transFirstName, accessor: 'firstName'},
    {Header: transLastName, accessor: 'lastName'},
    {Header: transEmail, accessor: 'email'},
    {Header: transEntity, accessor: 'entity'},
    {Header: transRelationship, accessor: 'relationshipString'},
    {Header: transAccessProfile, accessor: 'accessProfile'},
    {Header: transStatus, accessor: 'status'},
    {Header: transNDA, accessor: 'nda'},
    {Header: transAnswered, accessor: 'user_answered_at'},
    {
      Header: '',
      Cell: row => {
        return (<span>
          {!canAddAsAContact(row.original.user) ? null : <a href='#' onClick={() => onAddToAddressBook(row.original.user)}
            title={isAContact(row.original.user) ? transGoToInfo : transAddToBookTooltip} data-placement='left' data-toggle='tooltip'>
            <svg className={isAContact(row.original.user) ? 'icon icon--dark' : 'icon icon--primary'}>
              <use xlinkHref='#icon-note' />
            </svg>
          </a>}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {preventDeletion(row.original.user) ? null : <a href='#' onClick={() => onDelete(row.original.user)}
            title={transDeleteTooltip} data-toggle='tooltip' data-placement='top'>
            <svg className='icon icon--delete'>
              <use xlinkHref='#icon-delete' />
            </svg>
          </a>}
        </span>)
      }
    }
  ]

  if (stats.team_stats.length > 0) {
    tableColumns = tableColumns.slice(0, 10).concat([
      {Header: transLastLogin, accessor: 'lastLogin'},
      tableColumns[10]
    ])
  }

  return (
    <ReactTable
      minRows={2}
      sortable
      showPagination={false}
      pageSize={users ? users.length : 10}
      data={users.map(user => {
        let userStats = stats.team_stats.find(s => s.username === user.user.email)

        return {
          user: user,
          firstName: user.user.first_name,
          lastName: user.user.last_name,
          email: user.user.email,
          entity: user.user.entity,
          relationshipString: relationships[user.relationship] || user.relationship,
          accessProfile: user.access_profile && user.access_profile.name,
          status: (userIsEmpty(user.user) && transUserNotRegistered) || statuses[user.status] || user.status,
          lastLogin: userStats && userStats.last_login && moment(userStats.last_login).format(),
          nda: user.nda
            ? <a href={user.nda.url} target='_blank'>{ user.nda.name }</a>
            : null,
          user_answered_at: moment(user.user_answered_at).isValid() && moment(user.user_answered_at).format()
        }
      })}
      columns={tableColumns}
    />
  )
}
