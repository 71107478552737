import { gettext } from 'django'
import Hopscotch from 'hopscotch/dist/js/hopscotch'

// WorkspaceMember Tour

export default function tourWorkspaceMembers () {
  return {
    id: "tourworkspacemembers",
    i18n: {
      nextBtn: gettext('Next Step'),
      prevBtn: gettext('Back Step'),
      doneBtn: gettext('Done Step'),
      skipBtn: gettext('Skip Step'),
      closeTooltip: gettext('Close it')
    },
    steps: [
      {
        title: gettext('TG Members step 1 title'),
        content: gettext('TG Members step 1 content'),
        target: '.page-title',
        placement: 'bottom'
      }],
    showPrevButton: true
  }
}
