import { gettext } from 'django'
import Hopscotch from 'hopscotch/dist/js/hopscotch'

// Define the tour!

var tourDashboard = {
  id: "dashboard",
  i18n: {
    nextBtn: gettext('Next Step'),
    prevBtn: gettext('Back Step'),
    doneBtn: gettext('Done Step'),
    skipBtn: gettext('Skip Step'),
    closeTooltip: gettext('Close it')
  },
  steps: [
    {
      title: gettext('TG Dashboard step 1 title'),
      content: gettext('TG Dashboard step 1 content'),
      target: 'dashboard-title',
      placement: "bottom"
    },
    {
      title: gettext('TG Dashboard step 2 title'),
      content: gettext('TG Dashboard step 2 content'),
      target: 'nav-corporate-workspace',
      placement: "bottom"
    },
    {
      title: gettext('TG Dashboard step 3 title'),
      content: gettext('TG Dashboard step 3 content'),
      target: 'nav-transaction-workspace',
      placement: "bottom"
    },
    {
      title: gettext('TG Dashboard step 4 title'),
      content: gettext('TG Dashboard step 4 content'),
      target: 'nav-alert',
      placement: "top"
    },
    {
      title: gettext('TG Dashboard step 5 title'),
      content: gettext('TG Dashboard step 5 content'),
      target: 'nav-invitation',
      placement: "bottom"
    },
    {
      title: gettext('TG Dashboard step 6 title'),
      content: gettext('TG Dashboard step 6 content'),
      target: 'nav-management',
      placement: "bottom"
    }
  ],
  showPrevButton: true
}

export default tourDashboard
