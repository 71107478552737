import React from 'react'
import { gettext } from 'django'
import moment from 'moment'
import FileUpload from '../file_upload'
import ChatMessage from './chat_message'

// Register Translations here
// ----------------------------------------------------------------------
const transSendButton = gettext('Send')
const transBack = gettext('Back')
const transTexBoxPlaceHolder = gettext('type a message')
const transDivider = gettext('new messages')
const transClose = gettext('X')
// ----------------------------------------------------------------------

class RoomMessages extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      message: '',
      file: null
    }
  }

  scrollToBottom () {
    if (this.messagesContainer) {
      if (this.divider) {
        this.messagesContainer.scrollTop =
          this.divider.offsetTop - this.messagesContainer.offsetTop - this.messagesContainer.clientHeight + 40
      } else {
        this.messagesContainer.scrollTop = this.messagesContainer.scrollHeight
      }
    }
  }

  componentDidUpdate () {
    this.scrollToBottom()
  }

  componentDidMount () {
    this.scrollToBottom()
    window.setTimeout(this.onScroll.bind(this), 3000)
  }

  fileUploaded (file) {
    this.setState({file})
  }

  handleContent (e) {
    this.setState({message: e.target.value})
  }

  onScroll (e) {
    if (this.messagesContainer) {
      if (this.messagesContainer.scrollTop + this.messagesContainer.clientHeight >= this.messagesContainer.scrollHeight
          && this.somethingNew()) {
        this.props.onReadEverything(this.props.room.slug, this.props.category)
      }
    }
  }

  somethingNew () {
    return this.props.messages && this.props.messages.some(m => !m.read)
  }

  render () {
    let messages = this.props.messages.sort((a, b) => moment(a.created_at).isAfter(b.created_at) ? 1 : -1)
    return (
      <span>
        <div>
          <h4 className='align-left'>{`${this.props.room.name}`}</h4>
          <h5>{`#${this.props.category}`}</h5>
        </div>
        <div className='chat__content with-footer' ref={(el) => { this.messagesContainer = el }}
          onScroll={this.onScroll.bind(this)}>
          {messages.slice(0, messages.findIndex(m => !m.read)).map((m, i) => (<ChatMessage key={i} m={m} />))}
          {this.somethingNew()
            ? <div id='chat_divider' ref={(el) => { this.divider = el }} className='chat__divider'>
              <span>
                {this.props.messages.filter(m => !m.read).length}&nbsp;{transDivider}&nbsp;
                <a href='#' onClick={() => this.props.onReadEverything(this.props.room.slug, this.props.category)}>
                  [{transClose}]
                </a>
              </span>
            </div>
            : null}
          {messages.slice(messages.findIndex(m => !m.read)).map((m, i) => (<ChatMessage key={i} m={m} />))}
        </div>
        <div>
          <ul className='option-menu block--space-between |'>
            <span className='align-left'>
              <li className='left '>
                <a href='#' onClick={this.props.onBack}>{transBack}</a>
              </li>
            </span>
            <span className='align-right'>
              <li className='right'>
                <span title={this.props.room.participants.map(p => p.username).join(' ')}
                  data-toggle='tooltip' data-placement='top'>
                  <svg className='icon icon--person'>
                    <use xlinkHref='#icon-person' />
                  </svg>
                </span>
              </li>
            </span>
          </ul>
          <textarea className='form-control' placeholder={transTexBoxPlaceHolder} value={this.state.message}
            onChange={this.handleContent.bind(this)} />
          <div className='clearfix' />
          <FileUpload value={this.state.file} workspace={this.props.workspace} onFileUploaded={this.fileUploaded.bind(this)} />
          <button href='#'
            onClick={() => {
              this.props.onSendMessage({
                message: this.state.message,
                attachment: this.state.file })
              this.setState({message: '', file: null})
            }}
            className='pull-right btn btn-success'>
            {transSendButton}
          </button>
        </div>
      </span>)
  }
}

export default RoomMessages
