import { gettext } from 'django'
import Hopscotch from 'hopscotch/dist/js/hopscotch'

// Define the tour!

var tourEntityTeam = {
  id: "entityteam",
  i18n: {
    nextBtn: gettext('Next Step'),
    prevBtn: gettext('Back Step'),
    doneBtn: gettext('Done Step'),
    skipBtn: gettext('Skip Step'),
    closeTooltip: gettext('Close it')
  },
  steps: [
    {
      title: gettext('Users in all Workspaces'),
      content: gettext('This is the master view accross all homepages.'),
      target: 'overall-team-page',
      placement: "bottom"
    },
    {
      title: gettext('Workspace Access per User'),
      content: gettext('Here you can see which user has access to which workspace.'),
      target: 'th',
      placement: "bottom"
    }
  ],
  showPrevButton: true
}

export default tourEntityTeam