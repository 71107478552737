import http from './http'

function loadStructure (workspace) {
  return http.fetch(`/api/entity/legal_entities/?workspace=${workspace}`, 'get')
}

function loadShareholders () {
  return http.fetch(`/api/entity/institutions/`, 'get')
}

function _sanitizePercentage (percentage) {
  if (!percentage) {
    return 0
  }

  if (percentage.indexOf(',') > 0 && percentage.indexOf('.') > 0) {
    return percentage
  }

  if (percentage.indexOf(',') > 0) {
    return percentage.replace(',', '.')
  }

  return percentage
}

function addEntity (entity) {
  return http.fetch(`/api/entity/legal_entities/`, 'post',
    {...entity,
      institutions: entity.institutions.filter(i => i.slug !== '' || i.name !== '')
                                       .map(i => ({...i, percentage: _sanitizePercentage(i.percentage)})),
      individuals: entity.individuals.filter(i => i.first_name !== '' && i.last_name !== '')
                                     .map(i => ({...i, percentage: _sanitizePercentage(i.percentage)}))
    })
}

function updateEntity (entity) {
  return http.fetch(`/api/entity/legal_entities/${entity.slug}/`, 'put',
    {...entity,
      institutions: entity.institutions.filter(i => i.slug !== '' || i.name !== '')
                                       .map(i => ({...i, percentage: _sanitizePercentage(i.percentage)})),
      individuals: entity.individuals.filter(i => i.first_name !== '' && i.last_name !== '')
                                     .map(i => ({...i, percentage: _sanitizePercentage(i.percentage)}))
    })
}

function deleteEntity (slug) {
  return http.fetch(`/api/entity/legal_entities/${slug}/`, 'delete')
}

function validateUser (email, relationship) {
  return http.fetch(`/api/entity/employee_validations/`, 'put', {username: email, relationship: relationship})
}

export default {
  validateUser,
  loadStructure,
  loadShareholders,
  addEntity,
  updateEntity,
  deleteEntity
}
