import PropTypes from 'prop-types'
import { gettext } from 'django'
import { workspace } from '../clproxy'
import ReactTable from 'react-table'
import moment from 'moment'

var React = require('react')

// Register Translations here
// ----------------------------------------------------------------------
const transCloseBtn = gettext('Close')
const transShareBtn = gettext('Share')
const transUnShareBtn = gettext('Unshare')
const transIsShared = gettext('Is linked')
const transShareTitle = gettext('Share')
const transSharingDate = gettext('Date')
const transTransactionWks = gettext('Transaction Workspace')

// ----------------------------------------------------------------------

class FormSharing extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      data: null,
      workspaces: []
    }
  }

  componentDidMount () {
    this._loadStatuses()
  }

  shareGroupForm (wks) {
    workspace.shareFormGroup(this.props.valueId, wks)
      .then(() => this._loadStatuses())
  }

  unShareGroupForm (wks) {
    workspace.unShareFormGroup(this.props.valueId, wks)
      .then(() => this._loadStatuses())
  }

  _loadStatuses () {
    return workspace.getSharingStatuses(this.props.workspace, this.props.valueId)
      .then(json => {
        this.setState({
          data: json.map(value => ({
            workspace: value.workspace_name,
            button: value.shared ? transUnShareBtn : transShareBtn,
            status: value.shared,
            date: value.date
          })),
          workspaces: json
        })
      }).catch(function (ex) {
        console.log('parsing failed', ex)
      })
  }

  rowClicked (index) {
    if (this.state.workspaces[index].shared) {
      this.unShareGroupForm(this.state.workspaces[index].workspace_slug)
    } else {
      this.shareGroupForm(this.state.workspaces[index].workspace_slug)
    }
  }

  render () {
    const columns = [
      {
        Header: '',
        accessor: 'button',
        Cell: ({row, index}) => <a href='#' id={`${row.button}-link-${row.workspace}`} onClick={this.rowClicked.bind(this, index)}>
          {row.button}
        </a>
      },
      {
        Header: transTransactionWks,
        accessor: 'workspace'
      },
      {
        Header: transIsShared,
        accessor: 'status',
        Cell: ({row}) => row.status
          ? <span id={`status-shared-${row.workspace}`} className={'text-true'}>YES</span>
          : <span>NO</span>
      },
      {
        Header: transSharingDate,
        accessor: 'date',
        Cell: ({row}) => row.status ? moment(row.date).format() : null
      }
    ]

    if (this.state.data) {
      return [
        <div key='modal__content' className='modal__content'>
          <ReactTable
            key='approve_table'
            minRows='1'
            filterable={false}
            showPagination={false}
            pageSize={this.state.data ? this.state.data.length : 10}
            data={this.state.data}
            columns={columns}
          />
        </div>,
        <div key='modal_footer' className='modal__footer'>
          <div className='right'>
            <button
              key='close_button'
              onClick={this.props.onChange}
              className='btn'
              href='#'
              disabled={this.state.saving}>{transCloseBtn}</button>
          </div>
        </div>
      ]
    } else { return <h1>Loading...</h1> }
  }
}

FormSharing.title = transShareTitle

FormSharing.propTypes = {
  valueId: PropTypes.number.isRequired,
  workspace: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
}

export default FormSharing
