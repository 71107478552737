import { gettext } from 'django'
import Hopscotch from 'hopscotch/dist/js/hopscotch'

// Define the tour!
var tourOrderBook = {
  id: 'orderBookTour',
  i18n: {
    nextBtn: gettext('Next Step'),
    prevBtn: gettext('Back Step'),
    doneBtn: gettext('Done Step'),
    skipBtn: gettext('Skip Step'),
    closeTooltip: gettext('Close it')
  },
  steps: [
    {
      title: gettext('Orders Book'), // 0
      content: gettext('Each transaction workspace can have a separate order book. ' +
        'Here you see and manage the orderbook. Depending on your chosen settings, the orderbook can only be seen ' +
        'by your team or each lender can view or edit her respective order. If selected, a lender can therefore ' +
        'enter her order directly into the system, but of course only see her own data. '),
      target: 'navOrderBook',
      placement: 'bottom'
    },
    {
      title: gettext('Orders settings'), // 1
      content: gettext('Here you can manage key elements of your order book.'),
      target: 'orderBookSettings',
      placement: 'bottom'
    },
    {
      title: gettext('Add an order'), // 2
      content: gettext('Here you can add a new order. By default, each lender in a transaction workspace' +
        ' is assigned an order. You can, however, add additional orders for each lender.'),
      target: 'addAnOrder',
      placement: 'bottom'
    },
    {
      title: gettext('Summary line'), // 3
      content: gettext('Here you have a quick aggregated overview about the status of the order book.'),
      target: 'orderTableSummary',
      placement: 'bottom'
    },
    {
      title: gettext('Edit / view selection'), // 4
      content: gettext('Here you can view or edit the details of each order.'),
      target: 'viewOrder',
      placement: 'bottom'
    },
    {
      title: gettext('Status'), // 5
      content: gettext('Orders can be assigned a pre-defined “status” level that is linked to a pre-set probability ' +
        'of the order: “Looking” = 10%, “Indication of interest” = 25%, “soft order” = 50%, “firm order” = 100%.'),
      target: 'statusHeader',
      placement: 'bottom'
    },
    {
      title: gettext('Notional'), // 6
      content: gettext('Here you see the Notional amount of the order.'),
      target: 'notionalHeader',
      placement: 'bottom'
    },
    {
      title: gettext('Probability-weighted Notional'), // 7
      content: gettext('Each order notional is weighted with the probability of the order set through “status”.'),
      target: 'propWeightedNotionalHeader',
      placement: 'bottom'
    },
    {
      title: gettext('Order level'), // 8
      content: gettext('You will see the limit of each order here. At the top of the column you will see what the' +
        ' order level type is, e.g. a Price in %, a yield in % or a spread in bps.'),
      target: 'orderLevelHeader',
      placement: 'bottom'
    },
    {
      title: gettext('Order Comments'), // 9
      content: gettext('You will find any comments regarding the order here, e.g. “subject to final rating BBB”.'),
      target: 'commentsHeader',
      placement: 'bottom'
    },
    {
      title: gettext('Next steps'), // 10
      content: gettext('In this text field you see next steps for the order, e.g. “confirm rating level”.'),
      target: 'nextStepsHeader',
      placement: 'bottom'
    },
    {
      title: gettext('Assigned to'), // 11
      content: gettext('Here you see to whom within the team or on the lender side a next step has been assigned.'),
      target: 'assignedToHeader',
      placement: 'bottom'
    },
    {
      title: gettext('Upload'), // 12
      content: gettext('Here you can see any order documentation attached to the order, e.g. a “commitment letter” ' +
        'by the respective lender/investor.'),
      target: 'uploadHeader',
      placement: 'bottom'
    },
    {
      title: gettext('Show statistics'), // 13
      content: gettext('Here you find some statistics for your orderbook on a status level (“status”),' +
        ' a aggregated level (“funnel”) and on a limit level basis.'),
      target: 'statisticButton',
      placement: 'bottom'
    }
  ],
  showPrevButton: true
}

export default tourOrderBook
