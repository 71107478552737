import cookie from 'cookie'
import { gettext } from 'django'
import FileUploadProgress from 'react-fileupload-progress'

var React = require('react')

// Register Translations here
// ----------------------------------------------------------------------
const transAttachAFile = gettext('Attach a file')
const transDeleteButton = gettext('Delete')
// ---------------------------------------------------------

export default class FileUpload extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      id: Math.random().toString(36).substr(2, 10),
      showUploader: false,
      name: (props.value && props.value.name) || null,
      document: (props.value && props.value.document) || null
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    this.setState({
      name: (nextProps.value && nextProps.value.name) || null,
      document: (nextProps.value && nextProps.value.document) || null
    })
  }

  deleteFile () {
    this.props.onDeleteFile()
  }

  fileUploaded (e, request) {
    this.props.onFileUploaded(JSON.parse(request.responseText))
    this.setState({showUploader: false})
  }

  fileUploadError (e, request) {
    this.props.onFileUploaded(null, JSON.parse(request.responseText))
  }

  render () {
    let content
    if (this.state.document) {
      content = (
        <span>
          <a href={this.state.document} target='_blank'>
            <svg className='icon icon-link'>
              <use xlinkHref='#icon-link' />
            </svg>&nbsp;{this.state.name}
          </a>
          {this.props.onDeleteFile ? (<button onClick={this.deleteFile.bind(this)}>{transDeleteButton}</button>) : null}
        </span>
      )
    } else if (this.state.showUploader) {
      content = (
        <FileUploadProgress url={'/api/form/upload/?workspace_slug=' + this.props.workspace} method='POST'
          beforeSend={(request) => {
            request.setRequestHeader('X-CSRFToken', cookie.parse(document.cookie).csrftoken)
            request.setRequestHeader('Accept', 'application/json, text/plain, */*')
            return request
          }}
          progressRenderer={this.customProgressRenderer.bind(this)}
          formRenderer={this.customFormRenderer.bind(this)}
          formGetter={this.customFormGetter.bind(this)}
          onLoad={this.fileUploaded.bind(this)}
          onError={this.fileUploadError.bind(this)} />
      )
    } else {
      content = (
        <a href='#' className='pull-left upload_btn'
          onClick={() => this.setState({showUploader: true})}>
          {transAttachAFile}
        </a>)
    }

    return content
  }

  customProgressRenderer (progress, hasError, cancelHandler) {
    let cancelButton = {
      marginTop: '5px',
      WebkitAppearance: 'none',
      padding: 0,
      cursor: 'pointer',
      background: '0 0',
      border: 0,
      fontSize: '21px',
      fontWeight: 700,
      lineHeight: 1,
      color: '#000',
      textShadow: '0 1px 0 #fff',
      filter: 'alpha(opacity=20)',
      opacity: '.2'
    }

    if (hasError || progress > -1) {
      let message = (<span id='uploading'>Uploading ...</span>)
      let progress = (
        <span>
          <progress style={{width: '300px'}} />
          <button style={cancelButton} onClick={cancelHandler}>
            <span>&times;</span>
          </button>
        </span>
      )

      if (hasError) {
        message = (<span style={{color: '#a94442'}}>Failed to upload ...</span>)
        progress = (<span />)
      }
      if (this.state.inputValue) {
        message = (<span>Successfully uploaded</span>)
        progress = (<span />)
      }

      return (
        <div className='_react_fileupload_progress_content'>
          {progress}
          <div style={{clear: 'left'}}>
            {message}
          </div>
        </div>
      )
    }
    return ''
  }

  customFormRenderer (onSubmit) {
    return (
      <input style={{display: 'block'}} type='file' name='file' id={`control_${this.state.id}_file`}
        onChange={onSubmit} />
    )
  }

  customFormGetter () {
    var data = new window.FormData()
    let file = document.getElementById(`control_${this.state.id}_file`)
    if (file.files.length === 1) {
      data.append('document', file.files[0])
    }

    return data
  }
}
