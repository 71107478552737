import React from 'react'
import PropTypes from 'prop-types'
import { workspace } from '../clproxy'
import { gettext } from 'django'

import Actions from './Actions'

// Register Translations here
// ----------------------------------------------------------------------
const transLoading = gettext('Loading...')
// ----------------------------------------------------------------------

class TOC extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      showAddForm: false,
      showDeleteForm: false
    }
  }

  addNewForm (templateSlug) {
    return workspace.addNewForm(this.props.workspace, templateSlug)
      .then(json => {
        this.setState({showAddForm: false})
        this.props.onNewFormAdded(json)
      }).catch(ex => {
        this.setState({'message': {topic: ex.message, category: 'error'}})
      })
  }

  deleteForm () {
    return workspace.deleteForm(this.props.workspace, this.props.formValue.slug)
      .then(json => {
        this.setState({showDeleteForm: false})
        this.props.onFormDeleted(this.props.formValue)
      }).catch(ex => {
        this.setState({'message': {topic: ex.message, category: 'error'}})
      })
  }

  render () {
    if (!this.props.visible) {
      return null
    }

    let toc, actions

    if (!this.props.forms || this.props.forms.length === 0) {
      toc = null
    } else if (!this.props.formValue) {
      toc = <h4 key='toc'>{transLoading}</h4>
    } else {
      toc = (
        <div className='toc__content' key='toc'>
          <span className='tag'>
            {this.props.formValue.category || 'category undefined'}
          </span>
          <ul>
            <li className='is-active'>
              <a className=''>
                {this.props.formValue.name}
              </a>
            </li>
            {this.props.formValue.groups.map(pos => (
              <li key={pos.title}>
                <a onClick={() => this.props.navigateToGroup(this.props.formValue.slug, pos.id)}>
                  {pos.title}
                </a>
              </li>))
            }
          </ul>
        </div>)
    }

    if (this.props.showActionButtons) {
      actions = <Actions
        key='add_button'
        showForm={this.state.showAddForm}
        showDeleteForm={this.state.showDeleteForm}
        addNewForm={this.addNewForm.bind(this)}
        deleteForm={this.deleteForm.bind(this)}
        templates={this.props.allowed_forms}
        disabled={this.props.allowed_forms.length === 0}
        deleteDisabled={!this.props.formValue || this.props.formValue.type_in_workspace !== 'domestic'}
        toogleVisibility={() => this.setState({showAddForm: !this.state.showAddForm})}
        toogleDeleteVisibility={() => this.setState({showDeleteForm: !this.state.showDeleteForm})} />
    }

    return [
      toc,
      actions
    ]
  }
}

TOC.propTypes = {
  workspace: PropTypes.string.isRequired,
  allowed_forms: PropTypes.array.isRequired,
  forms: PropTypes.array.isRequired,
  showActionButtons: PropTypes.bool
}

export default TOC
