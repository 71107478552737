import PropTypes from 'prop-types'
import { gettext } from 'django'
import ReactTable from 'react-table'
import React from 'react'
import moment from 'moment'

// Register Translations here
// ----------------------------------------------------------------------
const transAddBtn = gettext('Add')
const transEditBtn = gettext('Edit')
const transExpandRow = gettext('Click here to see Data & Documents')
const transCurrentVersionApproved = gettext('Current version approved')
const transLastApproveDate = gettext('Last approve date')
const transLastViewChange = gettext('Last view change')
// ----------------------------------------------------------------------

class FormGroup extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      isOpen: false
    }
  }

  componentDidMount () {
    if (this.props.id === parseInt(this.props.selectedgroup) && this.props.values.length) {
      this.setState({isOpen: true})
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (parseInt(nextProps.selectedgroup) === this.props.id &&
      nextProps.selectedgroup && this.props.values.length && !this.state.isOpen) {
      this.setState({isOpen: true})
    }
  }

  _parseValues (label, value) {
    if (typeof value === 'undefined' || value === null) { return null }

    switch (this.props.definition[label].type) {
      case 'document-field':
        return <a href={value.document} target='_blank'>{value.name}</a>

      case 'date-field':
        return moment(value).format()

      default:
        return value
    }
  }

  _allowAddValue () {
    return this.props.typeInWorkspace !== 'foreign' &&
      (this.props.many || this.props.values.length === 0)
  }

  render () {
    let extraColumns
    if (this.props.typeInWorkspace !== 'foreign') {
      extraColumns = [{
        Header: '',
        width: 80,
        accessor: 'icon',
        Filter: () => null,
        Cell: (props) => <span className='accordion__tooltip'>
          <a id={'update-' + this.props.title} href='#'
            onClick={() => this.props.onOpenEditor(this.props.id, this.props.definition, props.original)}>
            {transEditBtn}
          </a>
        </span>
      },
      {
        Header: transCurrentVersionApproved,
        width: 80,
        accessor: 'status',
        Cell: (props) => <div className={props.original.status === 'clean' ? 'circle circle--green' : 'circle circle--red'}></div>
      },
      {
        Header: transLastApproveDate,
        width: 80,
        accessor: 'approvedAt',
        Cell: (props) => props.original.approvedAt && moment(props.original.approvedAt).format()
      }]
    } else {
      extraColumns = [{
        Header: transLastViewChange,
        width: 80,
        accessor: 'lastViewChange',
        Cell: (props) => props.original.lastViewChange && moment(props.original.lastViewChange).format()
      }]
    }

    const columns = extraColumns.concat(Object.keys(this.props.definition).map((k, index) => ({
      id: `${index}-${k}`,
      Header: k,
      accessor: value => value[k],
      Cell: props => this._parseValues(k, props.value)
    })))

    return (
      <li id={this.props.formslug + '_' + this.props.id} className={'accordion__item' + (this.state.isOpen ? ' is-open' : '')}>
        <div onClick={() => this.props.values.length && this.setState({isOpen: !this.state.isOpen})} className='accordion__title'>
          <span id={`arrow-${this.props.title}`} title={transExpandRow}
            data-toggle='tooltip'>
            <svg className='icon'>
              <use xlinkHref={this.props.values.length ? '#icon-arrow' : '#icon-info'} />
            </svg>
          </span>
          <span>{this.props.title}</span>
          <span className='accordion__meta | text-micro | mr-micro'>
            {this._allowAddValue()
              ? <a id={'add-' + this.props.title} href='#'
                onClick={() => this.props.onOpenEditor(this.props.id, this.props.definition)}>{transAddBtn}</a>
              : null}
          </span>
        </div>
        <div className='accordion__content' style={{'paddingRight': '20px'}} id={`content-${this.props.title}`} >
          <div className='table table--scroll table--no-wrap'>
            <ReactTable
              minRows='1'
              pageSize={this.props.values ? this.props.values.length : 10}
              showPagination={false}
              filterable={false}
              data={this.props.values}
              columns={columns}
            />
          </div>
        </div>
      </li>
    )
  }
}

FormGroup.propTypes = {
  formslug: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  type: PropTypes.string,
  selectedgroup: PropTypes.string,
  onOpenEditor: PropTypes.func.isRequired,
  typeInWorkspace: PropTypes.string,
  many: PropTypes.bool.isRequired,
  definition: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  values: PropTypes.array.isRequired
}

export default FormGroup
