import PropTypes from 'prop-types'
import React from 'react'
import { form } from '../clproxy'
import Alert from '../alert'
import { gettext } from 'django'
import moment from 'moment'

// Register Translations here
// ----------------------------------------------------------------------
const transNoPreviousValue = gettext('No previous value')
const transApproveBtn = gettext('Approve')
const transRejectBtn = gettext('Reject')
const transApproveWarning = gettext('Approve warning')
// ----------------------------------------------------------------------

class DetailView extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      finalValue: this.props.valueAfter && this.props.valueAfter.map(this._parseChanges),
      error: null
    }
  }

  _parseChanges (value) {
    return {...value, value: value.value && value.value.slug ? value.value.slug : value.value}
  }

  _approveChangeset () {
    form.approveChangeset(this.props.workspace,
      this.props.formId,
      this.props.groupId,
      this.props.groupPosition,
      this.state.finalValue || this.props.valueAfter)
      .then(() => this._cleanState())
      .then(() => this.props.onApprove())
      .catch(err => this.setState({error: {message: err.message, category: 'error'}}))
  }

  _handleSelection (e) {
    this.setState({
      finalValue: this.state.finalValue.map(field => {
        let id = parseInt(e.target.id.split('-')[0])
        if (field.position !== id) { return field }

        return {...field, value: e.target.value}
      })
    })
  }

  _formatField (value) {
    if (value !== null && typeof value === 'object') {
      return (<a href={value.document} target='_blank'>{value.name}</a>)
    } else if (value && value.match && value.match(/^\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/)) {
      return moment(value, 'YYYY-MM-DD').format()
    } else {
      return value && value.toString()
    }
  }

  _cleanState () {
    return new Promise((resolve) => {
      this.setState({error: null})
      resolve()
    })
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.valueAfter !== this.props.valueAfter) {
      this.setState({finalValue: nextProps.valueAfter && nextProps.valueAfter.map(this._parseChanges)})
    }
  }

  render () {
    let fields = this.props.valueAfter ? this.props.valueAfter.map(field => (
      <div className='row approve-row' key={field.name}>
        <div className='col-lg-5 col-md-5 col-sm-5'>
          <fieldset>
            <input type='radio' name={field.name} onChange={this._handleSelection.bind(this)}
              value={
                this.props.valueBefore && this.props.valueBefore.find(f => f.position === field.position) ?
                  this._parseChanges(this.props.valueBefore.find(f => f.position === field.position)).value : ''
              } id={`${field.position}-before`} disabled={!this.props.valueBefore} />
            <label htmlFor={field.position + '-before'}>&nbsp;</label>
          </fieldset>
          <h5>{field.name}</h5>
          {
            this.props.valueBefore && this.props.valueBefore.find(f => f.position === field.position) ?
              this._formatField(this.props.valueBefore.find(f => f.position === field.position).value) : ''
          }
        </div>
        <div className='col-lg-5 col-md-5 col-sm-5'>
          <h5>{field.name}</h5>
          <input type='radio' name={field.name} onChange={this._handleSelection.bind(this)}
            value={this._parseChanges(field).value} defaultChecked id={`${field.position}-after`} />
          <label htmlFor={field.position + '-after'}>&nbsp;</label>
          {this._formatField(field.value)}
        </div>
      </div>
    )) : null

    return (
      <div>
        <Alert message={this.state.error && this.state.error.message}
          category={this.state.error && this.state.error.category} />
        <div className='row'>
          <div className='col-lg-5 col-md-5 col-sm-5'>
            <span className='tag'>
              {this.props.valueBeforeTimestamp ? 
                moment(this.props.valueBeforeTimestamp, 'YYYY-MM-DD HH:mm:ss.SSSSSSZ').format(moment.defaultFormat + ', HH:mm') : transNoPreviousValue}
            </span>
          </div>
          <div className='col-lg-5 col-md-5 col-sm-5'>
            <span className='tag'>
              {moment(this.props.valueAfterTimestamp, 'YYYY-MM-DD HH:mm:ss.SSSSSSZ').format(moment.defaultFormat + ', HH:mm')}
            </span>
          </div>
        </div>
        {fields}
        <div className='modal__footer'>
          <div className='alert alert--secondary has-dismiss-button col-md-12'>
              {transApproveWarning}
          </div>
        </div>
        <div className='modal__footer'>
          <div className='right'>
            <button className='btn btn--red' onClick={() => this._cleanState().then(() => this.props.onReject())}>
              {transRejectBtn}
            </button>
            &nbsp;
            <button className='btn btn--green'
              onClick={() => this._approveChangeset(this.state.finalValue)}>
              {transApproveBtn}
            </button>
          </div>
        </div>
      </div>
    )
  }
}

DetailView.propTypes = {
  workspace: PropTypes.string.isRequired,
  formId: PropTypes.number,
  groupId: PropTypes.number,
  groupPosition: PropTypes.number,
  valueAfter: PropTypes.array,
  valueBefore: PropTypes.array,
  valueAfterTimestamp: PropTypes.string,
  valueBeforeTimestamp: PropTypes.string,
  onApprove: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired
}

export default DetailView
