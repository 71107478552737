import PropTypes from 'prop-types'
import { gettext } from 'django'
import React from 'react'
import moment from 'moment'

import { form } from '../clproxy'
import Modal from '../modal'
import Detail from './Detail'

// Register Translations here
// ----------------------------------------------------------------------
const transRow = gettext('row')
const transNewRow = gettext('new row')
const transApproveTitle = gettext('Approve')
const transSelectAll = gettext('Select all changes')
const transApproveAll = gettext('Approve Items')
const transOpenDetails = gettext('Open Details')
const transConfrimationMsg = gettext('Are you sure you want to approve these changes?')

// ----------------------------------------------------------------------

class Changeset extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      changesets: [],
      selectedChangesets: []
    }
  }

  componentDidMount () {
    if (this.props.form) {
      this._loadChangsets()
    }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (prevProps.form !== this.props.form) {
      this._loadChangsets()
    }
  }

  _approveChangesets () {
    if (this.state.selectedChangesets.length === 0 || !window.confirm(transConfrimationMsg)) {
      return
    }

    Promise.all(this.state.selectedChangesets.map(changeset => {
      let changesetObj = this.state.changesets.find(changesetObj => changesetObj.id === changeset)
      return form.approveChangeset(this.props.workspace,
        changesetObj.formId,
        changesetObj.formGroup.id,
        changesetObj.formGroup.position,
        changesetObj.formGroup.changes.map(this._parseChanges))
    }))
    .then(this._changesetApproved.bind(this))
    .catch(err => window.alert(err.message))
  }

  _parseChanges (value) {
    return {...value, value: value.value && value.value.slug ? value.value.slug : value.value}
  }

  _loadChangsets () {
    return form.getChangesets(this.props.workspace, this.props.form.slug)
      .then(changesets => this.setState({changesets: changesets}))
  }

  _changesetApproved () {
    this._loadChangsets()
    this.setState({selectedChangesets: []})
    this.props.onCloseDetailRequested()
  }

  _getRowNumber (changeset) {
    let group = this.props.form.groups.find(groups => groups.id === changeset.formGroup.position)
    if (group) {
      return transRow + ' ' + (group.values.map(v => v.id).indexOf(changeset.formGroup.id) + 1)
    }
    return transNewRow
  }

  _handleSelection (checked, id) {
    let selection

    if (checked) {
      selection = this.state.selectedChangesets.concat([id])
    } else {
      selection = this.state.selectedChangesets.filter(selectedId => selectedId !== id)
    }

    this.setState({selectedChangesets: selection})
  }

  render () {
    if (!this.props.visible) {
      return null
    }

    let changesetObj = this.state.changesets.find(changeset => changeset.id === parseInt(this.props.changeset))
    return [
      <Modal key='modal'
        className='approve-modal'
        show={!!this.props.changeset}
        id='changeset_detail'
        onClose={this.props.onCloseDetailRequested}>
        <Detail
          title={transApproveTitle}
          valueBefore={changesetObj && changesetObj.formGroup.values}
          workspace={this.props.workspace}
          onApprove={this._changesetApproved.bind(this)}
          groupId={changesetObj && changesetObj.formGroup.id}
          formId={changesetObj && changesetObj.formId}
          groupPosition={changesetObj && changesetObj.formGroup.position}
          valueAfter={changesetObj && changesetObj.formGroup.changes}
          valueBeforeTimestamp={changesetObj && changesetObj.formGroup.values_timestamp}
          valueAfterTimestamp={changesetObj && changesetObj.formGroup.changes_timestamp}
          onReject={this.props.onCloseDetailRequested} />
      </Modal>,
      <div className='approve__content' key='content'>
        {this.state.changesets.map(changeset => (
          <div className='input' key={changeset.id}>
            <fieldset>
              <input type='checkbox' id={changeset.id}
                onChange={(e) => this._handleSelection(e.target.checked, changeset.id)}
                checked={this.state.selectedChangesets.indexOf(changeset.id) > -1} />
              <label htmlFor={changeset.id}>
                <div className='data'>
                  <h5>{changeset.formGroup.name}</h5>
                  <p>{`${this._getRowNumber(changeset)} -
                      ${moment(changeset.formGroup.changes_timestamp).format()} -
                      ${changeset.user}`}</p>
                  <a href='#' onClick={() => this.props.onDetailViewRequested(changeset.id)}>
                    {transOpenDetails}
                  </a>
                </div>
              </label>
            </fieldset>
          </div>
        ))}
      </div>,
      <div className='action-wrapper' key='buttons'>
        <fieldset>
          <input type='checkbox' id='select_all_changeset'
            disabled={!this.state.changesets || this.state.changesets.length === 0}
            checked={this.state.changesets.length > 0 && (this.state.selectedChangesets.length === this.state.changesets.length)}
            onChange={(e) => this.setState({selectedChangesets: e.target.checked ? this.state.changesets.map(c => c.id) : []})} />
          <label htmlFor='select_all_changeset'>{transSelectAll}</label>
        </fieldset>
        <br />
        <button disabled={this.state.selectedChangesets.length === 0} className='btn btn--green' onClick={this._approveChangesets.bind(this)}>
          {transApproveAll}
        </button>
      </div>
    ]
  }
}

Changeset.propTypes = {
  workspace: PropTypes.string.isRequired,
  form: PropTypes.object,
  changeset: PropTypes.string,
  onCloseDetailRequested: PropTypes.func.isRequired,
  onDetailViewRequested: PropTypes.func.isRequired
}

export default Changeset
