import React from 'react'
import FormGroup from './FormGroup'

export default function FormGroups ({
                                      groups,
                                      disabled,
                                      formSlug,
                                      selectedGroup,
                                      selection,
                                      onValueSelection,
                                      selectAll}) {
  if (groups) {
    return (
      <div className='col-md-12'>
        <ul className='accordion'>
          {groups.map(group => <FormGroup
            key={group.id}
            disabled={disabled}
            formslug={formSlug}
            selectedgroup={selectedGroup}
            selection={selection}
            selectAll={selectAll}
            onSelect={onValueSelection}
            {...group} />)
          }
        </ul>
      </div>
    )
  } else {
    return null
  }
}
