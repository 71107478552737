import cookie from 'cookie'

import PropTypes from 'prop-types'
import { gettext } from 'django'
import React from 'react'
import { workspace } from '../clproxy'
import scroll from '../scroll'
import FormGroups from './FormGroups'
import FormSharing from '../form_sharing'
import FormApprove from '../form_approve'
import Modal from '../modal'
import OwnersGroup from '../company_structure/OwnersGroup'
import FormGroupValueEditor from '../form_group_value_editor'

// Register Translations here
// ----------------------------------------------------------------------
const transAddTitle = gettext('Add new value')
const transEditTitle = gettext('Edit')
const transGenerateZipFile = gettext('Generate Zip')
const transInfoMsg = gettext('Info')
const transZipMsg = gettext('Your request is being processing. As soon as the zip file be generated you will receive an email with the download link.')
const transNoFiles = gettext('There is no files in this form')
const transAcceptBtn = gettext('Accept')
const transDisclaimerText = gettext('Disclaimer text')
const transEmpty = gettext('Empty')
// ----------------------------------------------------------------------

class FormView extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      showInfo: false,
      disableZipGeneration: false,
      showCreateView: false,
      modified: false,
      valueToEdit: null,
      approvedValue: null
    }
  }

  componentDidMount () {
    window.$('[data-toggle="tooltip"]').tooltip()
    window.$(document).on('mouseenter', '#wk_name', function () {
      var $this = window.$(this)
      if (this.offsetWidth < this.scrollWidth && !$this.attr('title')) {
        $this.tooltip({
          title: $this.text(),
          placement: 'left'
        })
        $this.tooltip('show')
      }
    })
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if ((prevProps.group !== this.props.group)) {
      let anchor = document.getElementById(`${this.props.formValue.slug}_${this.props.group}`)
      scroll(anchor, 300, 'easeOutQuad')
    }
    window.$('[data-toggle="tooltip"]').tooltip()
  }

  _openEditor (groupId, definition, value = null) {
    this.setState({
      showCreateView: true,
      groupToEdit: groupId,
      definitionToEdit: definition,
      valueToEdit: value,
      approvedValue: value && value.approved
    })
  }

  _reLoadData () {
    this.setState({
      showCreateView: false,
      groupToEdit: null,
      definitionToEdit: null,
      valueToEdit: null,
      approvedValue: null
    })
    this.props.onChanged()
  }

  _generateZipFile () {
    workspace.zipFormMediaContent(this.props.workspace, this.props.formValue.slug)
      .then(() => this.setState({showInfo: true, infoText: transZipMsg, disableZipGeneration: true}))
      .catch(() => this.setState({showInfo: true, infoText: transNoFiles, disableZipGeneration: false}))
  }

  render () {
    if (!this.props.formValue) {
      return (<div key='form-row' className='page-title row mb-medium' style={{borderBottom: '0px'}}>
          <div className='col-md-12'>
            <h2 id='wk_name' style={{textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}}>
              {this.props.workspace_name_long}
            </h2>
            <h5>
              {transEmpty}
            </h5>
          </div>
        </div>)
    }
    return [
      <div key='form-row' className='page-title row mb-medium' style={{borderBottom: '0px'}}>
        <div className='col-md-8'>
          <h2 id='wk_name' style={{textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}}>
            {this.props.workspace_name_long}
          </h2>
          <h5>
            {this.props.formName}
          </h5>
        </div>
        <div className='col-md-4'>
          <button className='btn btn--flex btn--align-center' id='zip-button'
            disabled={!this.props.formValue || this.state.disableZipGeneration} onClick={this._generateZipFile.bind(this)}>
            {transGenerateZipFile}
          </button>
        </div>
        <div className='col-md-12'>
          {this.props.nda_name !== 'None' ? <h5>{transDisclaimerText}&nbsp;<a href={this.props.nda_url} target='_blanck'>{this.props.nda_name}</a></h5> : null}
        </div>
      </div>,
      <Modal key='modal' show={this.state.showCreateView} onClose={this._reLoadData.bind(this)} id='modal'>
        <FormGroupValueEditor
          key='editor'
          workspace={this.props.workspace}
          title={this.state.valueToEdit ? transEditTitle : transAddTitle}
          formId={this.props.formValue && this.props.formValue.id}
          viewType={'create'}
          onFinish={() => this._reLoadData()}
          groupId={this.state.groupToEdit}
          definition={this.state.definitionToEdit}
          value={this.state.valueToEdit} />
        {this.state.valueToEdit ? <FormApprove
          title={FormApprove.title}
          key='approve'
          onRestored={(approvedValue) => this.setState({approvedValue: approvedValue})}
          onFinish={() => this._reLoadData()}
          groupDefinition={this.state.definitionToEdit}
          approvedValue={this.state.approvedValue}
          valueId={this.state.valueToEdit && this.state.valueToEdit.id}
          workspace={this.props.workspace} /> : null}
        {(this.props.canlink === 'true') && this.state.valueToEdit ? <FormSharing
          title={FormSharing.title}
          key='sharing-status'
          workspace={this.props.workspace}
          onChange={this._reLoadData.bind(this)}
          valueId={this.state.valueToEdit && this.state.valueToEdit.id} /> : null}
      </Modal>,
      <Modal show={this.state.showInfo} key={'InfoForm'} id='infoform'
        onClose={() => this.setState({showInfo: false})}>
        <div title={transInfoMsg}>
          <p>{this.state.infoText}</p>
          <div className='input | align-right | mt-small'>
            <button
              onClick={() => this.setState({showInfo: false})}
              className='btn mr-micro'>{transAcceptBtn}
            </button>
          </div>
        </div>
      </Modal>,
      <div key='content' className='row' id='form__content'>
        {this.props.entity && (this.props.entity.institutions.length || this.props.entity.individuals.length)
          ? <OwnersGroup entity={this.props.entity} onEntitySaved={this.props.onEntitySaved}
            allowEdit={this.props.formValue?.type_in_workspace !== 'foreign'} />
          : null}
        <FormGroups
          groups={this.props.formValue && this.props.formValue.groups}
          onOpenEditor={this._openEditor.bind(this)}
          formSlug={this.props.formValue && this.props.formValue.slug}
          selectedGroup={this.props.group}
          typeInWorkspace={this.props.formValue && this.props.formValue.type_in_workspace}
        />
      </div>
    ]
  }
}

FormView.propTypes = {
  workspace_name: PropTypes.string.isRequired,
  workspace_name_long: PropTypes.string.isRequired,
  workspace: PropTypes.string.isRequired,
  group: PropTypes.string,
  canlink: PropTypes.string.isRequired
}

export default FormView
